import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { BsArrowRepeat } from "react-icons/bs";
import "./LoaderButton.css";

const useStyles = makeStyles({
  root: {
    background: '#f0c239',
    //borderRadius: 3,
    //border: 0,
    width:"100%",
    color: 'white',
    startIcon: {
      position: 'absolute',
      left: '1rem'
    },

    //margin: "4px",
    //padding: '0 30px',
    //boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    // $disabled is a reference to the local disabled
    // rule within the same style sheet.
    // By using &, we increase the specificity.
    '&:hover': {
      backgroundColor: '#f0c239',
      //color: 'lightGrey',
      boxShadow: '0 0px 3px 0px rgba(0, 0, 0, 1)',
    },
    '&:disabled': {
      backgroundColor: '#f0c23988',
      color: 'white',
      boxShadow: 'none',
    },
  },
  disabled: {},
});


export default function LoaderButton({
  isLoading,
  className = "",
  text,
  loadingText,

  disabled = false,
  ...props
}) {
  const classes = useStyles();
  return (
    <Button
      disabled={disabled || isLoading}
      className={`LoaderButton ${className}`}
      classes={{
        root: classes.root, // class name, e.g. `root-x`
        disabled: classes.disabled, // class name, e.g. `disabled-x`
        startIcon: classes.startIcon,
      }}
      {...props}
    >
      {isLoading && <BsArrowRepeat className="spinning" />}
      {props.children}
      {!isLoading ? text : loadingText}
    </Button>
  );
}
