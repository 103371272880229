import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useAppContext } from "../libs/contextLib";
import { useHistory, useParams } from "react-router-dom";
import { API } from "aws-amplify";

import LoaderButtonX from "../components/LoaderButtonX";
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { Link } from 'react-router-dom';

import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';

import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import IconButton from '@material-ui/core/IconButton';

import spinner from'../images/logo.gif';


import "./Gallery.css";


const FooterTypography = withStyles({
  root: {
    color: "#fff"
  }
})(Typography);



export default function Gallery() {
  const { id } = useParams();
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const history = useHistory();



  const useViewport = () => {
    const [width, setWidth] = React.useState(window.innerWidth);
    const [height, setHeight] = React.useState(window.innerHeight);
    React.useEffect(() => {
      const handleWindowResize = () => {setWidth(window.innerWidth);setHeight(window.innerHeight);};
      window.addEventListener("resize", handleWindowResize);
      return () => window.removeEventListener("resize", handleWindowResize);
    }, []);
    return { width, height };
  }
  const { width, height } = useViewport();



  useEffect(() => {
    async function onLoad() {
      if (!isAuthenticated) {history.push("/"); return;}
      initForSiteId();
    }
    onLoad();
  }, []);

  function getPhotos() {
      return API.get("spivolt", `/sites/images/${id}`);
  }

  async function initForSiteId() {
    setIsLoading(true);
    try {
      const result = await getPhotos();
      if (!result.status) {history.push("/"); return;}
      const items = result.items;
      setData(items);
    } catch (e) {
      alert(e);
    }
    setIsLoading(false);
  }

  function handleContactClick(event) {
    event.preventDefault();
    history.push("/contact");
  }


  function renderLander() {
    return (

      <div className={"lander"} >

      <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
      >
<Box maxWidth="800px">
      <Box width="100%" textAlign="left">
      <h2 style={{textAlign: "left", color:"#0cb9c2"}}>{id}: Gallery</h2>
      </Box>
      <br/>

      <ImageList  cols={3} rowHeight={200}>

        {data.map((photo, idx) => (

          <ImageListItem key={idx}>
            <img
              src={photo.base64data}
              alt={photo.content.name}
              loading="lazy"
            />
            <ImageListItemBar key={idx}
              title={photo.content.name}
              subtitle={photo.content.desc}
              actionIcon={
                <IconButton
                  sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                  aria-label={`info about ${photo.content.name}`}
                >
                  <InfoRoundedIcon />
                </IconButton>
                }
            />
          </ImageListItem>

        ))}

      </ImageList>
      </Box></Box>

</div>





    );




  }

  function renderLoader() {
    return (
      <div id="splash" className={"lander"} style={{paddingTop: (height-100)/2, zIndex:999}}>

        <center id="splash">
          <img src={spinner} height="30px" width="auto"/>
        </center>
      </div>


    );

  }



  return (
    <div className="Gallery">
      {isLoading?renderLoader():renderLander()}
    </div>
  );
}
