import React, { useState, useEffect } from "react";
import { useHistory, useParams} from "react-router-dom";
import { onError } from "../libs/errorLib";

import { API } from "aws-amplify";
import "./Site.css";
//import LoaderButton2 from "../components/LoaderButton2";
import LoaderButtonX from "../components/LoaderButtonX";

import { useAppContext } from "../libs/contextLib";
import { useFormFields } from "../libs/hooksLib";

//import Table from "react-bootstrap/Table";

import { makeStyles, withStyles } from '@material-ui/core/styles';

import { BsArrowRepeat } from "react-icons/bs";

// import ImageList from '@material-ui/core/ImageList';
// import ImageListItem from '@material-ui/core/ImageListItem';
 import Button from '@material-ui/core/Button';
 import Link from '@material-ui/core/Link';
 import IconButton from '@material-ui/core/IconButton';
// import Avatar from '@material-ui/core/Avatar';
//
// import Fab from '@material-ui/core/Fab';
//
//
import Switch from '@material-ui/core/Switch';

 import Box from '@material-ui/core/Box';
 import Paper from '@material-ui/core/Paper';

 //import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';
 //import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
 //import Brightness3Icon from '@material-ui/icons/Brightness3';
 //import InvertColorsIcon from '@material-ui/icons/InvertColors';
 import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

 import iTopOvernightOff from'../images/preview/Pay Per Night - Grey.svg';
 import iTopBaysOff from'../images/preview/Parking - Grey.svg';
 import iTopFacilitiesOff from'../images/preview/Facilities Only - Grey.svg';

 import iTopOvernight from'../images/preview/Pay Per Night - Blue.svg';
 import iTopBays from'../images/preview/Parking - Blue.svg';
 import iTopFacilities from'../images/preview/Facilities Only - Blue.svg';



import iRecyclingOff from'../images/facilities/Recycling - Grey.svg';
import iBlackWaterOff from'../images/facilities/Black Water Disposal - Grey.svg';
import iElectricOff from'../images/facilities/Electric Hook Up - Grey.svg';
import iGreyWaterOff from'../images/facilities/Grey Water Disposal - Grey.svg';
import iLaundryOff from'../images/facilities/Laundry - Grey.svg';
import iOvernightOff from'../images/facilities/Overnight Parking - Grey.svg';
import iRubbishOff from'../images/facilities/Rubbish - Grey.svg';
import iShowerOff from'../images/facilities/Shower - Grey.svg';
import iToiletOff from'../images/facilities/Toilet - Grey.svg';
import iWaterOff from'../images/facilities/Water - Grey.svg';
import iWifiOff from'../images/facilities/Wifi - Grey.svg';

import iRecycling from'../images/facilities/Recycling - Coral.svg';
import iBlackWater from'../images/facilities/Black Water Disposal - Coral.svg';
import iElectric from'../images/facilities/Electric Hook Up - Coral.svg';
import iGreyWater from'../images/facilities/Grey Water Disposal - Coral.svg';
import iLaundry from'../images/facilities/Laundry - Coral.svg';
import iOvernight from'../images/facilities/Overnight Parking - Coral.svg';
import iRubbish from'../images/facilities/Rubbish - Coral.svg';
import iShower from'../images/facilities/Shower - Coral.svg';
import iToilet from'../images/facilities/Toilet - Coral.svg';
import iWater from'../images/facilities/Water - Coral.svg';
import iWifi from'../images/facilities/Wifi - Coral.svg';

import ArrowRightRoundedIcon from '@material-ui/icons/ArrowRightRounded';

import iCampraA from'../images/campra/Campra Accredited - Green.svg';
import iCampraX from'../images/campra/Campra Accredited - Grey.svg';

import defaultSiteImage from'../images/Default-Image-Road.jpg';
import spinner from'../images/Driving-SVG.gif';


import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';


import Dialog from '@material-ui/core/Dialog';
// import ListItemText from '@material-ui/core/ListItemText';
// import ListItem from '@material-ui/core/ListItem';
// import List from '@material-ui/core/List';
// import Divider from '@material-ui/core/Divider';
 import AppBar from '@material-ui/core/AppBar';
 import Toolbar from '@material-ui/core/Toolbar';
 import Typography from '@material-ui/core/Typography';
 import CloseIcon from '@material-ui/icons/Close';
// import Slide from '@material-ui/core/Slide';
//
// import Container from '@material-ui/core/Container';


import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// import InputLabel from '@material-ui/core/InputLabel';
// import MenuItem from '@material-ui/core/MenuItem';
// import Select from '@material-ui/core/Select';
// import Switch from '@material-ui/core/Switch';

import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
// import Form from "react-bootstrap/Form";
//
 import { createTheme, ThemeProvider } from '@material-ui/core/styles'
 import CssBaseline from '@material-ui/core/CssBaseline';
//
 import parse from 'html-react-parser';





//import CheckboxLabels from "../components/CheckboxLabels";

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'white',
    },
  },
})(TextField);
const CssSelect = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'white',
    },
  },
})(Select);

const BlueTooltip = withStyles((theme) => ({
  arrow: {
    "&:before": {
      border: "1px solid #10385A"
    },
    color: '#10385Acc'
  },
  tooltip: {
    backgroundColor: '#10385Add',
    color: '#ffffff',
    border: "1px solid #10385A",
    fontSize: 12,
    fontFamily: "Montserrat",
    fontWeight: 400,

  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      background: "#000",
    },
  },
  imageList: {
    width: 500,
    height: 48,
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  dialogContent: {
    width: '100%',
    backgroundColor: '#10385A',
    paddingTop: '80px',
    height:"100vh",
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
    color: 'white'
  },

  link: {
      color: "#F18C7E",
      "&:hover": {
          color: "#F18C7E",
          textDecoration: "underline #F18C7E"
      }
  },

  textField: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "red"
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "red"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "red"
    },
    "& .MuiOutlinedInput-input": {
      color: "red"
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "red"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "red"
    },
    "& .MuiInputLabel-outlined": {
      color: "red"
    },
    "&:hover .MuiInputLabel-outlined": {
      color: "red"
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "red"
    },


  }



}







));


const useViewport = () => {
  const [width, setWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  // Return the width so we can use it in our components
  return { width };
}




export default function Site() {

  const { id } = useParams();
  //const [dark, setDark] = useState(false);

  const theme = createTheme({
      palette: {
          primary: {
            // light: will be calculated from palette.primary.main,
            main: '#10385A',
            // dark: will be calculated from palette.primary.main,
             //contrastText: '#fff',
          },
          secondary: {

            main: '#F18C7E',
            // dark: will be calculated from palette.secondary.main,
          },

      },

  })

  let classes = useStyles();

  const history = useHistory();
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    reg: "",
  });
  const [tncs, setTncs] = useState(false);

  const { isCampra } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const [isPaying, setIsPaying] = useState(false);
  const [isSwitching, setIsSwitching] = useState(false);


  const [open, setOpen] = React.useState(false);
  const [paymentResult, setPaymentResult] = React.useState(false);
  const [sessionValues, setSessionValues] = React.useState([]);

  const [payType, setPayType] = React.useState("");
  const [when, setWhen] = React.useState(0);

  const [content, setContent] = useState("");
  const [siteOpen, setSiteOpen] = useState(true);
  const [base64Image, setBase64Image] = useState("");
  const [siteCode, setSiteCode] = useState("");
  const [siteId, setSiteId] = useState("");
  const [ownerId, setOwnerId] = useState("");
  const [desc, setDesc] = useState("");
  const [rules, setRules] = useState([]);
  const [address, setAddress] = useState("");
  const [addressExtra, setAddressExtra] = useState("");
  const [accreditations, setAccreditations] = useState([]);
  const { width } = useViewport();
  const breakpoint = 600;


  const OpenClosedSwitch = withStyles({
    switchBase: {
      color: red[700],
      '&$checked': {
        color: green[500],
      },
      '&$checked + $track': {
        backgroundColor: green[500],
      },
    },
    checked: {},
    track: {},
  })(Switch);

  useEffect(() => {
    function getSite(code) {
      return API.get("aires", `/sites/${code}/DETAILS`);
    }
    async function onLoad() {
      try {
        const site = await getSite(id);
        if (site.status && site.data) {
          const {pk, itemId, itemCode, itemStatus, image, accreditations } = site.data;
          setSiteId(itemId);
          setOwnerId(pk);
          setSiteCode(itemCode);
          setContent(site.data.content);
          setSiteOpen(itemStatus > 0);
          setBase64Image(image);
          if (accreditations) {
            setAccreditations(accreditations);
          }
          setAddress(site.data.content.address);
          setAddressExtra(site.data.content.extraDirections);
          //const desc = site.data.content.desc.replaceAll('<','(').replaceAll('>',')');
          const desc = site.data.content.desc.replace(new RegExp('<',"g"),'(').replace(new RegExp('>',"g"),')');
          //setDesc(desc.replaceAll('\n','<br/>'));
          setDesc(desc.replace(new RegExp('\n',"g"),'<br/>'));
          setRules(site.data.content.rules);
          const parts = window.location.href.split("?");
          if (parts.length > 1) {
            if (parts[1].indexOf('session_id=')>-1) {
              const val = parts[1].split("=")[1];
              getSession(val);
            }
          }
          setIsLoading(false);
        } else {
          history.push("/");
        }
      } catch (e) {
        onError(e);
        history.push("/");
      }
    }

    onLoad();
  }, [id]);

  async function getSession(sessionId) {
    const result = await retrieveSession({session_id: sessionId});
    let type = "";
    if (result.metadata.payType==='overnight') {
      type = "Overnight Stay"
    } else if (result.metadata.payType==='facilities') {
      type = "Facilities Use"
    }
    setSessionValues([result.customer, type + " at AireStop " + result.metadata.siteCode]);
  }


function validateForm() {
  return !(fields.email.length > 0 && fields.reg.length > 0 && tncs);
}


function handlePayClick(type) {
  setWhen(0);
  setPayType(type);
  setOpen(true);
  //setDark(true);


}
function validatePayType(type) {
  if (type==='overnight') {
    return siteOpen && content.priceStay>=1 && content.facilities.facONS;
  } else if (type==='facilities') {
    return siteOpen && content.priceFacilities>=1 && (content.facilities.facEHU || content.facilities.facSWR || content.facilities.facTLT || content.facilities.facWTR || content.facilities.facGWD || content.facilities.facBWD || content.facilities.facLND || content.facilities.facWFI || content.facilities.facRUB || content.facilities.facREC);
  } else {
    return false;
  }
}




async function handleStripePayClick() {
  setIsPaying(true);
  let regStatus = {};
  const regResult = await getRegCheck(fields.reg);
  if (regResult.status == false) {
    if (regResult.error.message.indexOf("400")>-1) {
      //console.log("Invalid reg number format");
      regStatus={result: "400", data: {message: regResult.error.message}};
    } else if (regResult.error.message.indexOf("404")>-1) {
      //console.log("Reg not found");
      regStatus={result: "404", data: {message: regResult.error.message}};
    } else {
      regStatus={result: "000", data: {message: regResult.error.message}};
    }
  } else {
    regStatus={result: "200", data: regResult.data};
  }



  const currentUrl = window.location.href.split("?")[0];
  const metadata = {when: when, vehicle_reg: fields.reg, regStatus: JSON.stringify(regStatus), location: {}, siteCode: siteCode, siteId: siteId, payType: payType, ownerId: ownerId, email: fields.email};
  let result = await createPayment({ url: currentUrl, email: fields.email, metadata: metadata});
  let url = result.paymentLinks.url;
  if (url) {
    window.location.href = url;
  }
  setIsPaying(false);
}

function getRegCheck(reg) {
  try{
    return API.post("aires", "/dvla", {
      body: {reg: reg}
    });
  } catch {
    setIsLoading(false);
  }
}

function createPayment(content) {
  try{
    return API.post("aires", "/payments", {
      body: {type: 'airestopPayment', content: content}
    });
  } catch {
    setIsLoading(false);
  }
}

function retrieveSession(content) {
  return API.post("aires", "/payments", {
    body: {type: 'session', content: content}
  });
}

function handlePaymentResultClose() {
  setSessionValues([]);
}


function handleClose() {
  fields.email = "";
  fields.reg = "";
  setTncs(false);
  setOpen(false);
  //setDark(false);
}

const tandcs = (event) => {
  //event.preventDefault();
  window.open("https://airestop.co.uk/docs/tncs.pdf");
};

const handleTncsChange = (event) => {
  setTncs(event.target.checked);
}
const handleWhenChange = (event) => {
  setWhen(event.target.value);
}


async function handleLocationClick() {
  if (content.location) {
    let url = "https://google.com/maps/place/" + content.location.lat + "," + content.location.lng;
    window.open(url);
  } else if (content.mapLink) {
    window.open(content.mapLink);
  }
}

function currencyFormat(num) {
   return '£' + Number.parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const handleAccreditationChange = async (event) => {
  event.preventDefault();
  setIsSwitching(true);
  const isAccredited = event.target.checked;
  const result = await updateSiteAccreditation(isAccredited);
  if (result.status && result.result && result.result.Attributes) {
    if (result.result.Attributes.accreditations) {
      setAccreditations(result.result.Attributes.accreditations);
    } else {
      setAccreditations([]);
    }

  }
  setIsSwitching(false);
}

function updateSiteAccreditation(isAccredited) {
  return API.put("aires", `/sites/${siteCode}`, {body: {
    type: 'ACCREDITATION',
    accreditations: isAccredited?["campra"]:[],
    ownerId: ownerId
  }});
}




function renderLoader() {
  return (
    <div id="splash" style={{paddingTop: 100, zIndex:999}}>
      <center id="splash">
        <img src={spinner} height="30px" width="auto"/>
      </center>
    </div>
  );
}


  function renderLander() {

    return (
      <div style={isSwitching?{pointerEvents: "none", opacity: "0.7"}:{}}>
      <Box display="flex" width="100%" flexDirection="column" justifyContent="center" alignItems='center'>
      <Box  width="100%" maxWidth="600px" >
      <div  className={/*width>breakpoint?"lander2":*/"lander"}>

      <Box display="flex" width="100%"  flexDirection="column" justifyContent="center" alignItems='center'>



{isCampra?
  <>
      <Paper elevation={3} >
      <Box  p={1}>
      <a><b>CAMpRA Site Accreditation</b><br/>Please use this to set the accreditation for this site.</a>
      </Box>
      <Box display="flex" flexWrap="wrap" p={1} justifyContent="center" alignItems='center'>
      <FormControlLabel
      control={
        <>
        {isSwitching?
        <BsArrowRepeat className="spinning" />
        :<></>}
        <OpenClosedSwitch
        checked={accreditations.length>0}
        onChange={handleAccreditationChange}
        name="siteOpen"
        color="secondary"
        />
        </>
      }
      label={ accreditations.length>0?"Accredited":"Not accredited"}
      />
      </Box>


      </Paper>

      <br/>
</>
:""}










<Box display="flex" width="100%" flexDirection="column" justifyContent="left" alignItems='left'>
      <Box  width="100%" display="flex" flexWrap="wrap" mt={-1}  mx={0} alignItems='top'>
      <span className="text-muted">{siteCode}</span>
      </Box>
      <Box display="flex" width="100%" flexWrap="wrap" my={0}  mx={0} alignItems='top'>

      <Box display="flex" width="100%" flexWrap="wrap" my={0}  ml={-0.1} alignItems='top'> <h4>{content.siteName}</h4>  </Box>
      </Box>
      <Box display="flex" width="100%" flexDirection='column' flexWrap="wrap" my={0}  mx={0} alignItems='left'>

      <Link style={{cursor: 'pointer'}} onClick={() => {handleLocationClick();}} variant="primary">
        {address}
      </Link>
      {addressExtra}
      </Box>
      <br/>

      <Paper variant="outlined" width="100%" elevation={0} >
      <Grid container width="100%" justifyContent="center" alignItems='center' spacing={0}>
      <Grid key={1} item>
        <BlueTooltip arrow placement="top" enterTouchDelay={100} enterDelay={50} title={"AireStop is "+(siteOpen?"Open":"Closed")}><Box className="noselect" display="flex" flexWrap="wrap" my={0.3} mr={1} alignItems='center'><FiberManualRecordIcon style={siteOpen?{ color: green[700] }:{ color: red[700] }} />{siteOpen?'Open':'Closed'}</Box></BlueTooltip>
      </Grid>
      <Grid key={2} item>
        <BlueTooltip arrow placement="top" enterTouchDelay={100} enterDelay={50} title={"Overnight Parking (including facilities use)"}><Box className="noselect" display="flex" flexWrap="wrap" my={0.3}  mx={1} alignItems='center'><img src={content.priceStay==null || content.priceStay==""?iTopOvernightOff:iTopOvernight} width='auto' height='28'/>&nbsp;{(content.priceStay==null || content.priceStay=="")?"":(content.priceStay>0?"£"+content.priceStay:"free")}</Box></BlueTooltip>
      </Grid>
      <Grid key={3} item>
        <BlueTooltip arrow placement="top" enterTouchDelay={100} enterDelay={50} title={"Facilities use only"}><Box className="noselect" display="flex" flexWrap="wrap" my={0.3}  mx={1} alignItems='center'><img src={content.priceFacilities==null || content.priceFacilities==''?iTopFacilitiesOff:iTopFacilities} width='auto' height='28'/>&nbsp;{content.priceFacilities==null || content.priceFacilities==''?"":(content.priceFacilities>0?"£"+content.priceFacilities:"free")}</Box></BlueTooltip>
      </Grid>
      <Grid key={4} item>
        <BlueTooltip arrow placement="top" enterTouchDelay={100} enterDelay={50} title={"No. Of Parking Bays"}><Box className="noselect" display="flex" flexWrap="wrap" my={0.3}  mx={1} alignItems='center'><img src={content.bays>0?iTopBays:iTopBaysOff} width='auto' height='24'/>&nbsp;{content.bays>0?content.bays:""}</Box></BlueTooltip>
      </Grid>
      <Grid key={5} item>
        <BlueTooltip arrow placement="top" enterTouchDelay={100} enterDelay={50} title={"CAMpRA have"+(accreditations.length>0?" ":" not ")+"accredited this Aire"}><Box className="noselect" display="flex" flexWrap="wrap" my={0.3}  ml={1} alignItems='center'><img src={accreditations.length>0?iCampraA:iCampraX} width='auto' height='18'/></Box></BlueTooltip>
      </Grid>
       </Grid>
    </Paper>
</Box>

<br/>
</Box>
</div>
<div  className={/*width>breakpoint?"lander2":*/"lander"}>

<Box mb={2}  display="inline-block" bgcolor="background.paper"><img src={base64Image?base64Image:defaultSiteImage} width={/*breakpoint>width?*/'100%'/*:'620px'*/} height="auto" style={{borderRadius: '4px'}}/></Box>


<Paper variant="outlined" >
<Box mx={1}>
<div>

<Box display="inline-block" my={1}>

<Box display="flex" flexWrap="wrap" py={0.3}
justifyContent="center"
alignItems="center"
width="100%"

>

{content.facilities?
  <>
<Box component="span" p={0.3} ><BlueTooltip arrow placement="top" enterTouchDelay={100} enterDelay={50} title={"Overnight Parking is"+(content.facilities.facONS?"":" not")+" available."}><img src={content.facilities.facONS?iOvernight:iOvernightOff} width='40' height='40'/></BlueTooltip></Box>
<Box component="span" p={0.3} ><BlueTooltip arrow placement="top" enterTouchDelay={100} enterDelay={50} title={"Black Water Disposal is"+(content.facilities.facBWD?"":" not")+" available."}><img src={content.facilities.facBWD?iBlackWater:iBlackWaterOff} width='40' height='40'/></BlueTooltip></Box>
<Box component="span" p={0.3} ><BlueTooltip arrow placement="top" enterTouchDelay={100} enterDelay={50} title={"Grey Water Disposal is"+(content.facilities.facGWD?"":" not")+" available."}><img src={content.facilities.facGWD?iGreyWater:iGreyWaterOff} width='40' height='40'/></BlueTooltip></Box>
<Box component="span" p={0.3} ><BlueTooltip arrow placement="top" enterTouchDelay={100} enterDelay={50} title={"Drinking Water is"+(content.facilities.facWTR?"":" not")+" available."}><img src={content.facilities.facWTR?iWater:iWaterOff} width='40' height='40'/></BlueTooltip></Box>
<Box component="span" p={0.3} ><BlueTooltip arrow placement="top" enterTouchDelay={100} enterDelay={50} title={"Electric Hookup is"+(content.facilities.facEHU?"":" not")+" available."}><img src={content.facilities.facEHU?iElectric:iElectricOff} width='40' height='40'/></BlueTooltip></Box>
<Box component="span" p={0.3} ><BlueTooltip arrow placement="top" enterTouchDelay={100} enterDelay={50} title={"Rubbish Bins are"+(content.facilities.facRUB?"":" not")+" available."}><img src={content.facilities.facRUB?iRubbish:iRubbishOff} width='40' height='40'/></BlueTooltip></Box>
<Box component="span" p={0.3} ><BlueTooltip arrow placement="top" enterTouchDelay={100} enterDelay={50} title={"Recycling Bins are"+(content.facilities.facREC?"":" not")+" available."}><img src={content.facilities.facREC?iRecycling:iRecyclingOff} width='40' height='40'/></BlueTooltip></Box>
<Box component="span" p={0.3} ><BlueTooltip arrow placement="top" enterTouchDelay={100} enterDelay={50} title={"Toilet is"+(content.facilities.facTLT?"":" not")+" available."}><img src={content.facilities.facTLT?iToilet:iToiletOff} width='40' height='40'/></BlueTooltip></Box>
<Box component="span" p={0.3} ><BlueTooltip arrow placement="top" enterTouchDelay={100} enterDelay={50} title={"Shower is"+(content.facilities.facSWR?"":" not")+" available."}><img src={content.facilities.facSWR?iShower:iShowerOff} width='40' height='40'/></BlueTooltip></Box>
<Box component="span" p={0.3} ><BlueTooltip arrow placement="top" enterTouchDelay={100} enterDelay={50} title={"Laundry is"+(content.facilities.facLND?"":" not")+" available."}><img src={content.facilities.facLND?iLaundry:iLaundryOff} width='40' height='40'/></BlueTooltip></Box>
<Box component="span" p={0.3} ><BlueTooltip arrow placement="top" enterTouchDelay={100} enterDelay={50} title={"Wifi is"+(content.facilities.facWFI?"":" not")+" available."}><img src={content.facilities.facWFI?iWifi:iWifiOff} width='40' height='40'/></BlueTooltip></Box>
</>
:""}

</Box>


</Box>

</div>



<Box my={1}>
  {content.desc?<p className="noselect">{parse(desc)}</p>:""}
  {content.rules?<Box p={0} className="noselect"><b>Rules:</b>
    <ul style={{paddingLeft: "20px"}}>
      {
          rules.map((rule, index) => {
          return(<li>{rule}</li>);
        })
      }
    </ul>
    </Box>
    :""}
    </Box>
    </Box>
    </Paper>
{isCampra?<p className="noselect"><br/><br/></p>:breakpoint>width?
  <p className="noselect"><br/><br/><br/><br/><br/></p>
  :
  <p className="noselect"><br/><br/></p>
}


</div>



{!isCampra?(breakpoint>width?




  <div  id="grad1" style={{display: 'flex', alignItems: 'center', display: '-webkit-flex', WebkitAlignItems: 'center', zIndex: 999, height: '120px', position: 'fixed', left: '0px', right: '0px', bottom: '0px'}}>

  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    width="100%"

  >
        <div style={{width: 'auto'}}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"

        >
              <Button disabled={!validatePayType('overnight')}  align="center" variant="contained" color="primary"  onClick={() => { handlePayClick('overnight') }}>
                Stay Overnight
              </Button>

</Box>
              <Box my={3}/>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"

              >

              <Button  disabled={!validatePayType('facilities')} align="center"  variant="contained" color="primary"  onClick={() => { handlePayClick('facilities') }}>
                Use Facilities Only
              </Button>
              </Box>
        </div>
      </Box>
  </div>




  :



  <div  id="grad1" style={{display: 'flex', alignItems: 'center', display: '-webkit-flex', WebkitAlignItems: 'center', zIndex: 999, height: '60px', position: 'fixed', left: '0px', right: '0px', bottom: '0px'}}>

  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    width="100%"

  >
      <Button disabled={!validatePayType('overnight')} variant="contained" color="primary"  onClick={() => { handlePayClick('overnight') }}>
        Stay Overnight
      </Button>


      <Box mx={4}/>

      <Button disabled={!validatePayType('facilities')} variant="contained" color="primary"  onClick={() => { handlePayClick('facilities') }}>
        Use Facilities Only
      </Button>

      </Box>
  </div>

):""}





</Box></Box>




</div>

    );
  }




  return (
<>
    <ThemeProvider theme={theme}>
    <CssBaseline/>
    <div className="Site">
      {isLoading?renderLoader():renderLander()}


    </div>
    </ThemeProvider>
    <Dialog
      maxWidth='xs'
       open={sessionValues.length == 2}
       onClose={handlePaymentResultClose}
       aria-labelledby="alert-dialog-slide-title"
       aria-describedby="alert-dialog-slide-description"
     >
       <DialogTitle id="alert-dialog-slide-title" style={{color:"#10385A"}} >{"Payment Successful"}</DialogTitle>
       <DialogContent>
         <DialogContentText id="alert-dialog-slide-description">
             Thank you {sessionValues[0]}, your payment for {sessionValues[1]} was successful. You will receive a receipt by email.
         </DialogContentText>
       </DialogContent>
       <DialogActions>
         <Button onClick={handlePaymentResultClose} color="primary" >
           Ok
         </Button>
       </DialogActions>
     </Dialog>









     <Dialog disabled={isPaying} fullScreen style={{color:"white"}} open={open} onClose={handleClose} >
     <AppBar className={classes.appBar}>
       <Toolbar className={classes.toolbar}>
         <IconButton disabled={isPaying} edge="start" color="inherit" onClick={handleClose} aria-label="close">
           <CloseIcon />
         </IconButton>
         <Typography variant="h6" >
           {content.siteName}
         </Typography>
       </Toolbar>
     </AppBar>
       <DialogContent className={classes.dialogContent}>
       <Typography variant="h2" className={classes.title}>
         {payType==='overnight'?currencyFormat(content.priceStay):currencyFormat(content.priceFacilities)}
       </Typography>
       <Typography variant="subtitle1" className={classes.title}>
         {payType==='overnight'?(validatePayType('facilities')?'Overnight Stay (includes facilities use)': 'Overnight Stay'):'Use Facilities Only'}
       </Typography>
       <br/>
<Paper style={{backgroundColor:"#fffc", width: 'fit-content' ,   display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
}}>
<Box p={1}>

          <form className={classes.form} noValidate>
            <FormControl className={classes.formControl}>

            {/*}<InputLabel  id="demo-simple-select-outlined-label">When</InputLabel>*/}

<InputLabel id="demo-controlled-open-select-label">When</InputLabel>
              <Select
               disabled={isPaying}
                id="when"
                name="when"
                value={when}
                onChange={handleWhenChange}
                label="When"
              >
                <MenuItem value={0}>Today</MenuItem>
                <MenuItem value={1}>Previous day</MenuItem>
              </Select>




</FormControl>




 <br/>




            <CssTextField id="reg"

            value={fields.reg.toUpperCase()}
            disabled={isPaying}
            onChange={handleFieldChange}
            name="reg"
            label="Vehicle Registration" type="text" variant="outlined" size="small"/>
            <br/>

            <CssTextField id="email"

            disabled={isPaying}
            name="email"
            label="Email"
            type="email"
            variant="outlined"
            size="small"
             value={fields.email}
             onChange={handleFieldChange}/>

            <br/>
            <FormControlLabel
              control={
                <Checkbox  id="tandc"

                   disabled={isPaying}
                  checked={tncs}
                  onChange={handleTncsChange}

                />
              }
              label={
    <div>
       <span >I accept the </span>
       {isPaying?
           <>terms and conditions</>
         :
           <Link onClick={tandcs}  className={classes.link} color= 'secondary' hover='secondary' to={'/'}>terms and conditions</Link>
       }
    </div>
    }
            />



            <LoaderButtonX
            size="large"
            type="button"
            isLoading={isPaying}
            disabled={validateForm() || isPaying}
              onClick={() => { handleStripePayClick() }}>Make Secure Payment</LoaderButtonX>

          </form>
</Box>
</Paper>

        </DialogContent>

     </Dialog>
</>
  );
}
