import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";

import "./ListData.css";

import { onError } from "../libs/errorLib";

import { useAppContext } from "../libs/contextLib";
import { useHistory, useParams } from "react-router-dom";

import image from'../images/thinking.jpg';
import iCampra from'../images/campra/Campra Accredited - Green.svg';

import TextField from '@material-ui/core/TextField';
import LoaderButtonX from "../components/LoaderButtonX";
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import Button from '@material-ui/core/Button';

import JSZip from 'jszip';
import FileSaver from 'file-saver';

import spinner from'../images/logo.gif';
import IconButton from '@material-ui/core/IconButton';


import { Link } from 'react-router-dom';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';

import Tooltip from '@material-ui/core/Tooltip';
//import fs from 'fs';
import { existsSync } from 'fs';

const FooterTypography = withStyles({
  root: {
    color: "#fff"
  }
})(Typography);

function getChannelTitle(id) {
  var channelTitle = "pot";
  if (id<10) {
    channelTitle+="00"+id;
  } else if (id<100) {
    channelTitle+="0"+id;
  } else {
    channelTitle+=id;
  }
  return channelTitle;
}

export default function ListData() {

  const { id } = useParams();
  const [maxRows, setMaxRows] = useState(64);
  const [rows, setRows] = useState([]);
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [currentEsk, setCurrentEsk] = useState(null);
  const [authIsSPiVolt, setAuthIsSPiVolt] = useState(false);
  const history = useHistory();



  const columns = createCols();


  const useViewport = () => {
    const [width, setWidth] = React.useState(window.innerWidth);
    const [height, setHeight] = React.useState(window.innerHeight);
    React.useEffect(() => {
      const handleWindowResize = () => {setWidth(window.innerWidth);setHeight(window.innerHeight);};
      window.addEventListener("resize", handleWindowResize);
      return () => window.removeEventListener("resize", handleWindowResize);
    }, []);
    return { width, height };
  }
  const { width, height } = useViewport();


function getSiteDetails() {
  let channelCount;
  let extras;
  switch (id) {
    case 'S0002': //Llyn Fawr
      channelCount = 64;
      extras = 4;
      break;
    case 'S0003': //Oakenholt
      channelCount = 32;
      extras = 5;
      break;
    case 'S0004': //Mina de Touro
      channelCount = 64;
      extras = 5;
      break;
    case 'S0005': //Lisvane
      channelCount = 128;
      extras = 7; // has the extra air temp probe and distance
      break;
      case 'S0006': //Swithland
      channelCount = 96;
      extras = 6; // has the extra air temp probe
      break;
      case 'S0007': //Afan Forest (Swansea Uni)
      channelCount = 64;
      extras = 6; // has the extra air temp probe
      break;
    default:
      channelCount = 64;
      extras = 5;
  }
  return {channelCount: channelCount, extrasCount: extras};
}

useEffect(() => {
  async function onLoad() {
    if (!isAuthenticated) {history.push("/"); return;}
    initForSpivoltId(id);
  }
  onLoad();
}, []);

function getSensorData(limit, spivoltid) {
  console.log(limit, spivoltid);
    return API.get("spivolt", `/data?limit=` + limit +`&id=` + spivoltid +``);
}
function getAllSensorData(limit, spivoltid, esk) {
  if (esk==null) {
    return API.get("spivolt", `/alldata?limit=` + limit +`&id=` + spivoltid +``);
  } else {
    return API.get("spivolt", `/alldata?limit=` + limit +`&id=` + spivoltid +`&esk=` + esk +``);
  }
}

async function initForSpivoltId(id) {
  setIsLoading(true);
  const site = getSiteDetails();
  try {
    const data = await getSensorData(0, id); // 72 = 1 days worth
    setAuthIsSPiVolt(data.authIsSPiVolt);
    console.log('vvvvvvvv');

    console.log(data);
    console.log('ˆˆˆˆˆˆˆ');
    let sensorData = [];
    const sdata = data.data;
    console.log("####################################################");
    console.log(sdata.length);
    console.log("####################################################");
    for (var i = 0; i < sdata.length; ++i) {
      let sd = sdata[i];
      const {base64data, battery, created, uploaded} = sd;
      let buff = new Buffer(base64data, 'base64');
      let text = buff.toString('ascii');
      let sArr = text.split("\n");
      console.log(sArr.length);
      for (var i2 = 0; i2 < sArr.length; ++i2) {
          let dArr = sArr[i2].split(",");
          console.log(dArr);

          var newRow = {};
          for (var i3 = 0; i3 < (site.channelCount+site.extrasCount); ++i3) { //68 //36
              if (i3 < dArr.length) {
                let str = dArr[i3];
                //console.log(colCount, i3, str);
                if (i3 == 0) {
                  newRow['timestamp'] = str;
                } else if (i3 == 1) {
                  newRow["temperature"] = str;
                } else if (i3 == 2) {
                  newRow["pressure"] = str;
                } else if (i3 == 3) {
                  newRow["reftemperature"] = str;
                } else if (i3 == 4 && site.extrasCount>4) {
                  if (id=='S0006') {
                    let rf = parseFloat(str) / 4;
                    newRow["rainfall"] = Number.parseFloat(rf).toFixed(2);
                  } else {
                    newRow["rainfall"] = str;
                  }
                } else if (i3 == 5 && site.extrasCount>5) {
                  newRow["airtemperature"] = str;
                } else if (i3 == 6 && site.extrasCount>6) {
                  newRow["distance"] = str;
                } else {
                  newRow[getChannelTitle(i3 - site.extrasCount + 1)] = str;
                }
              } else {
                newRow[getChannelTitle(i3 - site.extrasCount + 1)] = "-";
              }
          }
          sensorData.push(newRow);
          console.log(newRow);
      }
    }
    // Remove duplicates
    sensorData = [...new Map(sensorData.map(v => [v.timestamp, v])).values()]
    sensorData.sort(function(a, b) {
      var ta = Number(a.timestamp);
      var tb = Number(b.timestamp);
      return tb-ta;
    });
    


    console.log(sensorData);


    setRows(sensorData);
  } catch (e) {
    alert(e);
  }
  setIsLoading(false);
}
function createCols() {
  console.log(id);
  //const colcount = id=='S0003'?128:64;
  const site = getSiteDetails();
  let cols = [
    { id: 'timestamp',
      label: 'Timestamp',
      minWidth: 200,
      format: (value) => (new Date((Number(value)*1000) - 3600000)).toLocaleString('en-GB'),
    },
    { id: 'temperature',
      label: <>temp (&deg;C)</>,
      minWidth: 110,
      align: 'center',
      format: (value) => Number(value) / 100,
    },
    { id: 'reftemperature',
      label: <>ref temp (&deg;C)</>,
      minWidth: 130,
      align: 'center',
      format: (value) => Number(value) / 100,
    },
  ];
  if (site.extrasCount>6) {
    cols.push(
      { id: 'distance',
        label: 'height (cm)',
        minWidth: 130,
        align: 'center',
        format: (value) => Number(value) / 100,
      }
    );
  }
  if (site.extrasCount>5) {
    cols.push(
      { id: 'airtemperature',
        label: <>air temp (&deg;C)</>,
        minWidth: 130,
        align: 'center',
        format: (value) => Number(value) / 100,
      }
    );
  }
  cols.push(
    { id: 'pressure',
      label: 'pressure(kPa)',
      minWidth: 110,
      align: 'center',
      format: (value) => (Number(value) / 100000).toFixed(2),
    },
  );
  if (site.extrasCount>4) {
    cols.push(
      { id: 'rainfall',
        label: 'rainfall(mm)',
        minWidth: 110,
        align: 'center',
        format: (value) => (Number(value)).toFixed(id=='S0006'?2:0),
      }
    );
  }
  for (var i=1;i<=site.channelCount;i++) {
    let channelId = getChannelTitle(i);
    let channelTitle = history.location.state && history.location.state.site?history.location.state.site.content.pots[i].name:channelId;
    cols.push(
      {
        id: channelId,
        label: channelTitle + "(mV)",
        minWidth: 90,
        align: 'center',
        format: (value) => (Number(value) / 1000000).toFixed(2),
      }
    );
  }
  return cols;
}

var datafiles;
var fileIndex = 0;

///////////////// UPLOADER //////////////////////////

const handleFile = async(e) => {
  const content = e.target.result;


  let arr = content.split(",");
  let ts = arr[0];

  let base64text = Buffer.from(content.trim()).toString('base64');
  try {
    const result = await uploadData({ siteId: id, base64Text: base64text, timestamp: ts, battery: '13.0', isFieldDataManualUpload: true});
    readNextFile();
  } catch (e) {
    onError(e);
    setIsUploading(false);
  }
}

function readNextFile() {
  fileIndex += 1;
  if (fileIndex >= datafiles.length) {
    setIsUploading(false);
    return;
  }
  var reader = new FileReader();
  reader.onloadend = handleFile;
  var f = datafiles[fileIndex];
  reader.readAsText(f);
}

function uploadData(data) {
  console.log(data);
  
  return API.post("spivolt", "/org/sites", {
    body: data
  });
  
}
  

const handleUploadClick = async (event) => {
  if (isDownloading) {
    return;
  }
  event.preventDefault();
  

  console.log("uploading...");
  


  if (event.target.files.length == 0) {
    
    return;
  }
  setIsUploading(true);

  fileIndex = -1;
  datafiles = event.target.files;
  readNextFile();
}



///////////////// DOWNLOADER //////////////////////////

const handleDownloadClick = async (event) => {
  if (isDownloading) {
    return;
  }
  event.preventDefault();
  setIsDownloading(true);


  let rslt = await getData(null);
  let arr = rslt.data;
  while (rslt.esk) {
    rslt = await getData(rslt.esk);
    arr.push(...rslt.data);
  }
  let rows = '';
  columns.map((column) => {
    if (rows!='') {
      rows += ',';
    }
    if (typeof column.label === 'string') {
      rows += column.label;
    } else {
      rows += column.label.props.children;
    }
  })
  rows += '\r';
  arr.map((row) => {
    rows += row;
  })



console.log(history);



  let datazip = new JSZip();
  var folder = datazip.folder(history.location.state && history.location.state.site?history.location.state.site.content.name:id);
  folder.file("data.csv", rows);
  saveDataZip(datazip);
}

function saveDataZip(zip) {
  const options = {
  dateStyle: "short"
};
  var title = 'SPiVolt_' + (new Date()).toLocaleDateString(options);
  zip.generateAsync({type:"blob"})
  .then(function(content) {
      FileSaver.saveAs(content, title + ".zip");
      setIsDownloading(false);
  });

}

async function getData(esk) {
  const data = await getAllSensorData(0, id, JSON.stringify(esk));
  return {data: extractData(data), esk: data.data.LastEvaluatedKey};
}

function extractData(data) {
  //console.log(data)
  const site = getSiteDetails();
  let sensorData = [];
  const sdata = data.data.Items;
  console.log("####################################################");
  console.log(sdata.length);
  console.log("####################################################");
  for (var i = 0; i < sdata.length; ++i) {
    let sd = sdata[i];
    const {base64data, battery, created, uploaded} = sd;
    let buff = new Buffer(base64data, 'base64');
    let text = buff.toString('ascii');
    let sArr = text.split("\n");
    for (var i2 = 0; i2 < sArr.length; ++i2) {
        let dArr = sArr[i2].split(",");
        //console.log(dArr);
        var newRow = {};
        for (var i3 = 0; i3 < (site.channelCount+site.extrasCount); ++i3) {
            if (i3 < dArr.length) {
              let str = dArr[i3];
              //console.log(colCount, i3, str);
              if (i3 == 0) {
                newRow['timestamp'] = str;
              } else if (i3 == 1) {
                newRow["temperature"] = str/100;
              } else if (i3 == 2) {
                newRow["pressure"] = str/100000;
              } else if (i3 == 3) {
                newRow["reftemperature"] = str/100;
              } else if (i3 == 4 && site.extrasCount>4) {
                if (id=='S0006') {
                  let rf = parseFloat(str) / 4;
                  newRow["rainfall"] = Number.parseFloat(rf).toFixed(2);
                } else {
                  newRow["rainfall"] = str;
                }
              } else if (i3 == 5 && site.extrasCount>5) {
                newRow["airtemperature"] = str/100;
              } else if (i3 == 6 && site.extrasCount>6) {
                newRow["distance"] = str/100;
              } else {
                newRow[getChannelTitle(i3 - site.extrasCount + 1)] = str/1000000;
              }
            } else {
              newRow[getChannelTitle(i3 - site.extrasCount + 1)] = "-";
            }
        }
        sensorData.push(newRow);
    }
  }

  // Remove duplicates
  sensorData = [...new Map(sensorData.map(v => [v.timestamp, v])).values()]

  sensorData.sort(function(a, b) {
    var ta = Number(a.timestamp);
    var tb = Number(b.timestamp);
    return tb-ta;
  });
  const rows = [];
  sensorData.map(row => {
    let str ='';
    columns.map((column) => {
      if (str!='') {
        str+=',';
      }
      str+=row[column.id];
    })
    str += '\r';
    rows.push(str);
  })


  return rows;
}
//////////////////////////////////////////////////////////////////////

  function renderLander() {
    return (
      <div className={"lander"} >
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
    <h2 style={{color:"#0cb9c2"}}>Field Data: <b>{history.location.state && history.location.state.site?history.location.state.site.content.name:id}</b></h2>
    
    {authIsSPiVolt &&
    <>
      <Box display="flex" alignItems='center' flexDirection="row" flexWrap = 'nowrap' >

      {(isDownloading || isUploading) &&
      <Box mx={1}><img src={spinner} height="20px" width="auto"/></Box>
    }
     <Box mb={1} mr={2}>
    <Button color="primary" variant="contained" component="span" startIcon={<CloudDownloadIcon/>} onClick={(event) => handleDownloadClick(event)}>Download</Button>
   </Box>
    <label htmlFor="upload-data">
    <input
      accept=".DAT"
      
      id="upload-data"
      name="upload-data"
      multiple
      type="file"
      onChange={handleUploadClick}
      hidden
      />
              <Button color="primary" variant="contained" component="span" startIcon={<CloudUploadIcon/>}>Upload</Button>
 
    </label>
    
    
    </Box>
   
</>  
  }
    </Grid>


        <Box height="16px"/>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer style={{ maxHeight: `calc(100vh - 210px)` }}>
            <Table stickyHeader aria-label="sticky table" size={'small' }>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, background:"#f0c239", fontWeight:"bold" }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  .map((row) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.code}  >
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align} >
                              {column.format(value)}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            </TableContainer>
        </Paper>

      </div>
    );
  }

  function renderLoader() {
    return (
      <div id="splash" className={"lander"} style={{paddingTop: (height-100)/2, zIndex:999}}>

        <center id="splash">
          <img src={spinner} height="30px" width="auto"/>
        </center>
      </div>


    );

  }


  return (
    <div className="ListData">
      {isLoading?renderLoader():renderLander()}
    </div>
  );
}
