import React, { useRef, useState, useEffect } from "react";
import { Form, InputGroup, FormGroup, FormControl, FormLabel, Breadcrumb } from "react-bootstrap";

import { useHistory } from "react-router-dom";
import { useAppContext } from "../libs/contextLib";

import LoaderButtonStandard from "../components/LoaderButtonStandard";
import { onError } from "../libs/errorLib";
import config from "../config";
import "./NewOrg.css";
import { API } from "aws-amplify";

import { red, green } from '@material-ui/core/colors';


import { useFormFields } from "../libs/hooksLib";
import { makeStyles, withStyles } from '@material-ui/core/styles';


import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Box from '@material-ui/core/Box';
//import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

//import FileOpenIcon from '@material-ui/icons/FiberManualRecord';
//import Fab from "@material-ui/core/Fab";
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import Button from '@material-ui/core/Button';
//import IconButton from '@material-ui/core/IconButton';

//import TextareaAutosize from '@material-ui/core/TextareaAutosize';


import CheckboxFacilities from "../components/CheckboxFacilities";



import RuleInput from "../components/RuleInput"
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';

//import { usePlacesWidget } from "react-google-autocomplete";
//import AutoComplete from "react-google-autocomplete";
//import Geocode from "react-geocode";




import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@material-ui/icons/KeyboardArrowUpRounded';
//import { GoogleMap, useJsApiLoader, Marker, StandaloneSearchBox, InfoWindow, InfoBox } from '@react-google-maps/api';
//import pinIcon from'../images/pin.svg';


import {
  FiCard,
  FiCardActionArea,
  FiCardActions,
  FiCardContent,
  FiCardMedia
} from "../components/ImageCard";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',

    justifyContent: 'space-around',
    flexFlow: 'row wrap',
    alignItems: 'stretch',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  box: {
    flexGrow: 1,
  },
  clear: {height: '30px', width: '30px', cursor:'pointer', float:'right', marginTop: '-14px', marginRight: '-14px', backgroundColor:'#0001', color:'#fff'},
  box1: {
    flexGrow: 1,
  }
}));

export default function NewOrg() {
  //Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY2);
  //Geocode.setLocationType("ROOFTOP");
  const classes = useStyles();
  const ref = useRef(null);

  const file = useRef(null);
  const history = useHistory();
  const { isCampra } = useAppContext();

  const defaultRules=[
    //{key:0, label:"Contact head office only"},
  ];
  const [isLoading, setIsLoading] = useState(false);
  const [siteOpen, setSiteOpen] = useState(true);
  const [base64Image, setBase64Image] = useState("");
  const [address, setAddress] = useState("");
  const [tempAddress, setTempAddress] = useState("");
  const [location, setLocation] = useState(null);
  const [mapLink, setMapLink] = useState("");
  const [map, setMap] = useState(null);
  const [mapOpen, setMapOpen] = useState(false);
  const [changestate, setChangestate] = useState(false);
  const [isPricingOvernight, setIsPricingOvernight] = useState(false);
  const [isPricingFacilities, setIsPricingFacilities] = useState(false);
  const [newRule, setNewRule] = useState("");
  const [ruleData, setRuleData] = React.useState([]);
  const [priceStay, setPriceStay] = useState("");
  const [priceFacilities, setPriceFacilities] = useState("");
  const [bays, setBays] = useState("");

  const [ratio, setRatio] = useState(1.0);
  const [instanceKey, setInstanceKey] = useState(0); // for ruleinput component
  const [fields, handleFieldChange] = useFormFields({
    name: "",
    desc: "",
    address: "",
    notes: [],
    contact: {
      name: "",
      phone: "",
      email: "",
    },
  });

/*
  const eventhandler = state => {
    fields.facilities.facBWD=state.facBWD;
    fields.facilities.facGWD=state.facGWD;
    fields.facilities.facEHU=state.facEHU;
    fields.facilities.facLND=state.facLND;
    fields.facilities.facREC=state.facREC;
    fields.facilities.facRUB=state.facRUB;
    fields.facilities.facSWR=state.facSWR;
    fields.facilities.facTLT=state.facTLT;
    fields.facilities.facWTR=state.facWTR;
    fields.facilities.facWFI=state.facWFI;
    if (!fields.facilities.facWFI) {
      fields.wifiNetwork = "";
      fields.wifiCode = "";
    }
    setChangestate(!changestate);
  }


const OpenClosedSwitch = withStyles({
  switchBase: {
    color: red[700],
    '&$checked': {
      color: green[500],
    },
    '&$checked + $track': {
      backgroundColor: green[500],
    },
  },
  checked: {},
  track: {},
})(Switch);

const handleSiteOpenChange = (event) => {
  setSiteOpen(event.target.checked);
}
*/

useEffect(() => {
  //if (isCampra) {history.push("/")}
  window.scrollTo(0, 0);
  setRuleData(defaultRules);
}, [])


function validateForm() {
  /*
  if(address!==""){
    fields.address=address;
    fields.mapLink=mapLink;
    fields.location=location;
  } else {
    fields.address=""
    return false;
  }
  */

  fields.notes=[];
  ruleData.map((data) => {
    fields.notes.push(data.label)
  })

/*
  fields.facilities.facONS = isPricingOvernight;
  fields.priceFacilities=priceFacilities;
  fields.priceStay=priceStay;
*/
  //const hasAtLeastOneFacility=(isPricingOvernight || isPricingFacilities) && ((isPricingOvernight && fields.facilities.facONS) || !isPricingOvernight)  && ((isPricingFacilities && (fields.facilities.facBWD || fields.facilities.facGWD || fields.facilities.facEHU || fields.facilities.facLND || fields.facilities.facREC || fields.facilities.facRUB || fields.facilities.facSWR || fields.facilities.facTLT || fields.facilities.facWTR || fields.facilities.facWFI) || !isPricingFacilities));
  //const hasWifiCode = !fields.facilities.facWFI || (fields.facilities.facWFI && fields.wifiCode.trim().length>0 && fields.wifiNetwork.trim().length>0);
  const hasRequired = fields.name.trim().length > 0;  //&& ((isPricingFacilities && fields.priceFacilities > 0 && (fields.priceFacilities==0 || fields.priceFacilities>=1)) || !isPricingFacilities) && ((isPricingOvernight && fields.priceStay.length > 0 && (fields.priceStay==0 || fields.priceStay>=1)) || !isPricingOvernight) && fields.bays.length > 0  && fields.bays > 0 && fields.desc.length > 0 && fields.rules.length > 0;
  return(hasRequired);
}

function handleFileChange(event) {
  file.current = event.target.files[0];
}

function handleContactChange(event) {
  fields.contact[event.target.name] = event.target.value;
  handleFieldChange();
}

/*
function handleBaysChange(event) {
  const val = event.target.value;
  if (!isNaN(val)) {
    if (val.indexOf(".")>-1 || val.indexOf("0")===0) { //integer only
      return;
    }
    fields.bays = val;
    setBays(fields.bays);
  }
}


function handlePriceStayChange(event) {
  const val = event.target.value;
  if (!isNaN(val)) {
    if (val.indexOf(".")>-1) {
      let parts = val.split(".");
      if (parts[1].length > 2) {
        return;
      }
      if (val.indexOf("0")===0 && val.length>1 && val.indexOf(".")!==1) {
        return;
      }
    } else if (val.indexOf("0")===0 && val.length>1) {
      return;
    }
    fields.priceStay = val;
    setPriceStay(fields.priceStay);
  }
}


function handlePriceStayOnBlur(event) {
  const val = event.target.value;
  if (val.indexOf(".")>-1) {
    let parts = val.split(".");
    if (parts[1].length === 2) {
      return;
    } else if (parts[1].length === 1) {
      fields.priceStay = val + "0";
    } else if (parts[1].length === 0) {
      fields.priceStay = val + "00";
    }
  } else {
    if (val.length === 0) {
      fields.priceStay = val + "0.00";
    } else {
      fields.priceStay = val + ".00";
    }
  }
  setPriceStay(fields.priceStay);
}


function handlePriceFacilitiesChange(event) {
  const val = event.target.value;
  if (!isNaN(val)) {
    if (val.indexOf(".")>-1) {
      let parts = val.split(".");
      if (parts[1].length > 2) {
        return;
      }
      if (val.indexOf("0")===0 && val.length>1 && val.indexOf(".")!==1) {
        return;
      }
    } else if (val.indexOf("0")===0 && val.length>1) {
      return;
    }
    fields.priceFacilities = val;
    setPriceFacilities(fields.priceFacilities);
  }
}

function handlePriceFacilitiesOnBlur(event) {
  const val = event.target.value;
  if (val.indexOf(".")>-1) {
    let parts = val.split(".");
    if (parts[1].length === 2) {
      return;
    } else if (parts[1].length === 1) {
      fields.priceFacilities = val + "0";
    } else if (parts[1].length === 0) {
      fields.priceFacilities = val + "00";
    }
  } else {
    if (val.length === 0) {
      fields.priceFacilities = val + "0.00";
    } else {
      fields.priceFacilities = val + ".00";
    }
  }
  setPriceFacilities(fields.priceFacilities);
}

const handleChangeAddress = (e) => {
  setTempAddress(e.target.value);
}
*/

function getReducedImageAsBase64(file) {
  var reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(null);
    };
    reader.onload = () => {
      var img = new Image();
      img.onload = function() {
        setRatio(img.height/img.width);
        let h = 600;
        let w = 600;
        if (img.height > img.width) {
          w = w * (img.width / img.height);
        } else {
          h = h * (img.height / img.width);
        }
        var oc = document.createElement('canvas');
        oc.width = w;
        oc.height = h;
        var ctx = oc.getContext('2d');
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, w, h);
        ctx.drawImage(img, 0, 0, w, h);
        var dataurl = oc.toDataURL('image/jpeg'); //context.file.type
        resolve(dataurl);
      }
      img.src = reader.result
    };
    reader.readAsDataURL(file);
  });
}
async function handleUploadClick(event) {
  if (event.target.files.length == 0) {
    return;
  }
  var file = event.target.files[0];
  const reader = new FileReader();
  var url = reader.readAsDataURL(file);

  if (file && file.type.indexOf("image") == -1) {
    file = null;
} else {
  if (file) {
    const base64 = await getReducedImageAsBase64(file);
    setBase64Image("" + base64);
  }
}
};

function handleClearPic() {
  setBase64Image("");
}



/*
function handlePricingFacilitiesCheck(event) {
  if (!event.target.checked) {
    fields.priceFacilities="";
    setPriceFacilities(fields.priceFacilities);
    if (!isPricingOvernight) {
      //clearFacilities();
    }
  }
  setIsPricingFacilities(event.target.checked);
}
function handlePricingOvernightCheck(event) {
  if (!event.target.checked) {
    fields.priceStay="";
    setPriceStay(fields.priceStay);
    if (!isPricingFacilities) {
      //clearFacilities();
    }
  }
  fields.facilities.facONS = event.target.checked;
  setIsPricingOvernight(event.target.checked);
}
*/

function handleAddRuleClick() {
  fields.notes.unshift(newRule);
  ruleData.splice(0,ruleData.length)
  fields.notes.map((rule, index) => {
    ruleData.push({ key: index, label: rule });
  })
  setInstanceKey(i => i + 1);
  setNewRule("");
}
const newRuleHandler = data => {setNewRule(data.rule) }


React.useEffect(() => {
  fields.notes=[];
  ruleData.map((data) => {
    fields.notes.push(data.label)
  })
}, [ruleData]);

const handleDeleteRule = (ruleToDelete) => () => {
  setRuleData((rules) => rules.filter((rule) => rule.key !== ruleToDelete.key));
};



async function handleSubmit(event) {
  event.preventDefault();

  /*
  if (file.current && file.current.size > config.MAX_ATTACHMENT_SIZE) {
  alert(
  `Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE /
  1000000} MB.`
);
return;
}
*/

setIsLoading(true);

try {
  const result = await createOrg({ content: fields, image: base64Image});
  history.push("/orgs");
} catch (e) {
  onError(e);
  setIsLoading(false);
}
}

function createOrg(org) {
  return API.post("spivolt", "/orgs", {
    body: org
  });
}

/*
async function onMarkerDragEnd(t) {
  if (t && t.latLng) {
    setLocation({"lat": t.latLng.lat(), "lng": t.latLng.lng()});
    Geocode.fromLatLng(t.latLng.lat(),  t.latLng.lng()).then(
      (response) => {
        const address = response.results[0].formatted_address;
        const url = response.results[0].url;
        setAddress(address);
        setMapLink(url);
      },
      (error) => {
        console.error(error);
      }
    );
  }
}

function openMapClick(e) {
  e.preventDefault();
  setMapOpen(!mapOpen);
}
const containerStyle = {
  width: '100%',
  height: "300px"
};
const onMapLoad = React.useCallback(function callback(map) {
  //map.fitBounds(UK_BOUNDS);
  setMap(map)
}, [])

const onUnmount = React.useCallback(function callback(map) {
  setMap(null)
}, [])

const onMapClick = (...args) => {
  //setInfoOpen(false);
}
const UK_BOUNDS = {
  south: 49.79129622491783,
  west: -10.795963172325314,
  north: 58.84452996184098,
  east: 2.1239587026746864
};

const RESTRICT_BOUNDS = {south: 10.994892342824796, west: -39.88202649919864, north: 74.86496038005808, east: 27.61797350080136};

const mapOptions = {
  restriction: {
    latLngBounds: RESTRICT_BOUNDS,
  },
  mapTypeControl: true,
  streetViewControl: false,
};





function addressClickHandler(e) {
  e.preventDefault();
}

*/

handleFileChange = async event => {
  event.preventDefault();
  this.file = event.target.files[0];
  if (this.file && this.file.type.indexOf("image") == -1) {
    this.file = null;
    this.inputEl.value='';
    this.setState({
      alertOpen: true,
      alertTitle: 'Invalid Image Format',
      alertMessage: 'Please choose a valid image for the employee pic.'
    });
  } else {
    if (this.file) {
      const base64 = await this.getReducedImageAsBase64(this);
      this.setState({ binaryImage: base64});
    }
  }

}

return (
  <div  className="lander">


  <Form onSubmit={handleSubmit} >
  <Box  paddingLeft={'12px'} paddingTop={'12px'} paddingRight={'12px'} paddingBottom={0}>
  <h3>Create New Organisation</h3>

  <Paper variant="outlined">
  <Box  p={1}>
  <a><b>*Organisation Name</b><br/>The name of the Organisation.</a>
  </Box>
  <Box display="flex" p={1} padding={0} >
  <Box p={1} flexGrow={1} >
  <TextField id="name"
  value={fields.name}
  onChange={handleFieldChange}
  name="name"
  inputProps={{style: {textTransform: 'none'}}}
  fullWidth
  label="*Org Name" type="text" variant="outlined" size="small"/>
  </Box>
  </Box>
  </Paper>





  <br/>





  <Paper variant="outlined">
  <Box  p={1}>
  <a><b>Address</b><br/>Enter the address of the Organisation.</a>
  </Box>
  <Box  p={1}>
  <TextField id="address"
  value={fields.address}
  onChange={handleFieldChange}
  name="address"
  inputProps={{style: {textTransform: 'none'}}}
  fullWidth
  label="*Address" type="text" variant="outlined" size="small"/>
  </Box>
  </Paper>




  <br/>


  <Paper variant="outlined">
  <Box  p={1}>
  <a><b>Contact</b><br/>Contact details.</a>
  </Box>
  <Box display="flex" p={1} padding={0} >
  <Box p={1} flexGrow={1} >
  <TextField id="contactName"
  value={fields.contact.name}
  onChange={handleContactChange}
  name="name"
  inputProps={{style: {textTransform: 'none'}}}
  fullWidth
  label="Contact Name" type="text" variant="outlined" size="small"/>
  </Box>
  <Box p={1} flexGrow={1} >
  <TextField id="contactPhone"
  value={fields.contact.phone}
  onChange={handleContactChange}
  name="phone"
  inputProps={{style: {textTransform: 'none'}}}
  fullWidth
  label="Contact Phone" type="text" variant="outlined" size="small"/>
  </Box>
  <Box p={1} flexGrow={1} >
  <TextField id="contactEmail"
  value={fields.contact.email}
  onChange={handleContactChange}
  name="email"
  inputProps={{style: {textTransform: 'none'}}}
  fullWidth
  label="Contact Email" type="text" variant="outlined" size="small"/>
  </Box>
  </Box>
  </Paper>




  <FiCard ref={ref} className={classes.card} variant="outlined" >
  <FiCardMedia
  media="picture"
  alt="Organisation"
  image={base64Image}
  title="Organisation"
  />
  <FiCardContent className={classes.fiCardContent} >
  {base64Image?
    <ClearRoundedIcon className={classes.clear} onClick={handleClearPic} />
    :
    <Box  mt={-1} ml={-1}>
    <a><b>Organisation Photo</b><br/>We recommend a <b><i>landscape</i></b> oriented photo of the Organisation.</a><br/><br/>
    </Box>
  }
  <Box  display="flex" p={0} m={0} style={{height:ratio*(base64Image && ref.current ? ref.current.offsetWidth : 0)}}>
    <label htmlFor="upload-photo">
      <input
        accept="image/*"
        className={classes.input}
        id="upload-photo"
        name="upload-photo"
        //multiple
        type="file"
        onChange={handleUploadClick}
        hidden
        />
        <Button color="primary" variant="contained" component="span" startIcon={base64Image?<InsertPhotoIcon/>:<AddPhotoAlternateIcon/>}>
          {base64Image?"Change Photo":"Add Photo"}
        </Button>
  </label>
  </Box>
  <br/><br/>
  </FiCardContent>
  </FiCard>
  <br/>



  <br/>
  <Paper variant="outlined">
  <Box  p={1}>
  <a><b>*Description</b><br/>Use this space to welcome your guests, tell them a bit about your AireStop, and let them know about things they can do locally.</a>
  </Box>
  <Box  p={1}>


  <TextField
  id="desc"
  name="desc"
  value={fields.desc}
  onChange={handleFieldChange}
  label="Enter description"
  placeholder="description"
  multiline
  fullWidth
  size="small"
  variant="outlined"
  inputProps={{ maxLength: 1000 }}
  />

  </Box>
  </Paper>
  <br/>

  <Paper variant="outlined">
  <Box  p={1}>
  <a><b>*Extra Info</b><br/>Add extra info items here.<br/>Once you’ve added an item hit the + on the right to add another. To remove an item click the x on the item.</a>
  </Box>
  <Box px={1}>
  <RuleInput id="ruleid"  key={instanceKey}
  onChange={newRuleHandler}
  size="small"
  onClick={() => { handleAddRuleClick() }}
  />
  </Box>
  <Box  p={1}
  flexDirection="column">
  {ruleData.map((data) => {
    return (

      <Chip key={data.key}
      variant="outlined"
      size="small"
      color="primary"
      style={{height:"100%",margin: '8px'}}
      label={<Typography style={{whiteSpace: 'normal'}}>{data.label}</Typography>}
      onDelete={handleDeleteRule(data)}
      />

    );
  })}
  </Box>
  </Paper>
  <br/>
  <br/>



























  <Box  padding={0}>


  <LoaderButtonStandard
  fullWidth
  type="submit"
  size="lg"
  variant="primary"
  isLoading={isLoading}
  disabled={!validateForm()}
  >
  Create Organisation
  </LoaderButtonStandard>

  </Box>

  <br/>

  </Box>

  </Form>
  </div>
);
}
