import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useAppContext } from "../libs/contextLib";
import { useHistory } from "react-router-dom";
import LoaderButtonX from "../components/LoaderButtonX";
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { Link } from 'react-router-dom';
import image from'../images/about.jpg';
import iCampra from'../images/campra/Campra Accredited - Green.svg';
import iSFS from'../images/SFS/sfs_logo_full.png';
import "./About.css";


const FooterTypography = withStyles({
  root: {
    color: "#fff"
  }
})(Typography);



export default function About() {
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const useViewport = () => {
    const [width, setWidth] = React.useState(window.innerWidth);

    React.useEffect(() => {
      const handleWindowResize = () => setWidth(window.innerWidth);
      window.addEventListener("resize", handleWindowResize);
      return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    // Return the width so we can use it in our components
    return { width };
  }

  const { width } = useViewport();
  const breakpoint = 600;

  function handleClick(event) {
    event.preventDefault();
    history.push("/signup");
  }

  function handleContactClick(event) {
    event.preventDefault();
    history.push("/contact", { from: 'about' } );
  }


  function renderLander() {
    return (
      <>
      <div className={width>breakpoint?"lander":"mobile-lander"} >

About...
</div>


</>




    );




  }




  return (
    <div className="About">
      {renderLander()}
    </div>
  );
}
