import React from 'react';
import { PropTypes } from 'prop-types';

function renderText(child, x, y, rotate, stroke, key) {
  if (child && child.content) {
    return (<text
      key={key}
      x={x}
      y={y}
      transform={`rotate(${rotate})`}
      textAnchor="middle"
      stroke={stroke}
      {...child.props}>
      {child.content}
    </text>);
  }

  return (<text
    key={key}
    x={x}
    y={y}
    transform={`rotate(${rotate})`}
    textAnchor="middle"
    stroke={stroke}>{child}</text>);
}

export default function AxisLabel({ viewBox, axisType, stroke, children, xOffset, yOffset }) {
  const x = viewBox.x;
  const y = viewBox.y;
  const width = viewBox.width;
  const height = viewBox.height;
  const isVert = axisType === 'yAxis';
  const cx = xOffset + (isVert ? x : x + (width / 2));
  const cy = yOffset + (isVert ? (height / 2) + y : y + height + 20);
  const rot = isVert ? `270 ${cx} ${cy}` : 0;
  const lineHeight = 20;
  console.log("axisType:");
  console.log(isVert,cx,cy,rot,lineHeight,xOffset,yOffset);
  console.log(viewBox);
  console.log(children);
  if (children.length > 1 && children.map) {
    return (<g>
      {children.map((child, index) =>
        renderText(
          child,
          cx,
          cy + index * lineHeight,
          rot,
          stroke,
          index)
      )}
    </g>);
  }

  return renderText(children, cx, cy, rot, stroke);
}

AxisLabel.propTypes = {
  axisType: PropTypes.oneOf(['yAxis', 'xAxis']),
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  stroke: PropTypes.string,
  children: PropTypes.any,
  aaaa: PropTypes.any,
};
