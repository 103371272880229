import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import About from "./containers/About";
import Test from "./containers/Test01";
import Hosting from "./containers/Thinking";
import Login from "./containers/Login";
import Account from "./containers/Account";
import Manager from "./containers/Manager";
import Signup from "./containers/Signup";
import NewOrg from "./containers/NewOrg";
import NewSite from "./containers/NewSite";
import NewUser from "./containers/NewUser";
import NewOverlay from "./containers/NewOverlay";
import Overlay from "./containers/Overlay";
import EditSite from "./containers/EditSite";
import Gallery from "./containers/Gallery";
import NewImages from "./containers/NewImages";
import Site from "./containers/Site";
//import SiteA from "./containers/Site";
import Contact from "./containers/Contact";
//import ContactA from "./containers/Contact";
import Sites from "./containers/Sites";
import Sign from "./containers/Sign";
//import Reauth from "./containers/Reauth";
//import Onboard from "./containers/Onboard";
import Mapper from "./containers/Mapper";
import Map from "./containers/Map";









import ListData from "./containers/ListData";
import ListAdmin from "./containers/ListAdmin";
import ListUsers from "./containers/ListUsers";

import NotFound from "./containers/NotFound";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";


export default function Routes() {
  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <UnauthenticatedRoute exact path="/test"><Test /></UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/about"><About /></UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/login"><Login /></UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/forgot"><Manager /></UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/signup"><Signup /></UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/sites/:id"><Site /></UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/sign"><Sign /></UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/hosting"><Hosting /></UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/contact"><Contact /></UnauthenticatedRoute>

      <AuthenticatedRoute exact path="/contactus"><Contact /></AuthenticatedRoute>
      <AuthenticatedRoute exact path="/campra/:id"><Site /></AuthenticatedRoute>




      <AuthenticatedRoute exact path="/sites/:id/data"><ListData /></AuthenticatedRoute>
      <AuthenticatedRoute exact path="/admin"><ListAdmin /></AuthenticatedRoute>
      <AuthenticatedRoute exact path="/admin/users"><ListUsers /></AuthenticatedRoute>
      <AuthenticatedRoute exact path="/sites/map"><Mapper /></AuthenticatedRoute>
      <AuthenticatedRoute exact path="/sites/mapx"><Mapper /></AuthenticatedRoute>



      <AuthenticatedRoute exact path="/account"><Account /></AuthenticatedRoute>
      <AuthenticatedRoute exact path="/map/:id"><Map /></AuthenticatedRoute>
      <AuthenticatedRoute exact path="/account/edit"><Manager /></AuthenticatedRoute>
      <AuthenticatedRoute exact path="/sites"><Sites /></AuthenticatedRoute>
      <AuthenticatedRoute exact path="/sites/create/:id/:id2"><NewSite /></AuthenticatedRoute>
      <AuthenticatedRoute exact path="/overlays/create/:id/:id2"><NewOverlay /></AuthenticatedRoute>
      <AuthenticatedRoute exact path="/overlays/edit/:id/:id2/:id3"><Overlay /></AuthenticatedRoute>
      <AuthenticatedRoute exact path="/orgs/create/new"><NewOrg /></AuthenticatedRoute>
      <AuthenticatedRoute exact path="/users/create/new"><NewUser /></AuthenticatedRoute>
      <AuthenticatedRoute exact path="/sites/:id/edit"><EditSite /></AuthenticatedRoute>
      <AuthenticatedRoute exact path="/gallery/:id"><Gallery /></AuthenticatedRoute>
      <AuthenticatedRoute exact path="/images/add/:id"><NewImages /></AuthenticatedRoute>
      {/*<AuthenticatedRoute exact path="/reauth"><Reauth /></AuthenticatedRoute>
      <AuthenticatedRoute exact path="/onboard"><Onboard /></AuthenticatedRoute>*/}

      {/*<UnauthenticatedRoute exact path="/pay0"><PaymentFailure /></UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/pay1"><PaymentSuccess /></UnauthenticatedRoute>*/}

{/*
      <AuthenticatedRoute path="/stripe" exact component={() => window.location = ''} props={childProps}/>
      */}


      {/* Finally, catch all unmatched routes */}
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}
