import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import AxisLabel from "./AxisLabel";

import {
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
ReferenceArea,
  ComposedChart,
 Line,
 Area,
 Bar,

} from 'recharts'

const CustomizedDot = (props) => {
  const { cx, cy, stroke, payload, value } = props;
return (<svg/>);
  if (value > 2500) {
    return (
      <svg x={cx - 10} y={cy - 10} width={20} height={20} fill="red" viewBox="0 0 1024 1024">
        <path d="M512 1009.984c-274.912 0-497.76-222.848-497.76-497.76s222.848-497.76 497.76-497.76c274.912 0 497.76 222.848 497.76 497.76s-222.848 497.76-497.76 497.76zM340.768 295.936c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM686.176 296.704c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM772.928 555.392c-18.752-8.864-40.928-0.576-49.632 18.528-40.224 88.576-120.256 143.552-208.832 143.552-85.952 0-164.864-52.64-205.952-137.376-9.184-18.912-31.648-26.592-50.08-17.28-18.464 9.408-21.216 21.472-15.936 32.64 52.8 111.424 155.232 186.784 269.76 186.784 117.984 0 217.12-70.944 269.76-186.784 8.672-19.136 9.568-31.2-9.12-40.096z" />
      </svg>
    );
  }

  return (
    <svg x={cx - 10} y={cy - 10} width={20} height={20} fill="green" viewBox="0 0 1024 1024">
      <path d="M517.12 53.248q95.232 0 179.2 36.352t145.92 98.304 98.304 145.92 36.352 179.2-36.352 179.2-98.304 145.92-145.92 98.304-179.2 36.352-179.2-36.352-145.92-98.304-98.304-145.92-36.352-179.2 36.352-179.2 98.304-145.92 145.92-98.304 179.2-36.352zM663.552 261.12q-15.36 0-28.16 6.656t-23.04 18.432-15.872 27.648-5.632 33.28q0 35.84 21.504 61.44t51.2 25.6 51.2-25.6 21.504-61.44q0-17.408-5.632-33.28t-15.872-27.648-23.04-18.432-28.16-6.656zM373.76 261.12q-29.696 0-50.688 25.088t-20.992 60.928 20.992 61.44 50.688 25.6 50.176-25.6 20.48-61.44-20.48-60.928-50.176-25.088zM520.192 602.112q-51.2 0-97.28 9.728t-82.944 27.648-62.464 41.472-35.84 51.2q-1.024 1.024-1.024 2.048-1.024 3.072-1.024 8.704t2.56 11.776 7.168 11.264 12.8 6.144q25.6-27.648 62.464-50.176 31.744-19.456 79.36-35.328t114.176-15.872q67.584 0 116.736 15.872t81.92 35.328q37.888 22.528 63.488 50.176 17.408-5.12 19.968-18.944t0.512-18.944-3.072-7.168-1.024-3.072q-26.624-55.296-100.352-88.576t-176.128-33.28z" />
    </svg>
  );
};


export default function MultiPotChart(props) {
  const [refAreaLeft, setRefAreaLeft] = useState("");
  const [refAreaRight, setRefAreaRight] = useState("");
  const [initialData, setInitialData] = useState(props.chartData.data);
  const [pots, setPots] = useState(props.chartData.pots);
  const [data, setData] = useState(props.chartData.data);
  const [dataType, setDataType] = useState(props.chartData.datatype);
  const [left, setLeft] = useState("dataMin");
  const [right, setRight] = useState("dataMax");
  const [bottom, setBottom] = useState("dataMin");
  const [top, setTop] = useState("dataMax");
  const [bottom2, setBottom2] = useState("dataMin");
  const [top2, setTop2] = useState("dataMax");
  const [idxStart, setIdxStart] = useState(0);
  const [idxEnd, setIdxEnd] = useState(0);

  const getAxisYDomain = (
    from: number,
    to: number,
    ref: string,
    offset: number
  ) => {
    //const refData = initialData.slice(from, to);
    let refData;
    if (from == -999 || to == -999) {
      refData = initialData.slice();
    } else {
      refData = initialData.slice(from, to);
    }
    console.log(refData);
    //let [bt, tp] = [refData[0][ref], refData[0][ref]];
    let [bt, tp] = [1000000, -1000000];
console.log(bt, tp);
    refData.forEach((d) => {
      pots.forEach((pot) => {
        const val = Number(d[pot.name]);
        if (val > tp) {
          tp = val;
          //console.log(">",val);
        } else if (val < bt) {
          bt = val;
          //console.log("<",val);
        }
      });
    });
console.log(bt, tp);
//return [(bottom | 0) - offset-3, (top | 0) + offset+3];
return [(bt | 0) - offset, (tp | 0) + offset];
  };

  useEffect(() => {
    const [bb, tt] = getAxisYDomain(-999, -999, 1);
    //console.log(bb, tt);
    setBottom(bb);
    setTop(tt);


  }, [])


  function zoom() {
      //let { refAreaLeft, refAreaRight } = this.state;
      //const { data } = this.state;

      if (refAreaLeft === refAreaRight || refAreaRight === "") {
        setRefAreaLeft("");
        setRefAreaRight("");
        return;
      }

      // xAxis domain
      /*
      if (refAreaLeft > refAreaRight)
        [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];
        */
      let start = idxStart;
      let end = idxEnd;
      if (idxStart > idxEnd) {
        start = idxEnd;
        end = idxStart;
      }

      console.log(start, end)
      // yAxis domain
      const [bt, tp] = getAxisYDomain(start, end, 1);
      const [bt2, tp2] = getAxisYDomain(
        start,
        end,
        "pressure",
        1
      );

      console.log(bt, tp);
      console.log(bt2, tp2);
      console.log(left, right);
      /*
      const [bottom, top] = getAxisYDomain(refAreaLeft, refAreaRight, "mv", 1);
      const [bottom2, top2] = getAxisYDomain(
        refAreaLeft,
        refAreaRight,
        "pressure",
        1
      );
      */

      setLeft(refAreaLeft);
      setRight(refAreaRight);
      setRefAreaLeft("");
      setRefAreaRight("");
      setData(data.slice());
      setBottom(bt);
      setTop(tp);
      setBottom2(bt2);
      setTop2(tp2);

      console.log(left, right);
    }


    function zoomOut() {
      setData(data.slice());
      setRefAreaLeft("");
      setRefAreaRight("");
      setRight("dataMax");
      setLeft("dataMin");
      const [bb, tt] = getAxisYDomain(-999, -999, 1);
      setBottom(bb);
      setTop(tt);
      setBottom2("dataMin");
      setTop2("dataMax");
    }


//       domain = {[props.chartData[0].time, props.chartData[props.chartData.length-1].time]}
  console.log(data);
  return(
    <>
    <button
       type="button"
       className="btn update"
       onClick={zoomOut}
     >
       Zoom Out
     </button>

  <ResponsiveContainer width = '95%' height = {800} >
  <ComposedChart
     data={data}
     onMouseDown= {(e) => {setRefAreaLeft(e.activeLabel); setIdxStart(e.activeTooltipIndex)}}
     onMouseMove= {(e) => {if (refAreaLeft!="") setRefAreaRight(e.activeLabel)}}
     onMouseUp= {(e) => {setIdxEnd(e.activeTooltipIndex); zoom()}}
     margin={{
       top: 20,
       right: 20,
       bottom: 20,
       left: 20
     }}
   >
     <CartesianGrid stroke="#f5f5f5" />
     <XAxis

     allowDataOverflow
       dataKey = 'time'
       name = 'Time'
       domain={[left, right]}
       tickFormatter = {(unixTime) => moment(unixTime).format('HH:mm Do')}
       type = 'number'
      label={""}
     />
     <YAxis allowDataOverflow yAxisId="mv" dx={0} domain={[bottom,top]}  label={{ value: `mV`, angle: -90, position: 'insideLeft'}} />
     <YAxis allowDataOverflow yAxisId="tmp" dx={0} orientation="right" label={{ value: `℃`, angle: -90, position: 'outsideLeft' }}/>
     <YAxis allowDataOverflow yAxisId="pressure" dx={0} domain={[80,120]} orientation="right"  label={<AxisLabel {...props} axisType='yAxis' stroke={1} yOffset={0} xOffset={45}>kPa</AxisLabel>}/>

      <Tooltip labelFormatter={t => new Date(t).toLocaleString()} />
     <Legend />


     {pots.map(pot => {
       return(
         <Line yAxisId="mv" type="monotone" dataKey={pot.name} stroke={pot.colour} name={pot.name} dot={<CustomizedDot/>}/>
       )
     })}




     <Line yAxisId="tmp" type="monotone" strokeDasharray="9 3" strokeWidth={2} dataKey="tmp" stroke="#413ea0"  name='temperature (&deg;C)' dot={<CustomizedDot/>} />
     <Line yAxisId="tmp" type="monotone" strokeDasharray="9 3" strokeWidth={2} dataKey="reftmp" stroke="#ff0000"  name='ref temperature (&deg;C)' dot={<CustomizedDot/>} />
     <Line yAxisId="pressure" type="monotone" strokeDasharray="9 3" strokeWidth={2} dataKey="pressure" stroke="#ff7300"  name='pressure (kPa)' dot={<CustomizedDot/>} />
     {refAreaLeft && refAreaRight?
        (<ReferenceArea
          yAxisId="mv"
          x1={refAreaLeft }
          x2={refAreaRight}
          strokeOpacity={0.3}

        />)
      :null}

   </ComposedChart>
</ResponsiveContainer>
</>
)
}
