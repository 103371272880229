import { Storage } from "aws-amplify";

export async function s3Upload(file, folderId, folderType) {
  const filename = `${folderId}/${folderType}/${Date.now()}-${file.name}`;

  const stored = await Storage.vault.put(filename, file, {
    contentType: file.type,
    level: 'public'
  });

  return stored.key;
}

export async function s3Download(filename) {
  //const data = await Storage.get(filename);
  const data = await Storage.get(filename, {cacheControl: 'no-cache' });
  return data;
}
