import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import {
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
ReferenceArea,
ComposedChart,
LineChart,
 Line,
 Area,
 Bar,

} from 'recharts'
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';

const CustomizedDot = (props) => {
  const { cx, cy, stroke, payload, value } = props;
return (<svg/>);
  if (value > 2500) {
    return (
      <svg x={cx - 10} y={cy - 10} width={20} height={20} fill="red" viewBox="0 0 1024 1024">
        <path d="M512 1009.984c-274.912 0-497.76-222.848-497.76-497.76s222.848-497.76 497.76-497.76c274.912 0 497.76 222.848 497.76 497.76s-222.848 497.76-497.76 497.76zM340.768 295.936c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM686.176 296.704c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM772.928 555.392c-18.752-8.864-40.928-0.576-49.632 18.528-40.224 88.576-120.256 143.552-208.832 143.552-85.952 0-164.864-52.64-205.952-137.376-9.184-18.912-31.648-26.592-50.08-17.28-18.464 9.408-21.216 21.472-15.936 32.64 52.8 111.424 155.232 186.784 269.76 186.784 117.984 0 217.12-70.944 269.76-186.784 8.672-19.136 9.568-31.2-9.12-40.096z" />
      </svg>
    );
  }

  return (
    <svg x={cx - 10} y={cy - 10} width={20} height={20} fill="green" viewBox="0 0 1024 1024">
      <path d="M517.12 53.248q95.232 0 179.2 36.352t145.92 98.304 98.304 145.92 36.352 179.2-36.352 179.2-98.304 145.92-145.92 98.304-179.2 36.352-179.2-36.352-145.92-98.304-98.304-145.92-36.352-179.2 36.352-179.2 98.304-145.92 145.92-98.304 179.2-36.352zM663.552 261.12q-15.36 0-28.16 6.656t-23.04 18.432-15.872 27.648-5.632 33.28q0 35.84 21.504 61.44t51.2 25.6 51.2-25.6 21.504-61.44q0-17.408-5.632-33.28t-15.872-27.648-23.04-18.432-28.16-6.656zM373.76 261.12q-29.696 0-50.688 25.088t-20.992 60.928 20.992 61.44 50.688 25.6 50.176-25.6 20.48-61.44-20.48-60.928-50.176-25.088zM520.192 602.112q-51.2 0-97.28 9.728t-82.944 27.648-62.464 41.472-35.84 51.2q-1.024 1.024-1.024 2.048-1.024 3.072-1.024 8.704t2.56 11.776 7.168 11.264 12.8 6.144q25.6-27.648 62.464-50.176 31.744-19.456 79.36-35.328t114.176-15.872q67.584 0 116.736 15.872t81.92 35.328q37.888 22.528 63.488 50.176 17.408-5.12 19.968-18.944t0.512-18.944-3.072-7.168-1.024-3.072q-26.624-55.296-100.352-88.576t-176.128-33.28z" />
    </svg>
  );
};

const CustomTooltip = ({ active, payload, label, name }) => {
    console.log("active",active," payload",payload," label",label," name",name);  //you check payload
    if (active && payload) {
      return (
        <div>
         <Card>
         <Box px={1} pt={1}>
          <p style={{color: payload[0].payload.colour}}><b>{payload[0].payload.id}</b></p>
          <p>Date: {new Date(payload[0].payload.time).toLocaleDateString('en-gb', { weekday:"long", year:"numeric", month:"short", day:"numeric"})}<br/>
          Displacement: {payload[0].payload.mm}mm
          </p>
          </Box>
          </Card>
        </div>
      );
    }

    return null;
  };


export default function SatsenseChart(props) {
  const [refAreaLeft, setRefAreaLeft] = useState("");
  const [refAreaRight, setRefAreaRight] = useState("");
  const [initialData, setInitialData] = useState(props.chartData);
  const [data, setData] = useState(props.chartData);
  const [left, setLeft] = useState("dataMin");
  const [right, setRight] = useState("dataMax");
  const [bottom, setBottom] = useState(0);
  const [top, setTop] = useState(0);
  //const [bottom2, setBottom2] = useState("dataMin");
  //const [top2, setTop2] = useState("dataMax");
  const [idxStart, setIdxStart] = useState(0);
  const [idxEnd, setIdxEnd] = useState(0);
  //const [colours, setColours] = useState([]);
  const colours = ["#9900CC","#0d98ba","#004225","#702963","#4b5320","#fe6f5e","#7c0a02","#734f96","#87a96b","#00ffff","#9966cc","#e32636","#ffbf00","#b0bf1a","#660000","#009999","#cc5500"];

  const getAxisYDomain = (
    from: number,
    to: number,
    ref: string,
    offset: number
    ) => {
      let bbb = 0;
      let ttt = 0;
      console.log("initialData");
      console.log(initialData);
      initialData.map((s) => {

        let refData;
        if (from == -999 || to == -999) {
          refData = s.displacements.slice();
        } else {
          refData = s.displacements.slice(from, to);
        }
        let [bb, tt] = [0,0];
        refData.every((d) => {
          let val = Number(d[ref]);
          if (!Object.is(val, NaN)) {
            [bb, tt] = [val, val];
            return false;
          }
          return true;
        })

        //console.log(refData);
        //console.log(bb,tt);
        refData.forEach((d) => {
          let val = Number(d[ref]);
          if (!Object.is(val, NaN)) {
            //console.log(val + "  " + tt + "  " + bb + "  " + (val > tt) + "  " + (val < bb));
            if (val > tt) tt = val;
            if (val < bb) bb = val;
            //console.log(val);
          }
        });
        //console.log(tt);
        //console.log(bb);
        //console.log([(bb | 0) - offset, (tt | 0) + offset]);
        if (tt > ttt) ttt = tt;
        if (bb < bbb) bbb = bb;

      })
console.log(bbb,ttt);
    return [(bbb | 0) - offset, (ttt | 0) + offset];
  };


  useEffect(() => {
    const [bb, tt] = getAxisYDomain(-999, -999, "mm", 1);
    //console.log(bb, tt);
    setBottom(bb);
    setTop(tt);


  }, [])

  function zoom() {
      //let { refAreaLeft, refAreaRight } = this.state;
      //const { data } = this.state;
      console.log(refAreaLeft, refAreaRight, idxStart,idxEnd);

      if (refAreaLeft === refAreaRight || refAreaRight === "") {
        setRefAreaLeft("");
        setRefAreaRight("");
        return;
      }

      // xAxis domain
      /*
      if (refAreaLeft > refAreaRight)
        [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];
        */
      let start = idxStart;
      let end = idxEnd;
      if (idxStart > idxEnd) {
        start = idxEnd;
        end = idxStart;
      }

      console.log(start, end)
      // yAxis domain
      const [bottom, top] = getAxisYDomain(start, end, "mm", 1);
      /*const [bottom2, top2] = getAxisYDomain(
        start,
        end,
        "mm",
        1
      );
      */

      //console.log(bottom, top);
      //console.log(bottom2, top2);
      //console.log(left, right);
      /*
      const [bottom, top] = getAxisYDomain(refAreaLeft, refAreaRight, "mv", 1);
      const [bottom2, top2] = getAxisYDomain(
        refAreaLeft,
        refAreaRight,
        "pressure",
        1
      );
      */

      setLeft(refAreaLeft);
      setRight(refAreaRight);
      setRefAreaLeft("");
      setRefAreaRight("");
      setData(data.slice());
      setBottom(bottom);
      setTop(top);
      //setBottom2(bottom2);
      //setTop2(top2);

      console.log(left, right);
    }


    function zoomOut() {
      setData(data.slice());
      setRefAreaLeft("");
      setRefAreaRight("");
      setRight("dataMax");
      setLeft("dataMin");
      const [bottom, top] = getAxisYDomain(-999, -999, "mm", 1);
      setBottom(bottom);
      setTop(top);
      //setBottom2("dataMin");
      //setTop2("dataMax");
    }


//       domain = {[props.chartData[0].time, props.chartData[props.chartData.length-1].time]}

  return(
    <>
    {/*}
    <button
       type="button"
       className="btn update"
       onClick={zoomOut}
     >
       Zoom Out
     </button>
     */}

  <ResponsiveContainer width = '95%' height = {500} >
  <ScatterChart
              //data={data}
              //onMouseDown= {(e) => {setRefAreaLeft(e.activeLabel); setIdxStart(e.activeTooltipIndex)}}
              //onMouseMove= {(e) => {if (refAreaLeft!="") setRefAreaRight(e.activeLabel)}}
              //onMouseUp= {(e) => {setIdxEnd(e.activeTooltipIndex); zoom()}}
              margin={{
                top: 20,
                right: 20,
                bottom: 20,
                left: 20
              }}
            >
              <CartesianGrid stroke="#f5f5f5" />
              <XAxis
              allowDataOverflow
                dataKey = 'time'
                name = 'Date'
                domain={[left, right]}
                tickFormatter = {(unixTime) => moment(unixTime).format('DMMMYYYY')}
                scale="time"
                type = 'number'

              />
              <YAxis allowDataOverflow domain={[bottom, top]} name = 'Displacement (mm)' dataKey = 'mm' label={{ value: `mm`, angle: -90, position: 'insideLeft'}}/>

            {/*}  <Tooltip formatter={t => new Date(t).toLocaleDateString('en-gb', { weekday:"long", year:"numeric", month:"short", day:"numeric"}) } />
*/}

<Tooltip content={<CustomTooltip />} />
<ReferenceLine y={0} label="0" stroke="red" strokeDasharray="5 3"/>
              {data.map((s, index) => (
                <Scatter
                key={s.id}

                data = {s.displacements}
                line = {{ stroke: s.colour+'1b' }}

                fill={s.colour}
                animationDuration={300}

                lineJointType = 'monotoneX'
                lineType = 'joint'
                name = {s.id}
                label = {s.id}
                />
              ))}
              {refAreaLeft && refAreaRight ? (
                <ReferenceArea
                  yAxisId="1"
                  x1={refAreaLeft }
                  x2={refAreaRight}
                  strokeOpacity={0.3}
                />
              ) : null}
            </ScatterChart>

{/*

  <ComposedChart
     data={data}
     onMouseDown= {(e) => {setRefAreaLeft(e.activeLabel); setIdxStart(e.activeTooltipIndex)}}
     onMouseMove= {(e) => {if (refAreaLeft!="") setRefAreaRight(e.activeLabel)}}
     onMouseUp= {(e) => {setIdxEnd(e.activeTooltipIndex); zoom()}}
     margin={{
       top: 20,
       right: 20,
       bottom: 20,
       left: 20
     }}
   >
     <CartesianGrid stroke="#f5f5f5" />
     <XAxis
     allowDataOverflow
       dataKey = 'time'
       name = 'Time'
       domain={[left, right]}
       tickFormatter = {(unixTime) => moment(unixTime).format('dd MMM yy')}
       type = 'number'
      label={{ value: 'Date', position: 'outsideBottomRight', offset: 0 }}
     />
     <YAxis allowDataOverflow yAxisId="mm" dx={0} label={{ value: `mm`, angle: -90, position: 'insideLeft'}} />
     <YAxis allowDataOverflow yAxisId="mm" dx={0} orientation="right" label={{ value: `℃`, angle: -90, position: 'outsideLeft' }}/>
     <YAxis allowDataOverflow yAxisId="mm" dx={0} domain={[80,120]} orientation="right"  label={{ value: `kPa`, angle: -90, position: 'outsideLeft'}}/>

      <Tooltip labelFormatter={t => new Date(t).toLocaleString()} />
     <Legend />
     <Line yAxisId="mm" type="monotone" dataKey="mm" stroke="#413ea0"  name='millimetres (mm)' dot={<CustomizedDot/>} />
     {refAreaLeft && refAreaRight?
        (<ReferenceArea
          yAxisId="mm"
          x1={refAreaLeft }
          x2={refAreaRight}
          strokeOpacity={0.3}

        />)
      :null}

   </ComposedChart>

   */}
</ResponsiveContainer>
</>
)
}
