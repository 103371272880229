import React from "react";
import "./NotFound.css";

export default function NotFound() {
  return (
    <div className="NotFound" text-center>
      <h3><center>Sorry, page not found!</center></h3>
    </div>
  );
}
