import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import LoaderButtonX from "../components/LoaderButtonX";
import LoaderButtonTextOnly from "../components/LoaderButtonTextOnly";
import { useHistory} from "react-router-dom";
//import { useAppContext } from "../libs/contextLib";
import { useFormFields } from "../libs/hooksLib";
import { onError } from "../libs/errorLib";
import "./Login.css";

import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';


import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

const FooterTypography = withStyles({
  root: {
    color: "#ccc"
  }
})(Typography);

const useStyles = makeStyles((theme) => ({
  link: {
      color: "#F18C7E",
      "&:hover": {
          color: "#F18C7E",
          textDecoration: "underline #F18C7E"
      }
    }

}));







export default function Test01() {
  //const { userHasAuthenticated, setUserName } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isForgotPWing, setIsForgotPWing] = useState(false);
  const [sessionValues, setSessionValues] = React.useState([]);
  const history = useHistory();

  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
    message: <>Not yet a registered for SPiVolt? <a style={{color: "#0cb9c2"}} href="/signup">Register here</a>.</>,
    colour: "#10385A",
    confirmed: true,
    resent: false,
  });



  useEffect(() => {
    const parts = window.location.href.split("?");
    if (parts.length > 1 && sessionValues.length==0) {
      if (parts[1].indexOf('userName=')>-1 && parts[1].indexOf('confirmation_code=')>-1) {
        const vals = parts[1].split("&");
        const username = vals[0].split("=")[1];
        const confCode = vals[1].split("=")[1];
        //submitConfirmationCode(username, confCode);
      }
    }
  });

/*
  async function submitConfirmationCode(email, code) {
    setSessionValues([email, code]);
    setIsLoading(true);

    try {
      await Auth.confirmSignUp(email, code);
      fields.email = email;
      fields.colour  = 'green';
      fields.message = "Thank you, your email is confirmed. You may now login.";
    } catch (e) {
      if (e.code === 'ExpiredCodeException') {
        fields.email = email;
        fields.confirmed = false;
        fields.colour  = 'red';
        fields.message = "Your verification link has expired. Please click the button below to have a fresh verification link sent to your email.";
      } else if (e.code === 'NotAuthorizedException' && e.message == 'User cannot be confirmed. Current status is CONFIRMED') {
        fields.email = email;
        fields.colour  = 'red';
        fields.message = "Not authorized. Please contact SPiVolt admin to resolve this.";
      } else {
        fields.email = email;
        fields.colour  = 'red';
        fields.message = e.message;
      }
    }
    setIsLoading(false);
  }
*/





  return (
    <div>

success
      </div>
    );
  }
