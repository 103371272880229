import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";

import "./ListAdmin.css";

import { useAppContext } from "../libs/contextLib";
import { useHistory, useParams } from "react-router-dom";
import image from'../images/thinking.jpg';
import iCampra from'../images/campra/Campra Accredited - Green.svg';

import TextField from '@material-ui/core/TextField';
import LoaderButtonX from "../components/LoaderButtonX";
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';



import { Link } from 'react-router-dom';


import { makeStyles, withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';

const FooterTypography = withStyles({
  root: {
    color: "#fff"
  }
})(Typography);


const columns = createCols();

function createCols() {
  let cols = [
    { id: 'name',
      label: 'Name',
      minWidth: 180,
      format: (value) => value.toLocaleString('en-GB'),
    },
  ];
  return cols;
}


export default function ListAdmin() {
  const [rows, setRows] = useState([]);
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [data, setData] = useState([]);
  const [type, setType] = useState("orgs");
  const [currentObj, setCurrentObj] = useState(null);

useEffect(() => {
  async function onLoad() {
    if (!isAuthenticated) {history.push("/"); return;}
    initForSpivoltId('/orgs', 'orgs');
  }
  onLoad();
}, []);

function getData(path) {
    return API.get("spivolt", path);
}

async function initForSpivoltId(path, tp) {
  try {
    const result = await getData(path);
    console.log(result);
    const items = result.items;
    setData(items);
    var newRows = [];
    for (var i = 0; i < items.length; ++i) {
      let sd = items[i];
      const {content} = sd;
      newRows.push(getRow(content, tp));
    }

    setRows(newRows);
  } catch (e) {
    alert(e);
  }
}

function getRow(content, tp ) {

  var newRow = {};
  newRow['name'] = content.name;
  switch (tp) {
    case 'orgs':
    //newRow['name'] = content.name;

    break;
    case 'sites':
    //console.log(content.name);
    //newRow['name'] = content.name;
    break;
    case 'overlays':
    //console.log(content.name);
    //newRow['name'] = content.name;
    break;

  }
  return newRow;
}



function handleRowClick(event, idx)
{
  if (type=='orgs') {
    setCurrentObj(data[idx]);
    setType('sites');
    initForSpivoltId('/org/sites/' + data[idx].pk, 'sites');
  } else if (type=='sites') {
    setCurrentObj(data[idx]);
    console.log('XXXX: ');
    console.log(data[idx]);
    setType('overlays');
    initForSpivoltId('/sites/overlays/' + (data[idx].sk).substring(4), 'overlays');
  } else if (type=='overlays') {
    setCurrentObj(data[idx]);
    history.push("/overlays/edit/" + data[idx].pk + "/" + (data[idx].sk).substring(4) + "/" + (data[idx].sk).substring(0,3));
  }
}
function handleAddClick(event) {
  if (type=='orgs') {
    history.push("/orgs/create/new");
  } else if (type=='sites') {
      history.push("/sites/create/" + currentObj.pk + "/" + currentObj.content.name);
  } else if (type=='overlays') {
      history.push("/overlays/create/" + currentObj.content.id + "/" + currentObj.content.name);
  }
}

  function renderLander() {
    return (
      <div className={"lander"} >
        <h2 style={{color:"#0cb9c2"}}>Admin: <b>{type=='sites'?currentObj.content.name + ' - sites':(type=='overlays'?currentObj.content.name + ' - overlays':type.toUpperCase())}</b> &nbsp;&nbsp;&nbsp;     <Fab size="small" color="primary" aria-label="add" onClick={handleAddClick}>
        <AddIcon />
      </Fab></h2>
        <Box height="16px"/>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer style={{ maxHeight: `calc(100vh - 210px)` }}>
            <Table stickyHeader aria-label="sticky table" size={'small' }>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, background:"#f0c239", fontWeight:"bold" }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  .map((row, index) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.code} onClick={event => handleRowClick(event, index)} >
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format(value)}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            </TableContainer>
        </Paper>
      </div>
    );
  }

  return (
    <div className="ListAdmin">
      {renderLander()}
    </div>
  );
}
