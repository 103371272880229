const config = {
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://3dagner8zd.execute-api.us-east-1.amazonaws.com/prod",
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_ERzlfMu2Z",
    APP_CLIENT_ID: "6kicivn6cmn9mvts5p4pa38erm",
    IDENTITY_POOL_ID: "us-east-1:eeb64a10-d6d7-46dc-8d1e-ad35079b972b",
  },
  s3: {
    REGION: "us-east-1",
    BUCKET: "spivolt-images"
  },
};

export default config;
