import React, { useRef, useState, useEffect } from "react";
import { API, Auth } from "aws-amplify";
import "./Map.css";
import LoaderButton from "../components/LoaderButton";

import { s3Download } from "../libs/awsLib";


import { useAppContext } from "../libs/contextLib";
import { useHistory, useParams } from "react-router-dom";
//import bgimage from'../images/large_camper.jpeg';
import spinner from'../images/logo.gif';

import TextField from '@material-ui/core/TextField';
import LoaderButtonX from "../components/LoaderButtonX";
import SiteIdInput from "../components/SiteIdInput";

import { useFormFields } from "../libs/hooksLib";
import { makeStyles, withStyles } from '@material-ui/core/styles';

import Form from "react-bootstrap/Form";
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Link from '@material-ui/core/Link';
import Drawer from '@material-ui/core/Drawer';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
//import ListItemButton from '@material-ui/core/ListItemButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

import IconButton from '@material-ui/core/IconButton';

import Slider from '@material-ui/core/Slider';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import ButtonGroup from '@material-ui/core/ButtonGroup';

import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import JSZip, { forEach } from 'jszip';
import FileSaver from 'file-saver';


//import DateFnsUtils from '@date-io/date-fns';
//import { KeyboardDatePicker, MuiPickersUtilsProvider  } from '@material-ui/pickers';

//import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
//import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
//import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

//import DatePicker from 'react-date-picker';

//import flatpickr from "flatpickr";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

import { BsArrowRepeat } from "react-icons/bs";



import {   Scatter,
  ScatterChart,
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

  //import TimeSeriesChart from "../components/TimeSeriesChart";
  import MultiPotChart from "../components/MultiPotChart";
  import PotChart from "../components/PotChart";
  import SatsenseChart from "../components/SatsenseChart";
  import StaticSPChart from "../components/StaticSPChart";
  import ChainageChart from "../components/ChainageChart";

  import moment from 'moment'


  //import DrawerContent from '@material-ui/core/DrawerContent';

  //import LaunchRoundedIcon from '@material-ui/icons/LaunchRoundedIcon';
  //import LaunchRoundedIcon from '@material-ui/icons/LaunchRounded';
  import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
  import BlockRoundedIcon from '@material-ui/icons/BlockRounded';
  import ChainageChartRoundedIcon from '@material-ui/icons/TimelineRounded';
  import SelectRoundedIcon from '@material-ui/icons/TouchAppRounded';
  import TimeChartRoundedIcon from '@material-ui/icons/MultilineChartRounded';
  import DrawRoundedIcon from '@material-ui/icons/GestureRounded';
  import AllRoundedIcon from '@material-ui/icons/GrainRounded';

  import QrReader from 'react-qr-reader'
  import Fab from '@material-ui/core/Fab';


  import { GoogleMap, DrawingManager, useJsApiLoader, useLoadScript, Marker, Polyline, StandaloneSearchBox, InfoWindow, InfoBox, GroundOverlay } from '@react-google-maps/api';

  import pointInPolygon from 'point-in-polygon';

  import pinIcon from'../images/pin3.svg';
  import potIcon from'../images/pot.svg';
  import potselIcon from'../images/pot_sel.svg';
  import refIcon from'../images/ref.svg';
  import disabledpotIcon from'../images/disabledpot.svg';
  import satsenseLogo from'../images/satsense_logo.svg';

  import defaultSiteImage from'../images/Default-Image.jpg';


  import CalendarMonthIcon from '@material-ui/icons/Today';
  import SettingsIcon from '@material-ui/icons/Settings';
  import Divider from '@material-ui/core/Divider';

  import iTopOvernightOff from'../images/preview/Pay Per Night - Grey.svg';
  import iTopBaysOff from'../images/preview/Parking - Grey.svg';
  import iTopFacilitiesOff from'../images/preview/Facilities Only - Grey.svg';

  import iTopOvernight from'../images/preview/Pay Per Night - Blue.svg';
  import iTopBays from'../images/preview/Parking - Blue.svg';
  import iTopFacilities from'../images/preview/Facilities Only - Blue.svg';
  import iCampraA from'../images/campra/Campra Accredited - Green.svg';
  import iCampraX from'../images/campra/Campra Accredited - Grey.svg';
  import Grid from '@material-ui/core/Grid';
  //import Tooltip from '@material-ui/core/Tooltip';
  import green from '@material-ui/core/colors/green';
  import red from '@material-ui/core/colors/red';

  import iLogo from "../images/logo.png";

  import { CirclePicker } from 'react-color'



  const BlueTooltip = withStyles((theme) => ({
    arrow: {
      "&:before": {
        border: "1px solid #10385A"
      },
      color: '#10385Acc'
    },
    tooltip: {
      backgroundColor: '#10385Add',
      color: '#ffffff',
      border: "1px solid #10385A",
      fontSize: 12,
      fontFamily: "Montserrat",
      fontWeight: 400,

    },
  }))(Tooltip);


  const useStyles = makeStyles(theme => ({
    root: {
      justifyContent : "left",
    },
    drawer : {
      paddingTop : "20px",
      width: "250px",
    },
    item: {
      display: 'flex',
      paddingTop: 0,
      paddingBottom: 0,
    },
    button: {
      color: '#ddd',
      padding: '10px 8px',
      justifyContent: 'flex-start',
      textTransform: 'none',
      letterSpacing: 0,
      width: '100%',
    },
    btnRoot : {
      paddingLeft : "25px",
      justifyContent : "left !important"
    },
    subMenu : {
      paddingLeft : "50px !important",
    }
  }));
  const libraries = ['drawing'];

  function getChannelTitle(id) {
    var channelTitle = "pot";
    if (id<10) {
      channelTitle+="00"+id;
    } else if (id<100) {
      channelTitle+="0"+id;
    } else {
      channelTitle+=id;
    }
    return channelTitle;
  }


  export default function Map() {

    const checkboxRef = useRef();

    const { id } = useParams();
    const history = useHistory();
    const { isAuthenticated } = useAppContext();
    const [isLoading, setIsLoading] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [isPageLoading, setIsPageLoading] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    //const [currentMarkerKey, setCurrentMarkerKey] = useState(-1);
    //const [selectedMarkerKeys, setSelectedMarkerKeys] = useState([]);
    const [selectedPoints, setSelectedPoints] = useState([]);
    const [infoOpen, setInfoOpen] = useState(false);
    const [sites, setSites] = useState([]);
    const [center, setCenter] = useState({ lat: 52.564303191489465, lng: -1.4860456320456625 });
    const [map, setMap] = useState(null);
    const [currentSite, setCurrentSite] = useState(null);
    //const [currentPotId, setCurrentPotId] = useState("");
    //const [currentPotName, setCurrentPotName] = useState("");
    const [chartTitle, setChartTitle] = useState("");
    const [showPots, setShowPots] = useState(false);
    const [currentOverlayId, setCurrentOverlayId] = useState(-1);

    const [potDataType, setPotDataType] = useState("raw");
    const [refDate, setRefDate] = useState(null);
    const [startAllDataRefDate, setStartAllDataRefDate] = useState(null);
    const [endAllDataRefDate, setEndAllDataRefDate] = useState(null);
    const [datePickerOpen, setDatePickerOpen] = useState(false);
    //const [newPots, setNewPots] = useState([]);
    //const [potStr, setPotStr] = useState("");
    const [overlays, setOverlays] = useState([]);
    const [satsensePoints, setSatsensePoints] = useState(null);
    const [satsenseDirection, setSatsenseDirection] = useState("A");
    const [velocity, setVelocity] = useState(null);
    const [spOverlayIdx, setSpOverlayIdx] = useState(-1);
    const [glOverlayIdx, setGlOverlayIdx] = useState(-1);
    const [currentVal, setCurrentVal] = useState(null);

    //const [checked, setChecked] = React.useState([]);
    const [opacity, setOpacity] = React.useState([]);

    const [imageUrls, setImageUrls] = React.useState([]);
    const [images, setImages] = React.useState([]);
    const [glImages, setGlImages] = React.useState([]);

    const [potSelectState, setPotSelectState] = React.useState("charttimeselect");
    const [updateState, setUpdateState] = React.useState(false);


    //const [imageUrl, setImageUrl] = useState("");

    const colours = ["#9900CC","#0d98ba","#004225","#702963","#4b5320","#fe6f5e","#7c0a02","#734f96","#87a96b","#00ffff","#9966cc","#e32636","#ffbf00","#b0bf1a","#660000","#009999","#cc5500"];
    const svgDot = "M 10 5 C 10 2.238576 7.761424 0 5 0 C 2.238576 0 0 2.238576 0 5 C 0 7.761424 2.238576 10 5 10 C 7.761424 10 10 7.761424 10 5 Z";
    const rainbowColours = ["#ff0000","#ff5500","#ffaa00","#ffff00","#aaff00","#2aff00","#00ff2b","#00ff80","#00ffd4","#00d4ff","#00aaff","#0055ff"];//,"#0000ff","#5500ff","#aa00ff","#ff00ff","#ff00aa"


    const [overlayValues, setOverlayValues] = React.useState([]);

    const [potsActive, setPotsActive] = React.useState(true);

    const [color, setColor] = React.useState("#ffffff");
    const [currentImage, setCurrentImage] = React.useState(null);

    const [currentLocation, setCurrentLocation] = React.useState({});

    const [forceUpdate, setForceUpdate] = React.useState(false);
    const [settingsOpen, setSettingsOpen] = React.useState(-1);
    const [lineWidth, setLineWidth] = React.useState([[4,4],[4,4,4,4,4]]);
    const [lineColour, setLineColour] = React.useState(["#9c27b0","#03a9f4"]);


    const [fromDateOpen, setFromDateOpen] = useState(true);

    const [currentDataDay, setCurrentDataDay] = useState(0);

    const [allSensorData, setAllSensorData] = useState({lek:"", data:[]});
    const [rawSensorData, setRawSensorData] = useState(null);
    const [filteredSensorData, setFilteredSensorData] = useState(null);
    const [avgSensorData, setAvgSensorData] = useState(null);
    const [fltSensorData, setFltSensorData] = useState(null);
    const [nrmSensorData, setNrmSensorData] = useState(null);
    const [sensorData, setSensorData] = useState([]);
    const [initialSensorData, setInitialSensorData] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [potChartOpen, setPotChartOpen] = useState(false);
    const [multiPotChartOpen, setMultiPotChartOpen] = useState(false);
    const [satsenseChartOpen, setSatsenseChartOpen] = useState(false);
    const [staticSpChartOpen, setStaticSpChartOpen] = useState(false);
    const [chainageChartOpen, setChainageChartOpen] = useState(false);
    const [currentChartDayIndex, setCurrentChartDayIndex] = useState(0);
    const [figureOpen, setFigureOpen] = useState(-1);
    const [currentBounds, setCurrentBounds] = useState({ // initial is UK bounds
      south: 49.79129622491783,
      west: -10.795963172325314,
      north: 58.84452996184098,
      east: 2.1239587026746864
    });
    const [alertVals, handleAlertChange] = useFormFields({
      title: "",
      message: "",
    });

    const RESTRICT_BOUNDS = {south: 10.994892342824796, west: -39.88202649919864, north: 74.86496038005808, east: 27.61797350080136};
    const mapOptions = {
      restriction: {
        latLngBounds: RESTRICT_BOUNDS,
      },
      mapTypeControl: true,
      streetViewControl: false,
      mapTypeId: 'satellite'
    };
    const { isLoaded, loadError } = useLoadScript({
      googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
      libraries: libraries,
    })


    const minDistance = 5;
    const [tempCutoffs, setTempCutoffs] = React.useState([0, 100]);
    const [cutoffs, setCutoffs] = React.useState([0, 100]);

      const handleCutoffsChangeCommitted = (evt) => {
        //setCutoffs(tempCutoffs);
      }

      const handleCutoffsChange = (event, newValue, activeThumb) => {
        if (!Array.isArray(newValue)) {
          return;
        }
        let newCutoffs = [];
        if (newValue[1] - newValue[0] < minDistance) {
          if (activeThumb === 0) {
            const clamped = Math.min(newValue[0], 100 - minDistance);
            newCutoffs = [clamped, clamped + minDistance];
          } else {
            const clamped = Math.max(newValue[1], minDistance);
            newCutoffs = [clamped - minDistance, clamped];
          }
        } else {
          newCutoffs = newValue;
        }
        setTempCutoffs(newCutoffs);
        setCutoffs(newCutoffs);
      };

    useEffect(() => {
      if (map) {
        map.fitBounds(currentBounds);
      }

    }, [map]);
    useEffect(() => {
      if (map) {
        map.fitBounds(currentBounds);
      }

    }, [currentBounds]);



    const handleClosePotChart = () => {
      setPotChartOpen(false);
      setMultiPotChartOpen(false);
      setStaticSpChartOpen(false);
      setSatsenseChartOpen(false);
      setChainageChartOpen(false);
    };
    const handleCloseFigure = () => {
      setFigureOpen(-1);
    };


    const onMapLoad = React.useCallback(function callback(map) {
      map.fitBounds(currentBounds);
      setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
      setMap(null)
    }, [])


    function onZoomChangedHandler(e) {
      if (map) {
        //console.log(map.zoom + "  " + showPots);
        setShowPots(map.zoom>16);
      }
    }


    function onCenterChanged() {
      if (map) {
        const bounds=map.getBounds();
        if (bounds) {
          //console.log(bounds.toJSON());
        }
      } else {
        //console.log("!");
      }
    }






    const onInfoLoad = infoBox => {
      console.log('infoBox: ', infoBox)
    }
    const onMarkerLoad = marker => {
      //console.log('marker: ', marker)
    }

    const classes  = useStyles();


    const useViewport = () => {
      const [width, setWidth] = React.useState(window.innerWidth);
      const [height, setHeight] = React.useState(window.innerHeight);
      React.useEffect(() => {
        const handleWindowResize = () => {setWidth(window.innerWidth);setHeight(window.innerHeight);};
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
      }, []);
      return { width, height };
    }
    const { width, height } = useViewport();
    const breakpoint = 600;


    const containerStyle = {
      width: '100%',
      height: height-84
    };


    const linkHoverStyle={
      link: {
        color: "#00ff00",
        "&:hover": {
          color: "#000000",
          textDecoration: "underline #000000"
        }
      }
    }
function calcNorms() {
  const days = [];
  const refIdx = getIndexFromTimeStamp(refDate);
  let minAvg1 = 100000000;
  let maxAvg1 = -100000000;
  let minAvg2 = 100000000;
  let maxAvg2 = -100000000;
  let allDaysMinAvg2 = 100000000;
  let allDaysMaxAvg2 = -100000000;
  avgSensorData.days.forEach((day) => {
    const norms = [];
    for (var i = 0; i < day.pots.length; i++) {
      //console.log(day.pots[i],day.min,(day.pots[i]-day.min)*2,day.max-day.min,((day.pots[i]-day.min)*2) / (day.max-day.min),((((day.pots[i]-day.min)*2) / (day.max-day.min)) - 1)*100,Math.round(((((day.pots[i]-day.min)*2) / (day.max-day.min)) - 1)*100),Math.round(((((day.pots[i]-day.min)*2) / (day.max-day.min)) - 1)*100)/100);
      const norm1 = Math.round(((((day.pots[i]-day.min)*2) / (day.max-day.min)) - 1)*100)/100; //range = [-1,1]
      const norm2 = Math.round((avgSensorData.days[refIdx].pots[i]-day.pots[i])*100)/100;
      if (currentSite && currentSite.content.pots[i+1].disabled) {
        // do nowt
      } else {
        if (norm1>maxAvg1) {
          maxAvg1 = norm1;
        } else if (norm1<minAvg1) {
          minAvg1 = norm1
        }
        if (norm2>maxAvg2) {
          maxAvg2 = norm2;
        } else if (norm2<minAvg2) {
          minAvg2 = norm2
        }
      }
      norms.push({"normalised1": norm1, "normalised2": norm2});
    };
    days.push({"timestamp": day.timestamp, "temperature": day.temperature, "reftemperature": day.reftemperature, "pressure": day.pressure, "pots": norms, "min": {"normalised1": minAvg1, "normalised2": minAvg2}, "max": {"normalised1": maxAvg1, "normalised2": maxAvg2}});
    if (maxAvg2>allDaysMaxAvg2) {
      allDaysMaxAvg2 = maxAvg2;
    } else if (minAvg2<allDaysMinAvg2) {
      allDaysMinAvg2 = minAvg2;
    }
  });
  return {min2: allDaysMinAvg2, max2: allDaysMaxAvg2, days: days};
}

function getNorms() {
  console.log('ggggggg');
  if (avgSensorData) {
    const days = calcNorms();
    setNrmSensorData(days);
    console.log('lllllooll');
  }
}

    useEffect(() => {
        getNorms();
    }, [refDate]);

    function getIndexFromTimeStamp(ts) {
      let d1 = new Date(ts);
      for (var i = 0; i < avgSensorData.days.length; i++) {
        let d2 = new Date(avgSensorData.days[i].timestamp);
        if (d1.getDate()==d2.getDate() && d1.getMonth()==d2.getMonth() && d1.getFullYear()==d2.getFullYear()) {
          return i;
        }
      }
      return -1;
    }
    function calcAverages() {
      //const now = (new Date()).getTime();
      let site = getSiteDetails()
      let keys = Object.keys(sensorData[0]);
        console.log(keys);
      const potCount = keys.length-site.extrasCount;
      console.log("potCount");
      console.log(potCount);
      let pots = Array(potCount).fill(0);
      let temp = 0;
      let reftemp = 0;
      let pressure = 0;
      let recCount = 0;
      let dayCount = 1;
      const days = [];
      console.log("sensorData");
      console.log(sensorData.length);
      //console.log(sensorData);

      let allDaysMinAvg = 100000000;
      let allDaysMaxAvg = -100000000;

      for (var idx = sensorData.length-1; idx >=0; idx--) {
        const data = sensorData[idx];
        const ts = Number(data["timestamp"]) * 1000;
        if (endAllDataRefDate - (86400000 * (dayCount)) > ts) {
          //console.log("dayCount");
          //console.log(dayCount);
          //console.log("now, ts");
          //console.log(now, ts);
          const averages = [];
          let minAvg = 100000000;
          let maxAvg = -100000000;
          //console.log("potCount");
          //console.log(recCount);
          for (var i = 0; i < potCount; i++) {
            const val = Math.round(pots[i] / recCount / 10000) / 100;
            if (currentSite && currentSite.content.pots[i+1].disabled) {
              // do nowt
           } else {
              if (val>maxAvg) {
                maxAvg = val;
              }
              if (val<minAvg) {
                minAvg = val;
              }
            }
            averages.push(val);
          }
          //console.log(recCount);
          //console.log(temp, reftemp, pressure);
          const avgTemp = Math.round(temp / recCount) / 100;
          const avgRefTemp = Math.round(reftemp / recCount) / 100;
          const avgPressure = Math.round(pressure / recCount / 1000) / 100;
          //console.log(avgTemp, avgRefTemp, avgPressure);
          days.push({"timestamp": endAllDataRefDate - (86400000 * (dayCount-1)), "pots": averages, "temperature": avgTemp, "reftemperature": avgRefTemp, "pressure": avgPressure, "min": minAvg, "max": maxAvg});
          if (maxAvg>allDaysMaxAvg) {
            allDaysMaxAvg = maxAvg;
          }
          if (minAvg<allDaysMinAvg) {
            allDaysMinAvg = minAvg;
          }
          pots = Array(potCount).fill(0);
          temp = 0;
          reftemp = 0;
          pressure = 0;
          recCount = 0;
          dayCount += 1;
        } else {
          keys = Object.keys(data);
          //console.log(keys);
          for (var i = 0; i < potCount; i++) {
            pots[i] += Number(data[keys[i]]);
          }
          temp += Number(data.temperature);
          reftemp += Number(data.reftemperature);
          pressure += Number(data.pressure);
          recCount += 1;
        }
      };
      //console.log("avgsensordata");
      //console.log(days.length);
      setAvgSensorData({min: allDaysMinAvg, max: allDaysMaxAvg, days: days});
      ///console.log("days:");
      //console.log(days);
      //if (refDate!=days[days.length-1].timestamp) {
      //  setRefDate(days[days.length-1].timestamp);
      //} else {
      //  getNorms();
      //}
    }
    useEffect(() => {
      if (sensorData && sensorData.length>0) {
        calcAverages();
      }
    }, [sensorData]);

    useEffect(() => {
      if (avgSensorData && avgSensorData.days && avgSensorData.days.length > 0) {

        console.log("_________________");
        console.log(avgSensorData);
        console.log("_________________");


        if (refDate!=avgSensorData.days[avgSensorData.days.length-1].timestamp) {
          setRefDate(avgSensorData.days[avgSensorData.days.length-1].timestamp);
        } else {
          getNorms();
        }
        //setRefDate(avgSensorData.days[avgSensorData.days.length-1].timestamp);
      }
    }, [avgSensorData]);

    useEffect(() => {
      if (startAllDataRefDate && endAllDataRefDate && initialSensorData && initialSensorData.length>0) {
        console.log(startAllDataRefDate,endAllDataRefDate);
        setSensorData(initialSensorData.filter(row => {
          let val = Number(row.timestamp) * 1000;
          return val >= startAllDataRefDate && val <= endAllDataRefDate;
        }));
      }
    }, [startAllDataRefDate, endAllDataRefDate]);



    function getSiteDetails() {
      let channelCount;
      let extras;
      switch (id) {
        case 'S0002':
          channelCount = 64;
          extras = 4;
          break;
        case 'S0003':
          channelCount = 32;
          extras = 5;
          break;
        case 'S0004':
          channelCount = 64;
          extras = 5;
          break;
        case 'S0005':
          channelCount = 128;
          extras = 7;
          break;
          case 'S0006':
            channelCount = 96;
            extras = 6;
            break;
          case 'S0007':
            channelCount = 64;
            extras = 6;
            break;
          default:
          channelCount = 64;
          extras = 5;
      }
      return {channelCount: channelCount, extrasCount: extras};
    }



    useEffect(() => {
      if (rawSensorData) {
        //console.log(rawSensorData);
        //console.log('ID:');
        //console.log(id);
        const site = getSiteDetails();
        let rows = [];
        //const sdata = rawSensorData.data;
        for (var i = 0; i < rawSensorData.length; ++i) {
          let sd = rawSensorData[i];
          const {base64data, battery, created, uploaded} = sd;
          let buff = new Buffer(base64data, 'base64');
          let text = buff.toString('ascii');
          let sArr = text.split("\n");
          for (var i2 = 0; i2 < sArr.length; ++i2) {
            let dArr = sArr[i2].split(",");
            var newRow = {};
            //console.log(dArr);
            for (var i3 = 0; i3 < (site.channelCount+site.extrasCount); ++i3) {
              if (i3 < dArr.length) {
                let str = dArr[i3];
                if (i3 == 0) {
                  newRow['timestamp'] = str;
                } else if (i3 == 1) {
                  newRow["temperature"] = str;
                } else if (i3 == 2) {
                  newRow["pressure"] = str;
                } else if (i3 == 3) {
                  newRow["reftemperature"] = str;
                } else if (i3 == 4 && site.extrasCount>4) {
                  newRow["rainfall"] = str;

                } else if (i3 == 5 && site.extrasCount>5) {
                  newRow["airtemperature"] = str;
                } else if (i3 == 6 && site.extrasCount>6) {
                  newRow["distance"] = str;

                } else {
                  //newRow[getChannelTitle(i3 - site.extrasCount + 1)] = str;
                  newRow["" + (i3 - site.extrasCount + 1)] = str;
                }
              } else {
                //newRow[getChannelTitle(i3 - site.extrasCount + 1)] = "0";
                newRow[""+(i3 - site.extrasCount + 1)] = "0";
              }
            }
            //console.log(newRow);
            rows.push(newRow);
          }
        }
 

        // Remove duplicates
        //var ooiaaa = [...new Map(rows.map(v => [v.timestamp, v])).values()];

        //rows = removeDuplicates(rows);

        rows.sort(function(b, a) {
          var ta = Number(a.timestamp);
          var tb = Number(b.timestamp);
          return tb-ta;
        });
        let uniqueRows = [];
        let ts = 0;
        rows.forEach((row) => {
          if (ts !== row.timestamp) {
            uniqueRows.push(row);
            ts = row.timestamp;
          }
          
        })
        //console.log(uniqueRows);
        //console.log(rows);
         rows = uniqueRows;
        
        setInitialSensorData(rows);
        setStartAllDataRefDate(rows[0].timestamp * 1000);
        setEndAllDataRefDate(rows[rows.length-1].timestamp * 1000);
        //console.log(rows.length);
      }
    }, [rawSensorData]);

    function removeDuplicates(arr) {
      //arr = [...new Map(arr.map(v => [v.timestamp, v])).values()];

      return [...new Map(arr.map(v => [v.timestamp, v])).values()];
    }


    useEffect(() => {
      onLoad();
    }, []);

    function getSites() {
      return API.get("spivolt", `/org/sites`);
    }


    function getOverlays() {
      return API.get("spivolt", `/sites/overlays/${id}`);
    }

    function getSatsenseData(body) {
      return API.post("spivolt", "/satsense", {
        body: body
      });
    }

    function getSatsensePoints(coords) {
      return new  Promise(async(resolve, reject) => {
        const body = {"type":"Polygon","coordinates":[]};
        body.coordinates.push(coords);
        //const data = {type: "POINTS", body: body};
        const data = {type: "DISPLACEMENTS", body: body};
        //const data = {type: "POINT", pointId: "103A_03925_051013_12503_49420"};
        const satsenseResult = await getSatsenseData(data);
        if (satsenseResult.status) {
          resolve(satsenseResult.data);
        } else {
          reject(satsenseResult.error);
        }
      })
    }

    function getRawSensorData(limit, esk) {
      const dataid = id; //id=='S0003'?'S0002':id;
      let eskStr = "";
      if (esk) {
        eskStr = `&esk=${esk}`;
      }
      let qs = `/alldata?limit=${limit}${eskStr}&id=${dataid}`;
      return API.get("spivolt", qs);
    }

    function getFilteredSensorData() {
      const dataid = id; //id=='S0003'?'S0002':id;
      let qs = `/filtereddata?id=${dataid}`;
      return API.get("spivolt", qs);
    }

    useEffect(() => {
console.log("gggg?");
      //console.log("filtered sens data loaded!");

      //filteredSensorData.columns

      //filteredSensorData.index

      ///filteredSensorData.data







//console.log(filteredSensorData);
if (!filteredSensorData) {return};

      //let keys = Object.keys(sensorData[0]);
      const potCount = filteredSensorData.data[0].length; //keys.length-4;
      console.log("filt_potCount");
      console.log(potCount);
      let pots = Array(potCount).fill(0);
      let temp = 0;
      let reftemp = 0;
      let pressure = 0;
      let recCount = 0;
      let dayCount = 1;
      const days = [];
      console.log("filterData");
      console.log(filteredSensorData.data.length);
      let allDaysMinAvg = 100000000;
      let allDaysMaxAvg = -100000000;

      //for (var idx = sensorData.length-1; idx >=0; idx--) {
      for (var idx = filteredSensorData.index.length-1; idx >=0; idx--) {
        const data = filteredSensorData.data[idx];
        const newData=[];
        const ts = Number(filteredSensorData.index[idx]);
        let minAvg = 100000000;
        let maxAvg = -100000000;
        data.forEach(pot=>{
          if (pot>maxAvg) {
            maxAvg = pot;
          } else if (pot<minAvg) {
            minAvg = pot;
          }
          newData.push(Math.round(pot * 100) / 100);
        });
        days.push({"timestamp": ts, "pots": newData, "temperature": temp, "reftemperature": reftemp, "pressure": pressure, "min": minAvg, "max": maxAvg});
        if (maxAvg>allDaysMaxAvg) {
          allDaysMaxAvg = maxAvg;
        } else if (minAvg<allDaysMinAvg) {
          allDaysMinAvg = minAvg;
        }
        dayCount += 1;
      }
      console.log("fltSensordata");
      console.log(days.length);
      setFltSensorData({min: allDaysMinAvg, max: allDaysMaxAvg, days: days});
      console.log(days);
      //????? below
      //setRefDate(days[days.length-1].timestamp);

    }, [filteredSensorData]);

    useEffect(() => {
      if (allSensorData.lek!="") {
        loadRawSensorData(allSensorData.lek);
      } else {
        if (allSensorData.data.length > 0) {
          let data = [...allSensorData.data];
          setRawSensorData(data);
          setAllSensorData({lek:"", data:[]});

          //if (id!='S0003') {



            loadFilteredSensorData();


          //}



        }
      }
    }, [allSensorData]);

    async function loadFilteredSensorData() {
      console.log("yyy");
      await getFilteredSensorData().then((response) => {
        console.log("zzz");
        console.log(response);
        console.log(response.data.Payload);

        var jsonParsed = JSON.parse(response.data.Payload);
        //console.log(JSON.stringify(jsonParsed, null, 2));
        let rslt = jsonParsed.result;
        //console.log(JSON.stringify(rslt, null, 2));
        var rsltParsed = JSON.parse(rslt);


        console.log(rsltParsed.index.length);
        console.log(rsltParsed.index[0]);
        console.log(rsltParsed.index[2]);
        console.log(rsltParsed.index[3]);


        setFilteredSensorData(rsltParsed);

        /*
        let cols = rsltParsed.columns;
        console.log(cols[0]);
        //let jcols = JSON.parse(cols);
        //console.log(JSON.stringify(jcols, null, 2));
        let idx = rsltParsed.index;
        console.log(idx.length);
        console.log(idx[0]);
        //let jidx = JSON.parse(idx);
        //console.log(JSON.stringify(jidx, null, 2));
        let dat = rsltParsed.data;
        console.log(dat.length);
        console.log(dat[0]);
        //let jdat = JSON.parse(dat);
        //console.log(JSON.stringify(jdat, null, 2));
        */
      }).catch((error) => {
        console.log(error.message);
      })



    }

    async function loadRawSensorData(esk) {
      await getRawSensorData(0, esk).then((response) => {
        console.log(response);
        let allData = [...allSensorData.data,...response.data.Items];
        let lek = "";
        if (response.data.LastEvaluatedKey) {
          lek = JSON.stringify(response.data.LastEvaluatedKey);
        }
        setAllSensorData({lek: lek, data: allData});
      }).catch((error) => {
        console.log(error.message);
      })
    }

    async function onLoad() {
      //console.log(process.env.REACT_APP_GOOGLE_API_KEY);
      if (!isAuthenticated) {
        history.push("/");
        return;
      }

      setIsLoading(true);
      try {
        loadRawSensorData(null);
        //console.log("eeeeee");
        const result = await getSites();
        const result1 = await getOverlays();
        if (result.status && result.items) {
          let itms = result.items;
          let satsenseCoords = null;
          itms.forEach((site) => {
            //item["location"] = {lat: Number(item.content.location.lat), lng: Number(item.content.location.lng)};
            //console.log(item.content.location);
            if (site.content.id===id) {
              //console.log(item.content.bounds);
              site.content.pots.forEach((pot, idx) => {
                pot.selected = false;
              });
              setCurrentSite(site);
              setCurrentBounds(site.content.bounds);
            }

          });
          setSites(itms);
          if (result1.status && result1.items) {
            const ovs = [];
            const groups = {};
            const ovlys = result1.items;
            setOpacity(new Array(ovlys.length).fill(1));
            setImageUrls(new Array(ovlys.length).fill(""));
            setImages(new Array(ovlys.length).fill(null));
            ovlys.forEach((o) => {
              if (o.content.groupName) {
                if (groups[o.content.groupName]) {
                  groups[o.content.groupName].push(o);
                } else {
                  groups[o.content.groupName] = [o]
                }
              } else {
                if (o.content.overlayType == 'satsense') {
                  satsenseCoords = o.content.coords;
                }
                ovs.push(o);
              }
            });
            setGlImages(new Array(groups.length).fill(null));
            Object.keys(groups).forEach((groupName) => {
              groups[groupName][0]["overlays"] = groups[groupName];
              ovs.push(groups[groupName][0]);
            });

            ovs.sort((a, b) => {
                return a.content.order - b.content.order;
            });
            //console.log(ovs);
            setOverlays(ovs);
          }
          if (satsenseCoords) {
            getSatsensePoints(satsenseCoords).then(data => {
              let max=0;
              let min=0;
              data.points.forEach((point,idx) => {
                if (point.velocity>max) {
                  max=point.velocity;
                } else if (point.velocity<min){
                  min=point.velocity;
                }
              })
              data.points.forEach((point,idx) => {
                point.colour = colours[idx%colours.length];
                //point.velocityColour = (point.velocity==0?"#fff":point.velocity>0?(point.velocity/max*255).toString(16):(Math.abs(point.velocity/min*255)).toString(16));
                //console.log((Math.round(Math.abs(point.velocity/min*255))).toString(16));
                //console.log(point.id);

                point["direction"] = point.id.charAt(3);
                if (point.velocity==0) {
                  point.velocityColour = "#fff"
                } else if (point.velocity>0) {
                  let val = (Math.round(point.velocity/max*255)).toString(16);
                  if (val.length==1) {
                    val="0"+val;
                  }
                  point.velocityColour = "#1e90ff" + val;
                } else if (point.velocity<0) {
                  let val = (Math.round(Math.abs(point.velocity/min*255))).toString(16);
                  if (val.length==1) {
                    val="0"+val;
                  }
                  point.velocityColour = "#ff0000" + val;
                }
                //point.velocityColour = (point.velocity==0?"#fff":point.velocity>0?"#00ff00"+(Math.round(point.velocity/max*255)).toString(16):"#ff0000"+(Math.round(Math.abs(point.velocity/min*255))).toString(16));
              })



              //satsensePoints.points[idx]["colour"] =  colours[idx2];

              //console.log(data);
              setVelocity({min: min, max: max});
              setSatsensePoints(data);
            }).catch(error => {
              console.log(error);
            })
          }
          setIsLoading(false);
        } else {
          setIsLoading(false);
          alertVals.title="SPiVolt - Oops!";
          alertVals.message="Unable to retrieve sites, please check your internet connection and try later.";
          setAlertOpen(true);
        }
      } catch (e) {
        console.log(e.message);
        history.push("/");
      }

    }


    async function getGlImage(link, idx) {
      console.log(link);
      console.log(idx);
      if (glImages[idx]) {return}
      const url = await s3Download(link);
      //glImages[idx] = url;

      console.log(url);
      var img = new Image();
      img.onload = function() {
        glImages[idx] = url;
        setGlImages([...glImages]);
        console.log(glImages[idx]);
      }
      img.crossOrigin = "Anonymous";
      img.src = url;

    }

    async function getOverlayImage(overlay,idx) {
      const url = await s3Download(overlay.content.link);
      //console.log(overlay.content.link);
      //console.log(overlay);

      //setImageUrl(url);
      imageUrls[idx] = url;
      setImageUrls([...imageUrls]);

      overlay["isActive"] = true;
      setCurrentOverlayId(idx);
      //console.log(url);


      var img = new Image();


      img.onload = function() {
        //var oc = document.createElement('canvas');
        //oc.width = img.width;
        //oc.height = img.height;
        //var ctx = oc.getContext('2d');
        //ctx.drawImage(img, 0, 0, img.width, img.height);
        images[idx] = img;
        setImages([...images]);
      }

      img.crossOrigin = "Anonymous";
      img.src = url;



    }

    const handleOpacityOverlayChange = (newVal, overlay, idx)  => {
      opacity[idx] = newVal;
      setOpacity([...opacity]);
    }

    function handleTogglePotOverlay() {
      setPotsActive(!potsActive);
      checkboxRef.current.focus();
    }

    const handleToggleOverlay = (overlay, idx) => () => {
      if (overlay.isActive) {
        console.log(overlay);
        overlay.isActive = false;
        if (spOverlayIdx==idx) {
          setSpOverlayIdx(-1);
        } else if (glOverlayIdx==idx){
          setGlOverlayIdx(-1);
        } else if (overlay.content.overlayType=="satsense") {
          satsensePoints.points.forEach((point) => {
            point.selected = false;
          })
          setSelectedPoints([]);
          setSatsensePoints(satsensePoints);
        } else {
          setCurrentOverlayId(-1);
          imageUrls[idx] = "";
          setImageUrls([...imageUrls]);
        }
      } else {
        if (overlay.content.overlayType=="line") {
          setSpOverlayIdx(idx);
          overlay["isActive"] = true;
        } else if (overlay.content.overlayType=="line-figure") {
          setGlOverlayIdx(idx);
          overlay["isActive"] = true;
        } else if (overlay.content.overlayType=="image") {
          getOverlayImage(overlay, idx);
        } else if (overlay.content.overlayType=="satsense") {
          overlay["isActive"] = true;
        }
      }
      setOverlays([...overlays]);
    }

    function handleSettingsClose() {
      setSettingsOpen(-1);
    }

    function handleChangeLineColourComplete(color, event) {
      console.log(color.hex);
      lineColour[settingsOpen] = color.hex;
      setLineColour(lineColour);
      //setLineColour([...lineColour]);
      setForceUpdate(!forceUpdate);
    }

    const handleLineWidthChange = (e) => {
      lineWidth[settingsOpen].forEach((item, i) => {
        lineWidth[settingsOpen][i] = e.target.value;
      });
      setLineWidth([...lineWidth]);

      //lineWidth[settingsOpen] = e.target.value;
      //setLineWidth(lineWidth);
      //setForceUpdate(!forceUpdate);
    }

    function handleAlertClose() {
      setAlertOpen(false);
    };
    //function handleSiteClick(e) {
    //  e.preventDefault();
    //  history.push("/sites/" + sites[currentMarkerKey].itemCode);
    //};


    function rgbToHex(r, g, b) {
      if (r > 255 || g > 255 || b > 255)
      throw "Invalid color component";
      return ((r << 16) | (g << 8) | b).toString(16);
    }

    function getValueFromRGB(idx,r,g,b) {
      const colourData = overlays[idx].content.legend;
      for (let i=0;i<colourData.length;i++) {
        if (Math.abs(colourData[i].rgb.r-r)<=3 && Math.abs(colourData[i].rgb.g-g)<=3 && Math.abs(colourData[i].rgb.b-b)<=3) {
          return colourData[i].val;
        }
      }
      return null;
    }

    const onMapClick = (e) => {
      //console.log(e);
/*
      const potId = newPots.length * 5;
      const lat = e.latLng.lat();
      const lng = e.latLng.lng();
      setNewPots([...newPots,{id: potId, location:{lat:lat,lng:lng}}]);
      setPotStr(potStr + "," + potId + "," + lat + "," + lng);
      console.log(potStr);

*/

    }

    function getBounds() {
      let n=-1000;
      let s=1000;
      let e=-1000;
      let w=1000;
      overlays.forEach((ov, idx) => {
        if (ov.isActive && ov.content.overlayType=="image") {
          if (n<ov.content.bounds.north) {
            n=ov.content.bounds.north;
          }
          if (s>ov.content.bounds.south) {
            s=ov.content.bounds.south;
          }
          if (e<ov.content.bounds.east) {
            e=ov.content.bounds.east;
          }
          if (w>ov.content.bounds.west) {
            w=ov.content.bounds.west;
          }
        }
      });
      if (s==1000) {
        return null;
      } else {
        return {north:n,south:s,west:w,east:e};
      }
    }

    const onMouseMove = (e) => {
      const lat = e.latLng.lat();
      const lng = e.latLng.lng();
      //const ov = overlays[currentOverlayId];

      if (currentSite && showPots && potSelectState=="singleselect") {
        let distances = [];
        let pots = currentSite.content.pots.filter(pot=>{return pot.selected});
        if (pots.length<10) {
          pots.forEach((pot, i) => {
            console.log(pot);
            const dist = haversine_distance({lat: lat, lng: lng}, pot.location);
            distances.push({title:pot.name, value:dist.toFixed(1) + "m"});
          });
          if (distances.length > 0) {
            setCurrentLocation({lng:lng, lat:lat});
            setOverlayValues(distances);
            setInfoOpen(true);
          } else {
            setInfoOpen(false);
          }
        } else {
          setInfoOpen(false);
        }
      } else {
        const fullBounds = getBounds();
        if (!fullBounds || !showPots || (lat > fullBounds.north || lat < fullBounds.south || lng > fullBounds.east || lng < fullBounds.west)) {
          setInfoOpen(false);
          setCurrentLocation(null);
          map.setOptions({draggableCursor:''});
          return;
        }
        let ovVals = [];
        overlays.forEach((ov, idx) => {
          if (images[idx] && ov.isActive && ov.content.overlayType=="image") {
            const bounds = ov.content.bounds;
            if (!(lat > bounds.north || lat < bounds.south || lng > bounds.east || lng < bounds.west)) {
              const ddh = Math.abs(bounds.north - bounds.south);
              const ddw = Math.abs(bounds.west - bounds.east);
              const img = images[idx];
              const rx=img.width/ddw;
              const ry=img.height/ddh;
              const x=Math.abs(bounds.west-lng)*rx;
              const y=Math.abs(bounds.north-lat)*ry;
              var oc = document.createElement('canvas');
              oc.width = img.width;
              oc.height = img.height;
              var ctx = oc.getContext('2d');
              ctx.drawImage(img, 0, 0, img.width, img.height);
              var pixelData = ctx.getImageData(x, y, 1, 1).data;
              if((pixelData[0] == 0) && (pixelData[1] == 0) && (pixelData[2] == 0) && (pixelData[3] == 0)){
                setInfoOpen(false);
                setCurrentLocation(null);
                map.setOptions({draggableCursor:''});
              } else {
                map.setOptions({draggableCursor:'crosshair'});
                //console.log(pixelData[0], pixelData[1], pixelData[2]);
                const val = getValueFromRGB(idx, pixelData[0], pixelData[1], pixelData[2]);
                if (val) {
                  const newVal = Number(val);
                  ovVals.push({title: ov.content.name, value: newVal.toFixed(2)});
                }
                //setCurrentLocation({lng:lng, lat:lat});
                //setInfoOpen(true);
              }
            }
          }
        });
        setCurrentLocation({lng:lng, lat:lat});
        setOverlayValues(ovVals);
        setInfoOpen(true);
    }
}

const onMarkerClick = (e) => {
  //console.log(map.zoom);
  //setInfoOpen(true);
}
const onCloseClick = () => {
  setInfoOpen(false);
}


function getLineCoords(overlay) {
  let coords = [];
  overlay.content.legend.forEach((item, idx) => {
    coords.push(item.location);
  });
  return coords;
}
function getGroupLineCoords(overlay) {
  //console.log(overlay);
  let coords = [];
  overlay.content.coords.forEach((item, idx) => {
    coords.push(item);
  });
  console.log(overlay.content.coords);
  console.log(coords);
  return coords;
}

function onToggleOpenStaticSP() {
  let cd = [];
  overlays[spOverlayIdx].content.legend.forEach(function (row, index) {
    //cd.push({idx: index, mv: Number(row.val).toFixed(2), metres: Number(row.metres)});
    cd.push({idx: index, mv: Number(row.val), metres: Number(row.metres)});
  });
  getChartTitle(null,null, 'staticchart')
  setChartData(cd);
  setStaticSpChartOpen(true);
}

function onToggleOpenERD(idx) {

  //console.log(overlays[glOverlayIdx].overlays[idx].content.name);

  getGlImage(overlays[glOverlayIdx].overlays[idx].content.link, idx);


  setFigureOpen(idx);
}


function handleSPLineOptionsClick(e, idx) {
  //console.log(idx);
  setSettingsOpen(idx);
}
function haversine_distance(loc1, loc2) {
    const R = 6371e3; // earth radius in meters
    const φ1 = loc1.lat * (Math.PI / 180);
    const φ2 = loc2.lat * (Math.PI / 180);
    const Δφ = (loc2.lat - loc1.lat) * (Math.PI / 180);
    const Δλ = (loc2.lng - loc1.lng) * (Math.PI / 180);

    const a = (Math.sin(Δφ / 2) * Math.sin(Δφ / 2)) +
              ((Math.cos(φ1) * Math.cos(φ2)) * (Math.sin(Δλ / 2) * Math.sin(Δλ / 2)));

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = R * c;
    return distance; // in meters


/*

      var R = 6371e3; // Radius of the Earth in kms
      var rlat1 = loc1.lat * (Math.PI/180); // Convert degrees to radians
      var rlat2 = loc2.lat * (Math.PI/180); // Convert degrees to radians
      var difflat = (rlat2-rlat1) * (Math.PI/180); // Radian difference (latitudes)
      var difflon = (loc2.lng-loc1.lng) * (Math.PI/180); // Radian difference (longitudes)

      var d = 2 * R * Math.asin(Math.sqrt(Math.sin(difflat/2)*Math.sin(difflat/2)+Math.cos(rlat1)*Math.cos(rlat2)*Math.sin(difflon/2)*Math.sin(difflon/2)));
      return d;
      */
    }
function onToggleOpenPot(potId, key, potName, idx) {
  console.log(potId, key, potName, idx);
  setCurrentChartDayIndex(0);
  if (potSelectState=="singleselect") {
    if (idx==0) {
      return;
    }
    currentSite.content.pots[idx]["selected"] = !currentSite.content.pots[idx]["selected"];
    setUpdateState(!updateState);
  } else if (potSelectState=="disableselect") {
    currentSite.content.pots[idx]["disabled"] = !currentSite.content.pots[idx]["disabled"];
    currentSite.content.pots[idx]["selected"] = false;
    calcAverages();
    //console.log('norms!');
    //getNorms();
    setUpdateState(!updateState);
  } else {
    if (currentSite.content.pots[idx].selected) {
      let selCount = 0;
      for (var i = 0; i < currentSite.content.pots.length; ++i) {
        if (currentSite.content.pots[i].selected) {
          selCount += 1;
        }
      }
      if (potSelectState=="chartchainageselect" && selCount < 2) {
        alertVals.title="SPiVolt - Oops!";
        alertVals.message="Chainage charting requires at least two selected sensors.";
        setAlertOpen(true);
        return;
      }
      let cd = [];
      if (potSelectState=="chartchainageselect") {

          let e = -1000;
          let w = 1000;
          let n = -1000;
          let s = 1000;
          let eIdx = 0;
          let wIdx = 0;
          let nIdx = 0;
          let sIdx = 0;
          let selPotIdxs = [];
          currentSite.content.pots.forEach((pot, idx) => {
            if (pot.selected) {
              selPotIdxs.push(idx);
              if (pot.location.lng>e) {
                e = pot.location.lng;
                eIdx = idx;
              }
              if (pot.location.lng<w) {
                w = pot.location.lng;
                wIdx = idx;
              }
              if (pot.location.lat>n) {
                n = pot.location.lat;
                nIdx = idx;
              }
              if (pot.location.lat<s) {
                s = pot.location.lat;
                sIdx = idx;
              }
            }
          })
          let ewDiff = currentSite.content.pots[wIdx].location.lng-currentSite.content.pots[eIdx].location.lng;
          let nsDiff = currentSite.content.pots[nIdx].location.lng-currentSite.content.pots[sIdx].location.lng;
          let diffs = [];

          let diff = haversine_distance(currentSite.content.pots[wIdx].location,currentSite.content.pots[eIdx].location);
          let biggestDiff = diff;
          let startIdx = wIdx;
          diff = haversine_distance(currentSite.content.pots[wIdx].location,currentSite.content.pots[nIdx].location);
          if (diff>biggestDiff) {
            biggestDiff = diff;
            startIdx = wIdx;
          }
          diff = haversine_distance(currentSite.content.pots[wIdx].location,currentSite.content.pots[sIdx].location);
          if (diff>biggestDiff) {
            biggestDiff = diff;
            startIdx = wIdx;
          }
          diff = haversine_distance(currentSite.content.pots[eIdx].location,currentSite.content.pots[nIdx].location);
          if (diff>biggestDiff) {
            biggestDiff = diff;
            startIdx = nIdx;
          }
          diff = haversine_distance(currentSite.content.pots[eIdx].location,currentSite.content.pots[sIdx].location);
          if (diff>biggestDiff) {
            biggestDiff = diff;
            startIdx = sIdx;
          }
          let totalDiff = 0;
          let orderedIdxs = [{name: currentSite.content.pots[startIdx].name, idx: startIdx, pos: totalDiff}];
          for (let i=0;i<selPotIdxs.length;i++) {
            if (selPotIdxs[i] == startIdx) {
              selPotIdxs.splice(i, 1);
              break;
            }
          }

          let currentIdx = startIdx
          let currSelPotIdx = 999999;
          while (selPotIdxs.length>0) {
            let smallestDiff = biggestDiff + 500;
            for (let i=0;i<selPotIdxs.length;i++) {
              diff = haversine_distance(currentSite.content.pots[currentIdx].location,currentSite.content.pots[selPotIdxs[i]].location);
              if (diff<smallestDiff) {
                smallestDiff = diff;
                startIdx = selPotIdxs[i];
                currSelPotIdx = i;
              }
            }
            selPotIdxs.splice(currSelPotIdx, 1);
            totalDiff+=smallestDiff;
            orderedIdxs.push({name: currentSite.content.pots[startIdx].name, idx: startIdx, pos: totalDiff});
            currentIdx = startIdx;
          }
          let recs = [];
          if (potDataType=="raw") {
            for (let index=sensorData.length-1;index>=0;index--) {
              let row = sensorData[index];
              let rec = {idx: index, time: row.timestamp * 1000, tmp: Number(row.temperature) / 100, reftmp: Number(row.reftemperature) / 100, pressure: (Number(row.pressure) / 100000).toFixed(2)};
              let pots = [];
              orderedIdxs.forEach((orderedIdxData, iii) => {
                let mv = Math.round(Number(row[orderedIdxData.idx]) / 10000) / 100;
                pots.push( {idx: iii, mv: mv, pos: Math.round(orderedIdxData.pos*10)/10, id: orderedIdxData.name});
              });
              rec["pots"] = pots;
              recs.push(rec);
            }
          } else if (potDataType=="averaged") {
            for (let index=0;index<avgSensorData.days.length;index++) {
              let day = avgSensorData.days[index];
              let rec = {idx: index, time: day.timestamp, tmp: day.temperature, reftmp: day.reftemperature, pressure: day.pressure};
              let pots = [];
              console.log(orderedIdxs);
              orderedIdxs.forEach((orderedIdxData, iii) => {
                console.log(day);
                let mv = day.pots[Number(orderedIdxData.idx)-1];//Math.round(Number(row[orderedIdxData.idx]) / 10000) / 100;
                pots.push( {idx: iii, mv: mv, pos: Math.round(orderedIdxData.pos*10)/10, id: orderedIdxData.name});
              });
              rec["pots"] = pots;
              console.log(rec);
              recs.push(rec);
            }
          } else if (potDataType=="filtered") {
            for (let index=0;index<fltSensorData.days.length;index++) {
              let day = fltSensorData.days[index];
              let rec = {idx: index, time: day.timestamp, tmp: day.temperature, reftmp: day.reftemperature, pressure: day.pressure};
              let pots = [];
              console.log(orderedIdxs);
              orderedIdxs.forEach((orderedIdxData, iii) => {
                console.log(day);
                let mv = day.pots[Number(orderedIdxData.idx)-1];//Math.round(Number(row[orderedIdxData.idx]) / 10000) / 100;
                pots.push( {idx: iii, mv: mv, pos: Math.round(orderedIdxData.pos*10)/10, id: orderedIdxData.name});
              });
              rec["pots"] = pots;
              console.log(rec);
              recs.push(rec);
            }
          } else if (potDataType=="normalised1") {
            for (let index=0;index<nrmSensorData.days.length;index++) {
              let day = nrmSensorData.days[index];
              let rec = {idx: index, time: day.timestamp, tmp: day.temperature, reftmp: day.reftemperature, pressure: day.pressure};
              let pots = [];
              orderedIdxs.forEach((orderedIdxData, iii) => {
                console.log(orderedIdxData);
                console.log(day.pots);
                let mv = day.pots[Number(orderedIdxData.idx)-1].normalised1;//Math.round(Number(row[orderedIdxData.idx]) / 10000) / 100;
                pots.push( {idx: iii, mv: mv, pos: Math.round(orderedIdxData.pos*10)/10, id: orderedIdxData.name});
              });
              rec["pots"] = pots;
              recs.push(rec);
            }
          } else if (potDataType=="normalised2") {
            for (let index=0;index<nrmSensorData.days.length;index++) {
              let day = nrmSensorData.days[index];
              let rec = {idx: index, time: day.timestamp, tmp: day.temperature, reftmp: day.reftemperature, pressure: day.pressure};
              let pots = [];
              orderedIdxs.forEach((orderedIdxData, iii) => {
                let mv = day.pots[Number(orderedIdxData.idx)-1].normalised2;//Math.round(Number(row[orderedIdxData.idx]) / 10000) / 100;
                pots.push( {idx: iii, mv: mv, pos: Math.round(orderedIdxData.pos*10)/10, id: orderedIdxData.name});
              });
              rec["pots"] = pots;
              recs.push(rec);
            }
          }


          setChartTitle(getChartTitle(null, null, 'chainagechart'));
          setChartData({"datatype": potDataType, "data": recs});
          setChainageChartOpen(true);


      } else if (potSelectState=="charttimeselect") {
        let pots = [];
        let gotPots = false;
        let coloursIdx = 0;
        if (potDataType=="raw") {
          sensorData.forEach(function (row, index) {
            let rec = {idx: index, time: row.timestamp * 1000, tmp: Number(row.temperature) / 100, reftmp: Number(row.reftemperature) / 100, pressure: (Number(row.pressure) / 100000).toFixed(2)};
            currentSite.content.pots.forEach((pot) => {
              if (pot.selected) {
                //let title = pot.name?pot.name:pot.id
                let dataKey = pot.name?pot.name:pot.id;//"mv-" + title;
                if (!gotPots) {
                  pots.push({name: dataKey, colour: colours[coloursIdx]});
                  coloursIdx++;
                  if (coloursIdx>=colours.length) {
                    coloursIdx=0;
                  }
                }
                //console.log(pot.id);
                rec[dataKey] = Math.round(Number(row[pot.id]) / 10000) / 100;
              }
            });
            gotPots = true;
            cd.push(rec);
          });
        } else if (potDataType=="averaged") {
          avgSensorData.days.forEach(function (day, index) {
            let rec = {idx: index, time: day.timestamp, tmp: day.temperature, reftmp: day.reftemperature, pressure: day.pressure};
            currentSite.content.pots.forEach((pot,idx) => {
              if (pot.selected) {
                let dataKey = pot.name?pot.name:pot.id;//"mv-" + (pot.name?pot.name:pot.id);
                if (!gotPots) {
                  pots.push({name: dataKey, colour: colours[coloursIdx]});
                  coloursIdx++;
                  if (coloursIdx>=colours.length) {
                    coloursIdx=0;
                  }
                }
                console.log(pot.id, Number(pot.id)-1);
                rec[dataKey] = day.pots[Number(pot.id)-1];
              }
            });
            gotPots = true;
            cd.push(rec);
          });
        } else if (potDataType=="normalised1") {
          nrmSensorData.days.forEach(function (day, index) {
            let rec = {idx: index, time: day.timestamp, tmp: day.temperature, reftmp: day.reftemperature, pressure: day.pressure};
            currentSite.content.pots.forEach((pot,idx) => {
              if (pot.selected) {
                let dataKey = pot.name?pot.name:pot.id;//"mv-" + (pot.name?pot.name:pot.id);
                if (!gotPots) {
                  pots.push({name: dataKey, colour: colours[coloursIdx]});
                  coloursIdx++;
                  if (coloursIdx>=colours.length) {
                    coloursIdx=0;
                  }
                }
                console.log(pot.id, Number(pot.id)-1);
                rec[dataKey] = day.pots[Number(pot.id)-1].normalised1;
              }
            });
            gotPots = true;
            cd.push(rec);
          });
        } else if (potDataType=="normalised2") {
          nrmSensorData.days.forEach(function (day, index) {
            let rec = {idx: index, time: day.timestamp, tmp: day.temperature, reftmp: day.reftemperature, pressure: day.pressure};
            currentSite.content.pots.forEach((pot,idx) => {
              if (pot.selected) {
                let dataKey = pot.name?pot.name:pot.id;//"mv-" + (pot.name?pot.name:pot.id);
                if (!gotPots) {
                  pots.push({name: dataKey, colour: colours[coloursIdx]});
                  coloursIdx++;
                  if (coloursIdx>=colours.length) {
                    coloursIdx=0;
                  }
                }
                console.log(pot.id, Number(pot.id)-1);
                rec[dataKey] = day.pots[Number(pot.id)-1].normalised2;
              }
            });
            gotPots = true;
            cd.push(rec);
          });
        } else if (potDataType=="filtered") {
          fltSensorData.days.forEach(function (day, index) {
            let rec = {idx: index, time: day.timestamp, tmp: day.temperature, reftmp: day.reftemperature, pressure: day.pressure};
            currentSite.content.pots.forEach((pot,idx) => {
              if (pot.selected) {
                let dataKey = pot.name?pot.name:pot.id;//"mv-" + (pot.name?pot.name:pot.id);
                if (!gotPots) {
                  pots.push({name: dataKey, colour: colours[coloursIdx]});
                  coloursIdx++;
                  if (coloursIdx>=colours.length) {
                    coloursIdx=0;
                  }
                }
                console.log(pot.id, Number(pot.id)-1);
                rec[dataKey] = day.pots[Number(pot.id)-1];
              }
            });
            gotPots = true;
            cd.push(rec);
          });
        }

      setChartTitle(getChartTitle(null, null, 'potchart'));
      setChartData({"datatype": potDataType, "data": cd, "pots": pots});
      setMultiPotChartOpen(true);

}

    } else {
      if (potSelectState=="chartchainageselect") {
        alertVals.title="SPiVolt - Oops!";
        alertVals.message="Chainage charting requires at least two selected sensors.";
        setAlertOpen(true);
      } else {
        let cd = [];
        if (potDataType=="raw") {
          sensorData.forEach(function (row, index) {
            cd.push({idx: index, mv: (Number(row[potId]) / 1000000).toFixed(2), time: row.timestamp * 1000, tmp: Number(row.temperature) / 100, reftmp: Number(row.reftemperature) / 100, pressure: (Number(row.pressure) / 100000).toFixed(2)});
          });
        } else if (potDataType=="averaged") {
          avgSensorData.days.forEach(function (day, index) {
            cd.push({idx: index, mv: day.pots[idx-1], time: day.timestamp, tmp: day.temperature, reftmp: day.reftemperature, pressure: day.pressure});
          });
        } else if (potDataType=="normalised1") {
          nrmSensorData.days.forEach(function (day, index) {
            cd.push({idx: index, mv: day.pots[idx-1].normalised1, time: day.timestamp, tmp: day.temperature, reftmp: day.reftemperature, pressure: day.pressure});
          });
        } else if (potDataType=="normalised2") {
          nrmSensorData.days.forEach(function (day, index) {
            cd.push({idx: index, mv: day.pots[idx-1].normalised2, time: day.timestamp, tmp: day.temperature, reftmp: day.reftemperature, pressure: day.pressure});
          });
        } else if (potDataType=="filtered") {
          fltSensorData.days.forEach(function (day, index) {
            cd.push({idx: index, mv: day.pots[idx-1], time: day.timestamp, tmp: 0, reftmp: 0, pressure: 0});
          });
        }
        setChartTitle(getChartTitle(potId, potName, 'potchart'));
        setChartData({"datatype": potDataType, "data": cd});
        setPotChartOpen(true);
      }
    }
  }
}
function getChartTitle(potId, potName, chartType) {
  const siteName = currentSite?currentSite.content.name + ": ":'';
  let subTitle = chartType=="staticchart"?<b>Static SP</b>:chartType=="satsensechart"?<><b>Displacements</b></>:<><b>SP Sensors{chartType=="chainagechart"?" Chainage":""}</b></>;
  if (potId || potName) {
    subTitle = potId?<>{subTitle}<b>: {potName?potName:potId}</b></>:<>{subTitle}</>;
  }
  if (chartType=="potchart" || chartType=="chainagechart") {
    subTitle = <>{subTitle} <b>({potDataType}{potDataType=="normalised2"?" - ref date: " + (new Date(refDate).toDateString()):""})</b></>;
  }
  return <>{siteName}{subTitle}</>;
}
function onToggleOpen(key) {
  setInfoOpen(false);
  //setCurrentMarkerKey(key);
}

function onToggleOpenSatsensePoint(index, key) {
  //console.log(satsensePoints.points[index]);
  //var mustAdd = true;
  for( var i = 0; i < selectedPoints.length; i++){
    //console.log(selectedPoints[i], index);
    if ( selectedPoints[i] === index) {
      //selectedMarkerKeys.splice(i, 1);
      selectedPoints.splice(i, 1);
      satsensePoints.points[index]["selected"] = false;
      //satsensePoints.points[index]["colour"] = null;
      //mustAdd = false;
      //setSelectedMarkerKeys([...selectedMarkerKeys]);
      setSelectedPoints([...selectedPoints]);
      return;
    }
  }
  //if (mustAdd) {
  //setCurrentMarkerKey(key);
  //setCurrentPotId(index);
  //selectedMarkerKeys.push(key);
  selectedPoints.push(index);
  //}
  //setSelectedMarkerKeys([...selectedMarkerKeys]);
  setSelectedPoints([...selectedPoints]);
  let series = []
  selectedPoints.forEach((idx, idx2) => {
    //console.log(idx);
    let cd = [];
    satsensePoints.points[idx]["selected"] = true;
    //if (satsensePoints.points[idx]["colour"] == null) {
    //  satsensePoints.points[idx]["colour"] =  colours[idx2];
    //}
    //;

    //console.log(satsensePoints.points[idx].displacements.length
    let dpIdx = 0;
    //console.log(satsensePoints.points[idx]);
    for (const k in satsensePoints.points[idx].displacements) {
      //console.log(`${k} , ${satsensePoints.points[idx].displacements[k]}`);
      let x = satsensePoints.points[idx].displacements[k];
      if (x) {
        let year = k.substring(0,4);
        let month = k.substring(4,6);
        let day = k.substring(6);
        let strdate = year + "-" + month + "-" + day + "Z";
        let date = new Date(strdate);
        if (x != null) {
          let val = (Number(satsensePoints.points[idx].displacements[k])).toFixed(1)
          cd.push({idx: dpIdx, mm: val, time: date.getTime(), id: satsensePoints.points[idx].id, colour: satsensePoints.points[idx].colour});
        } else {
          cd.push({idx: dpIdx, time: date.getTime(), id: satsensePoints.points[idx].id, colour: satsensePoints.points[idx].colour});
        }
        dpIdx++;
      }
    }
    series.push({id: satsensePoints.points[idx].id, velocity: satsensePoints.points[idx].velocity, colour: satsensePoints.points[idx].colour, displacements: cd, coordinates: satsensePoints.points[idx].coordinates});
  });
  setChartTitle(getChartTitle(null,null, 'satsensechart'));
  console.log(series);
  setChartData(series);
  setSatsenseChartOpen(true);

}

const handleSatsenseDirectionChange = event => {
  setSatsenseDirection(event.target.value);
};
const handlePotDataTypeChange = event => {
  setCurrentDataDay(0);
  setPotDataType(event.target.value);
};



function handleSelectButtonClick(value) {
  if (value == "clearselect") {
    currentSite.content.pots.forEach((pot) => {
      pot["selected"] = false;
    });
    setUpdateState(!updateState);
  } else if (value == "allselect") {
    currentSite.content.pots.forEach((pot) => {
      if (pot.id == "0") {
        pot["selected"] = false;
      } else {
        pot["selected"] = true;
      }
    });
    setUpdateState(!updateState);
  } else {
    setPotSelectState(value);
  }
};



function valueLabelFormat(value) {
  return `${(value*100).toFixed(0)}%`;
}

function dataDayLabelFormat(days) {
  if (currentDataDay==0) {
    return "Today";
  } if (currentDataDay==1) {
    return "Yesterday";
  }
  const d = new Date(days[currentDataDay].timestamp);
  return d.toDateString();
}

function getFormattedDate(timestamp) {
  const d = new Date(timestamp);
  return d.toLocaleDateString('en-uk');
}
function rawDataChartDayLabelFormat() {
  /*if (currentChartDayIndex==0) {
    return "Today";
  } if (currentChartDayIndex==1) {
    return "Yesterday";
  }
  */
  const d = new Date(chartData.data[currentChartDayIndex].time);
  return potDataType=="raw"?d.toLocaleString('en-uk'):d.toLocaleDateString('en-uk');
}

function handleCurrentDataDayChange(event, newVal) {
  setCurrentDataDay(newVal);
}
function handleCurrentChartDayIndexChange(event, newVal) {
  setCurrentChartDayIndex(newVal);
  //setAltChartData({data: chartData, idx: currentChartDayIndex});

  console.log(newVal);
}

const onDMLoad = drawingManager => {
  console.log(drawingManager)
}

const onPolygonComplete = polygon => {
  const poly = [];
  const path = polygon.getPath().getArray();
  path.forEach(coord=>{
   poly.push([coord.lat(),coord.lng()]);
  });
  currentSite.content.pots.forEach((pot) => {
    if (pot.id!="0" && pointInPolygon([pot.location.lat,pot.location.lng], poly)) {
      pot["selected"] = true;
    }
  });
  polygon.setMap(null);
  setUpdateState(!updateState);
}
const onMarkerComplete = marker => {

  console.log(marker.position.lat())
}

function getColour(potId) {

 //console.log(potId, potDataType);
  const potIdx = Number(potId) - 1;
  //console.log(potId, potIdx);
  //console.log(currentSite.content.pots);
  if (currentSite.content.pots[potIdx+1].disabled) {
    return "#222";
  }
  switch (potDataType) {
    case "averaged":
      if (potId == "0") {
        return "#fff";
      }
      const potVal = avgSensorData.days[currentDataDay].pots[potIdx];
      //const min = ((avgSensorData.days[currentDataDay].max-avgSensorData.days[currentDataDay].min)*cutoffs[0]/100)+avgSensorData.days[currentDataDay].min;
      const min = ((avgSensorData.max-avgSensorData.min)*cutoffs[0]/100)+avgSensorData.min;
      //const max = ((avgSensorData.days[currentDataDay].max-avgSensorData.days[currentDataDay].min)*cutoffs[1]/100)+avgSensorData.days[currentDataDay].min;
      const max = ((avgSensorData.max-avgSensorData.min)*cutoffs[1]/100)+avgSensorData.min;
      const diff = (max - min) / rainbowColours.length;
      if (potVal>=max) {
        return rainbowColours[rainbowColours.length-1];
      } else if (potVal<=min) {
        return rainbowColours[0];
      } else {
        for (var i = 0; i < rainbowColours.length; i++) {
          const cuttoff = i==rainbowColours.length-1?max:min+((i+1)*diff);
          if (potVal<=cuttoff) {
            return rainbowColours[i];
          }
        }
      }
      return "#fff";
      case "filtered":
        if (potId == "0") {
          return "#fff";
        }
        const potVal3 = fltSensorData.days[currentDataDay].pots[potIdx];
        const min3 = ((fltSensorData.max-fltSensorData.min)*cutoffs[0]/100)+fltSensorData.min;
        const max3 = ((fltSensorData.max-fltSensorData.min)*cutoffs[1]/100)+fltSensorData.min;
        const diff3 = (max3 - min3) / rainbowColours.length;
        if (potVal3>=max3) {
          return rainbowColours[rainbowColours.length-1];
        } else if (potVal3<=min3) {
          return rainbowColours[0];
        } else {
          for (var i = 0; i < rainbowColours.length; i++) {
            const cuttoff = i==rainbowColours.length-1?max3:min3+((i+1)*diff3);
            if (potVal3<=cuttoff) {
              return rainbowColours[i];
            }
          }
        }
        return "#fff";
    case "normalised1":
    if (nrmSensorData.days[currentDataDay].pots[potIdx]) {
    const potVal1 = nrmSensorData.days[currentDataDay].pots[potIdx].normalised1;
    const min1 = nrmSensorData.days[currentDataDay].min.normalised1;
    const max1 = nrmSensorData.days[currentDataDay].max.normalised1;

    if (potVal1==0) {
      return "#fff";
    } else if (potVal1>0) {
      let val = (Math.round(potVal1/max1*255)).toString(16);
      if (val.length==1) {
        val="0"+val;
      }
      return "#1e90ff" + val;
    } else if (potVal1<0) {
      let val = (Math.round(Math.abs(potVal1/min1*255))).toString(16);
      if (val.length==1) {
        val="0"+val;
      }
      return "#ff0000" + val;
    }
}
return "#fff";

    case "normalised2":
    if (nrmSensorData.days[currentDataDay].pots[potIdx]) {
    const potVal2 = nrmSensorData.days[currentDataDay].pots[potIdx].normalised2;
    const min2 = nrmSensorData.min2; //nrmSensorData[currentDataDay].min.normalised2;
    const max2 = nrmSensorData.max2; //nrmSensorData[currentDataDay].max.normalised2;
//console.log(currentDataDay,potIdx, potVal2);
    if (potVal2==0) {
      return "#fff";
    } else if (potVal2>0) {
      let val = (Math.round(potVal2/max2*255)).toString(16);
      if (val.length==1) {
        val="0"+val;
      }
      return "#1e90ff" + val;
    } else if (potVal2<0) {
      let val = (Math.round(Math.abs(potVal2/min2*255))).toString(16);
      if (val.length==1) {
        val="0"+val;
      }
      return "#ff0000" + val;
    }
}



      return "#fff";
  }
}

const handleDatePickerChange = (newValue) => {
    setRefDate(newValue.valueOf());
  };
  const handleStartAllDataDatePickerChange = (newValue) => {
      setStartAllDataRefDate(newValue.valueOf());
    };
    const handleEndAllDataDatePickerChange = (newValue) => {
        setEndAllDataRefDate(newValue.valueOf());
      };



  const handleOpenDatePicker = (evt) => {
      setDatePickerOpen(!datePickerOpen);
    };

  const isDateValid = (current) => {
      const cts = current.valueOf();
      return cts >= (avgSensorData.days[avgSensorData.days.length-1].timestamp - 86400000) && cts <=avgSensorData.days[0].timestamp;
    };
    const isStartAllDataDateValid = (current) => {
      const cts = (current.valueOf() / 1000);
      const st = Number(initialSensorData[0].timestamp) - 86400;
      const et = Number(initialSensorData[initialSensorData.length-1].timestamp);
      return cts >= st && cts <= et && cts <= (endAllDataRefDate / 1000);
    }
    const isEndAllDataDateValid = (current) => {
      const cts = (current.valueOf() / 1000);
      const st = Number(initialSensorData[0].timestamp) - 86400;
      const et = Number(initialSensorData[initialSensorData.length-1].timestamp);
      return cts >= st && cts >= (startAllDataRefDate / 1000) && cts <= et;
    }







      ///////////////// DOWNLOADER //////////////////////////

      const handleDownloadClick = async (event) => {



        if (isDownloading) {
          return;
        }
        event.preventDefault();

        let selCount = 0;
        for (var i = 0; i < currentSite.content.pots.length; ++i) {
          if (currentSite.content.pots[i].selected) {
            selCount += 1;
            break;
          }
        }
        if (selCount == 0) {
          alertVals.title="SPiVolt - Oops!";
          alertVals.message="please select at least one pot to download.";
          setAlertOpen(true);
          return;
        }


        setIsDownloading(true);


    //console.log("_________________");
    //console.log(avgSensorData);
    console.log(potDataType);
    //console.log("_________________");



let cd = [];
    let pots = [];
    let gotPots = false;
    let coloursIdx = 0;
    if (potDataType=="raw") {
      sensorData.forEach(function (row, index) {
        let rec = {idx: index, time: row.timestamp * 1000, tmp: Number(row.temperature) / 100, reftmp: Number(row.reftemperature) / 100, pressure: (Number(row.pressure) / 100000).toFixed(2)};
        currentSite.content.pots.forEach((pot) => {
          if (pot.selected) {
            //let title = pot.name?pot.name:pot.id
            let dataKey = pot.name?pot.name:pot.id;//"mv-" + title;
            if (!gotPots) {
              pots.push({name: dataKey, colour: colours[coloursIdx]});
              coloursIdx++;
              if (coloursIdx>=colours.length) {
                coloursIdx=0;
              }
            }
            //console.log(pot.id);
            rec[dataKey] = Math.round(Number(row[pot.id]) / 10000) / 100;
          }
        });
        gotPots = true;
        cd.push(rec);
      });
    } else if (potDataType=="averaged") {
      avgSensorData.days.forEach(function (day, index) {
        let rec = {idx: index, time: day.timestamp, tmp: day.temperature, reftmp: day.reftemperature, pressure: day.pressure};
        currentSite.content.pots.forEach((pot,idx) => {
          if (pot.selected) {
            let dataKey = pot.name?pot.name:pot.id;//"mv-" + (pot.name?pot.name:pot.id);
            if (!gotPots) {
              pots.push({name: dataKey, colour: colours[coloursIdx]});
              coloursIdx++;
              if (coloursIdx>=colours.length) {
                coloursIdx=0;
              }
            }
            console.log(pot.id, Number(pot.id)-1);
            rec[dataKey] = day.pots[Number(pot.id)-1];
          }
        });
        gotPots = true;
        cd.push(rec);
      });
    } else if (potDataType=="normalised1") {
      nrmSensorData.days.forEach(function (day, index) {
        let rec = {idx: index, time: day.timestamp, tmp: day.temperature, reftmp: day.reftemperature, pressure: day.pressure};
        currentSite.content.pots.forEach((pot,idx) => {
          if (pot.selected) {
            let dataKey = pot.name?pot.name:pot.id;//"mv-" + (pot.name?pot.name:pot.id);
            if (!gotPots) {
              pots.push({name: dataKey, colour: colours[coloursIdx]});
              coloursIdx++;
              if (coloursIdx>=colours.length) {
                coloursIdx=0;
              }
            }
            console.log(pot.id, Number(pot.id)-1);
            rec[dataKey] = day.pots[Number(pot.id)-1].normalised1;
          }
        });
        gotPots = true;
        cd.push(rec);
      });
    } else if (potDataType=="normalised2") {
      nrmSensorData.days.forEach(function (day, index) {
        let rec = {idx: index, time: day.timestamp, tmp: day.temperature, reftmp: day.reftemperature, pressure: day.pressure};
        currentSite.content.pots.forEach((pot,idx) => {
          if (pot.selected) {
            let dataKey = pot.name?pot.name:pot.id;//"mv-" + (pot.name?pot.name:pot.id);
            if (!gotPots) {
              pots.push({name: dataKey, colour: colours[coloursIdx]});
              coloursIdx++;
              if (coloursIdx>=colours.length) {
                coloursIdx=0;
              }
            }
            console.log(pot.id, Number(pot.id)-1);
            rec[dataKey] = day.pots[Number(pot.id)-1].normalised2;
          }
        });
        gotPots = true;
        cd.push(rec);
      });
    } else if (potDataType=="filtered") {
      fltSensorData.days.forEach(function (day, index) {
        let rec = {idx: index, time: day.timestamp, tmp: day.temperature, reftmp: day.reftemperature, pressure: day.pressure};
        currentSite.content.pots.forEach((pot,idx) => {
          if (pot.selected) {
            let dataKey = pot.name?pot.name:pot.id;//"mv-" + (pot.name?pot.name:pot.id);
            if (!gotPots) {
              pots.push({name: dataKey, colour: colours[coloursIdx]});
              coloursIdx++;
              if (coloursIdx>=colours.length) {
                coloursIdx=0;
              }
            }
            console.log(pot.id, Number(pot.id)-1);
            rec[dataKey] = day.pots[Number(pot.id)-1];
          }
        });
        gotPots = true;
        cd.push(rec);
      });
    }




    //console.log("_________________");
    //console.log(pots);
    //console.log(cd);
    //console.log("_________________");

        let rowStr = "timestamp,temperature,ref temperature";
        pots.map((pot) => {
          rowStr += "," + pot.name;
        })
        let rows = rowStr + '\r';
        cd.map((row) => {
          rowStr = row.time + "," + row.tmp + "," + row.reftmp;
          pots.map((pot) => {
            rowStr += "," + row[pot.name];
          })
          rowStr += '\r';
          rows += rowStr;
        })



        let datazip = new JSZip();
        var folder = datazip.folder(currentSite.content.name);
        folder.file(potDataType + "_data.csv", rows);
        saveDataZip(datazip);
      }

      function saveDataZip(zip) {
        const options = {
        dateStyle: "short"
      };
        var title = 'SPiVolt_' + (new Date()).toLocaleDateString(options);
        zip.generateAsync({type:"blob"})
        .then(function(content) {
            FileSaver.saveAs(content, title + ".zip");
            setIsDownloading(false);
        });

      }

      //////////////////////////////////////////////////////////////////////




















function renderDateInput( props, openCalendar, closeCalendar ) {
    return (
            <Chip label={"Reference date: " + props.value} color="secondary" variant="outlined" size="small" deleteIcon={<CalendarMonthIcon />} onDelete={openCalendar}/>
    );
}
function renderStartAllDataDateInput( props, openCalendar, closeCalendar ) {
  //console.log(props);
    return (
            <Chip label={props.value} color="secondary" variant="outlined" size="small" deleteIcon={<CalendarMonthIcon />} onDelete={openCalendar}/>
    );
}
function renderEndAllDataDateInput( props, openCalendar, closeCalendar ) {
  //console.log(props);
    return (
            <Chip label={props.value} color="secondary" variant="outlined" size="small" deleteIcon={<CalendarMonthIcon />} onDelete={openCalendar}/>
    );
}





function renderLander() {
  return (
    <div  className={"lander"}>
    <Box
    position="relative"
    display="flex"
    flexDirection="row"
    width="100%"

    >
    <Box width="auto" bgcolor="#0cb9c211"  style={{ padding: 8, borderRight: '4px solid #0cb9c2'}}>
    <Box p={1.5} width="auto" bgcolor="#f0c239" flexDirection="column" display="flex" alignItems='center'>
    <b>{currentSite?currentSite.content.name.toUpperCase():''}</b>
    </Box>
    <Box minWidth="380px" overflow="auto" maxHeight={height-120} flex={1} flexDirection="column" display="flex" style={{
      overflow: "hidden",
      overflowY: "scroll" // added scroll
    }}>
    <Box
    display='flex'
    flexWrap = 'nowrap'
    flexDirection='row'
    justifyContent='flex-start'
    alignItems='center'
    alignContent='flex-end'
    >
    <Checkbox

    onChange={handleTogglePotOverlay}
    checked={potsActive}
    ref={checkboxRef}
    />
    <Box my={1} mx={-1}>
    SP Sensors
    </Box></Box>
    <Box mx={2}>
    <img src={potIcon} width="25px" height="25px"/>
    <img src={refIcon} width="25px" height="25px"/>
    </Box>
{potsActive && sensorData && sensorData.length>0?
  <>
    <Box mx={2} >
    {/*}<Paper elevation={0} >*/}
Data range:

    <Box flexDirection="row" display="flex"     alignItems='center'>
{potDataType=="filtered"?
<>
<Box py={1}>
<Chip label={getFormattedDate(fltSensorData.days[fltSensorData.days.length-1].timestamp)}  variant="outlined" size="small" />
</Box>
<Box mx={0.5} >
-
</Box>
<Chip label={getFormattedDate(fltSensorData.days[0].timestamp)} variant="outlined" size="small" />

</>
:
<>
    <Box py={1}>
    <Datetime

      input={ true }
    closeOnClickOutside={true}
    closeOnSelect={true}
    timeFormat={false}
    renderInput={renderStartAllDataDateInput}
    onChange={handleStartAllDataDatePickerChange}
    value={startAllDataRefDate}
    isValidDate={isStartAllDataDateValid}
    initialViewMode="days"
    dateFormat="DD-MM-YYYY"


    />
    {/*renderView={(mode, renderDefault) => renderCalenderView(mode, renderDefault)}*/}

    </Box>
    <Box mx={0.5} >
    -
    </Box>
    <Datetime
    //open={false}

      input={ true }
    closeOnClickOutside={true}
    closeOnSelect={true}
    timeFormat={false}
    renderInput={renderEndAllDataDateInput}
    onChange={handleEndAllDataDatePickerChange}
    value={endAllDataRefDate}
    isValidDate={isEndAllDataDateValid}
    initialViewMode="days"
    dateFormat="DD-MM-YYYY"
    />
    </>
  }
    </Box>
    {/*</Paper>*/}
    </Box>
    </>
  :<></>}

{potsActive && avgSensorData && nrmSensorData?
  <>
    <Box mx={2} my={2} width="100%">


    <FormControl width="100%" size="small" variant="outlined">
      <InputLabel id="demo-select-small">Data Type</InputLabel>
      <Select
        labelId="demo-select-small"
        id="demo-select-small"
        value={potDataType}
        label="Data Type:"
        onChange={handlePotDataTypeChange}
      >
        <MenuItem value={"raw"}>Raw SP Data</MenuItem>
        <MenuItem value={"averaged"}>Averaged</MenuItem>
        <MenuItem value={"normalised1"}>Normalised v1</MenuItem>
        <MenuItem value={"normalised2"}>Normalised v2</MenuItem>
        <MenuItem disabled={!fltSensorData} value={"filtered"}>Filtered&nbsp;{!fltSensorData && <BsArrowRepeat className="spinning"/>}</MenuItem>
      </Select>
    </FormControl>
    <IconButton  align="right" aria-label="download" size="large" onClick={(event) => handleDownloadClick(event)}><CloudDownloadIcon fontSize="inherit" /></IconButton>

</Box>







{potDataType!="raw"?
<Box mx={2} mb={2}>
<Paper >

{nrmSensorData && potDataType=="normalised2" &&
<>
<Box mx={3} pt={2}>
 <Datetime
 //open={false}
   input={ true }
 closeOnClickOutside={true}
 closeOnSelect={true}
 timeFormat={false}
 renderInput={renderDateInput}
 onChange={handleDatePickerChange}
 value={refDate}
 isValidDate={isDateValid}
 initialViewMode="days"
 dateFormat="DD-MM-YYYY"
 />
</Box>
<Box my={1}> <Divider/> </Box>
</>
}

{(potDataType=="averaged" || potDataType=="filtered") &&
<>
  <Box mx={5} pt={1} mb={-1}>
<Slider
    value={tempCutoffs}
    onChange={handleCutoffsChange}
    onChangeCommitted={handleCutoffsChangeCommitted}
    disableSwap
  />
  </Box>
  <Box mx={2} mb={-1}>
  <Grid

  container
  direction="row"
  justifyContent='space-between'
  alignItems='center'
  >
  {potDataType=="averaged"?
        <>
        <Grid item  key={0}>

        {(((avgSensorData.max-avgSensorData.min)*tempCutoffs[0]/100)+avgSensorData.min).toFixed(0)}mV
        </Grid>
        <Grid item  key={1}>
                cuttoffs
              </Grid>
              <Grid item  key={2}>
                      {(((avgSensorData.max-avgSensorData.min)*tempCutoffs[1]/100)+avgSensorData.min).toFixed(0)}mV
                  </Grid>
        </>
        :potDataType=="filtered"?
        <>
        <Grid item  key={0}>

        {(((fltSensorData.max-fltSensorData.min)*tempCutoffs[0]/100)+fltSensorData.min).toFixed(0)}mV
        </Grid>
        <Grid item  key={1}>
                cuttoffs
              </Grid>
              <Grid item  key={2}>
                      {(((fltSensorData.max-fltSensorData.min)*tempCutoffs[1]/100)+fltSensorData.min).toFixed(0)}mV
                  </Grid>
        </>
        :
        <>
        <Grid item  key={0}>

        {potDataType=="normalised2"?nrmSensorData.min2.toFixed(2):nrmSensorData.days[currentDataDay].min[potDataType].toFixed(2)}
        </Grid>
        <Grid item  key={1}>
                {potDataType=="normalised2"?nrmSensorData.max2.toFixed(2):nrmSensorData.days[currentDataDay].max[potDataType].toFixed(2)}
              </Grid>
        </>
  }

  </Grid>
  </Box>
  </>
}


     <Box mx={5} pt={1}>

{potDataType=="averaged" || potDataType=="filtered"?

<Box  style={{ background: "linear-gradient(to right," + rainbowColours.toString() + ")" }} display="flex" alignItems='center' flexDirection="row" flexWrap = 'nowrap' height={10} width="100%"/>

:
<Box  style={{ background: "linear-gradient(to right, #f00, #fff,#1e90ff)" }} display="flex" alignItems='center' flexDirection="row" flexWrap = 'nowrap' height={10} width="100%"/>
}

</Box>
<Box mx={2} pb={1}>
<Grid

container
direction="row"
justifyContent='space-between'
alignItems='center'
>
{potDataType=="averaged"?
<>
<Grid item  key={0}>
{(Math.sign(avgSensorData.min) * Math.round(Math.abs(avgSensorData.min))).toFixed(0)}mV
</Grid>
<Grid item  key={1}>
        min - max
      </Grid>

<Grid item  key={2}>
        {(Math.sign(avgSensorData.max) * Math.round(Math.abs(avgSensorData.max))).toFixed(0)}mV
      </Grid>
</>
:potDataType=="filtered"?
<>
<Grid item  key={0}>
{(Math.sign(fltSensorData.min) * Math.round(Math.abs(fltSensorData.min))).toFixed(0)}mV
</Grid>
<Grid item  key={1}>
        min - max
      </Grid>

<Grid item  key={2}>
        {(Math.sign(fltSensorData.max) * Math.round(Math.abs(fltSensorData.max))).toFixed(0)}mV
      </Grid>
</>
:
<>
<Grid item  key={0}>

{potDataType=="normalised2"?nrmSensorData.min2.toFixed(2):nrmSensorData.days[currentDataDay].min[potDataType].toFixed(2)}
</Grid>
<Grid item  key={1}>
        {potDataType=="normalised2"?nrmSensorData.max2.toFixed(2):nrmSensorData.days[currentDataDay].max[potDataType].toFixed(2)}
      </Grid>
</>
}

</Grid>
</Box>
<Box mx={2}>
  Date : {dataDayLabelFormat(potDataType=="filtered"?fltSensorData.days:avgSensorData.days)}
<Slider
min={0}
step={1}
max={potDataType=="filtered"?fltSensorData.days.length-1:avgSensorData.days.length-1}

size="small"
disabled={false}
onChange={handleCurrentDataDayChange}
value={currentDataDay}
/>
</Box>
</Paper >
</Box>:<></>}










<Box mx={2} my={0.5}>
Mode: <b>{
potSelectState=="chartchainageselect"?"Chainage charting":
potSelectState=="charttimeselect"?"Time series charting":
potSelectState=="disableselect"?"Disable sensor":
potSelectState=="singleselect"?"Single selector":
potSelectState=="drawselect"?"Polygon selector":""
}</b></Box>

<Box
position="relative"
display="flex"
flexDirection="row"
width="100%"
mx={2} mb={1}

>
<Box  >
<ButtonGroup size="small" >

<Button
        variant="contained"
        color={potSelectState=="charttimeselect"?"primary":""}
        size="small"
        onClick={(event) => handleSelectButtonClick("charttimeselect")}
        startIcon={<TimeChartRoundedIcon />}
      />
      <Button
              variant="contained"
              color={potSelectState=="chartchainageselect"?"primary":""}
              size="small"
              onClick={(event) => handleSelectButtonClick("chartchainageselect")}
              startIcon={<ChainageChartRoundedIcon />}
            />

      <Button

              variant="contained"
              color={potSelectState=="singleselect"?"primary":""}
              size="small"
              onClick={(event) => handleSelectButtonClick("singleselect")}
              startIcon={<SelectRoundedIcon />}
            />
            <Button

                    variant="contained"
                    color={potSelectState=="drawselect"?"primary":""}

                    size="small"
                    onClick={(event) => handleSelectButtonClick("drawselect")}
                    startIcon={<DrawRoundedIcon />}
                  />
                  <Button
                          variant="contained"
                          color={potSelectState=="disableselect"?"primary":""}
                          size="small"
                          onClick={(event) => handleSelectButtonClick("disableselect")}
                          startIcon={<BlockRoundedIcon />}
                        />

      </ButtonGroup>
      </Box>
      <Box ml={1}>
      <ButtonGroup size="small" >
      <Button

              variant="contained"
              color="primary"
              size="small"
              onClick={(event) => handleSelectButtonClick("clearselect")}
              startIcon={<ClearRoundedIcon />}
            ></Button>

            <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={(event) => handleSelectButtonClick("allselect")}
                    startIcon={<AllRoundedIcon />}
                  ></Button>
</ButtonGroup>

</Box>


</Box>

</>:<></>}

    <Box my={1}> <Divider/> </Box>



    {overlays && overlays.map((overlay, i) => {
      return (
        <>
        <Box
        display='flex'
        flexWrap = 'nowrap'
        flexDirection='row'
        justifyContent='flex-start'
        alignItems='center'
        alignContent='flex-end'
        >
        <Checkbox
        disabled={overlay.content.overlayType=="satsense" && !satsensePoints}
        onChange={handleToggleOverlay(overlay, i)}//handleToggleOverlay(i)
        checked={overlay.isActive}//checked.indexOf(i) !== -1

        />
        <Box my={1} ml={-1} mr={0}>
        {overlay.content.groupName?overlay.content.groupName:overlay.content.overlayType=="satsense"?<img src={satsenseLogo} height="20px"/>:overlay.content.name}
        </Box></Box>
        <Box mx={1}>
        {overlay.content.overlayType=="image"?
        <img src={overlay.base64data}/>
        :overlay.content.overlayType=="line"?
        <Box display="flex" alignItems='center' flexDirection="row" flexWrap = 'nowrap' width="100%"><Box width="100%" height={lineWidth[0][0]} bgcolor={lineColour[0]}/><IconButton id={"line-0"} name="line-0" align="right" aria-label="delete" size="small" onClick={(e) => {handleSPLineOptionsClick(e,0)}}><SettingsIcon fontSize="inherit" /></IconButton></Box>
        :overlay.content.overlayType=="line-figure"?
        <Box display="flex" alignItems='center' flexDirection="row" flexWrap = 'nowrap' width="100%"><Box sx={{ borderTop: lineWidth[1][0] + 'px dashed ' + lineColour[1] }}  width="100%" height={lineWidth[1][0]} /><IconButton id={"line-1"}  name="line-1"  align="right" aria-label="delete" size="small" onClick={(e) => {handleSPLineOptionsClick(e,1)}}><SettingsIcon fontSize="inherit" /></IconButton></Box>
        :overlay.content.overlayType=="satsense" && satsensePoints?
        <>
        <Box mx={1} display="flex" alignItems='center' flexDirection="row" flexWrap = 'nowrap' width="100%">
        <svg height="10" width="12"><circle cx="5" cy="5" r="4" stroke="black" stroke-width="1" fill="white" /></svg>
        <svg height="18" width="14"><circle cx="5" cy="5" r="4" stroke="black" stroke-width="1" fill="white" /></svg>
        <svg height="22" width="15"><circle cx="5" cy="5" r="4" stroke="black" stroke-width="1" fill="white" /></svg>
        <svg height="10" width="13"><circle cx="5" cy="5" r="4" stroke="black" stroke-width="1" fill="white" /></svg>
        <svg height="16" width="16"><circle cx="5" cy="5" r="4" stroke="black" stroke-width="1" fill="white" /></svg>
        </Box>
        {overlay.isActive?
          <>
<Box mx={1}>
        <FormControl disabled={!overlay.isActive} visible={!overlay.isActive}>
           <RadioGroup

           value={satsenseDirection}
           onChange={handleSatsenseDirectionChange}
             row
             aria-labelledby="demo-row-radio-buttons-group-label"
             name="row-radio-buttons-group"
           >
             <FormControlLabel value="A" control={<Radio size="small"/>} label="Ascending" />
             <FormControlLabel value="D" control={<Radio size="small"/>} label="Descending" />
           </RadioGroup>
         </FormControl>
         </Box>
         <Box mx={5}>
<Paper >
<Box  style={{ background: "linear-gradient(to right, #f00, #fff,#1e90ff)" }} display="flex" alignItems='center' flexDirection="row" flexWrap = 'nowrap' height={10} width="100%"/>
</Paper>
</Box>
<Box mx={2}>
<Grid

  container
  direction="row"
  justifyContent='space-between'
  alignItems='center'
>
<Grid item  key={0}>
   {velocity.min.toFixed(1)}mm/yr
 </Grid>
 <Grid item  key={1}>
            {velocity.max.toFixed(1)}mm/yr
          </Grid>



</Grid>
</Box></>
:<></>}

        </>
        :<>loading...</>}
        </Box>
        <Box pr={2} pl={1} py={1}>
        <Slider
        min={0}
        step={0.01}
        max={1}

        size="small"
        disabled={!overlay.isActive}
        onChange={(event, newVal) => handleOpacityOverlayChange(newVal, overlay, i)}
        value={opacity[i]}
        valueLabelDisplay="auto"
        valueLabelFormat={valueLabelFormat}
        />
        </Box>
        <Box my={1}>
        {i<overlays.length-1?<Divider/>:null}
        </Box>
        </>
      )})}

      </Box></Box>

      {isLoaded?

        <GoogleMap
        onMouseMove={onMouseMove}
        onClick={onMapClick}

        mapContainerStyle={containerStyle}
        onLoad={onMapLoad}
        onUnmount={onUnmount}
        onZoomChanged={onZoomChangedHandler}
        options = {mapOptions}

        >
        <>



        {overlays && overlays.map((overlay, i) =>
          overlay.isActive && overlay.content.overlayType=="image" && showPots?
          <GroundOverlay

          options={{
            clickable: false,
          }}

          key={imageUrls[i]}
          url={imageUrls[i]}
          bounds={new window.google.maps.LatLngBounds(
            new window.google.maps.LatLng(overlay.content.bounds.south, overlay.content.bounds.west),
            new window.google.maps.LatLng(overlay.content.bounds.north, overlay.content.bounds.east)
          )}
          opacity={opacity[i]}

          />
          :overlay.isActive && overlay.content.overlayType=="satsense" && showPots?
          satsensePoints.points.map((point, idx) =>
          <>
          {point.direction == satsenseDirection && point.selected?
            <Marker  key={idx + 20000} icon={{path: svgDot, fillColor: point.colour, fillOpacity: opacity[i], strokeOpacity: opacity[i], scale: 1.6, anchor: {x: 4.5, y: 4.5}}} position={{lat:point.coordinates[1], lng:point.coordinates[0]}} title={"velocity: " + point.velocity}/>
            :<></>}
{point.direction == satsenseDirection?
  <>
            <Marker zIndex={20000 + (idx*2)} key={idx + 30000} icon={{path: svgDot, fillColor: "#fff", scale: 0.8, anchor: {x: 4, y: 4}, fillOpacity: opacity[i], strokeOpacity: opacity[i]}} position={{lat:point.coordinates[1], lng:point.coordinates[0]}}/>
            <Marker zIndex={20000 + (idx*2) + 1} onClick={() => onToggleOpenSatsensePoint(idx, idx + 10000)} key={idx + 10000} icon={{path: svgDot, fillColor: point.velocityColour, scale: 0.8, anchor: {x: 4, y: 4}, fillOpacity: opacity[i], strokeOpacity: opacity[i]}} position={{lat:point.coordinates[1], lng:point.coordinates[0]}} title={"velocity: " + point.velocity}/>
</>:<></>}
            </>

          )

          :null
        )}






        {!showPots && sites && sites.map((site, i) =>

          site.content.location?<Marker onClick={() => onToggleOpen(i)} key={i} icon={{url: pinIcon, size: {width: 40, height: 40}, origin: {x: 0, y: 0}, anchor: {x: 20, y: 35}, scaledSize: {width: 40, height: 40}}} position={site.content.location} title={site.content.name}/>:<></>
        )}

        {potsActive && currentSite && currentSite.content.pots.map((pot, i) =>

          pot.location?
          <>
          {pot.selected && !pot.disabled && potDataType!="raw"?
            <Marker zIndex={40000 + (i*2)} visible={showPots} key={i+sites.length+1000}  icon={{path: svgDot, fillColor: "#fff", scale: 2, anchor: {x: 0.1, y: 0.1}, fillOpacity: 1, strokeOpacity: 1}} position={pot.location} />
:
potDataType!="raw"?
          <Marker zIndex={40000 + (i*2)} visible={showPots} key={i+sites.length+1000}  icon={{path: svgDot, fillColor: "#fff", scale: 1.5, anchor: {x: -1.4, y: -1.4}, fillOpacity: 1, strokeOpacity: 1}} position={pot.location} />
:<></>

}

<Marker zIndex={40000 + (i*2) + 1} visible={showPots} onClick={() => onToggleOpenPot(pot.id, i+sites.length, pot.name, i)} key={i+sites.length}  icon={potDataType!="raw"?{path: svgDot, fillColor: getColour(pot.id), scale: 1.5, anchor: {x: -1.4, y: -1.5}, fillOpacity: 1, strokeOpacity: 1}:{url: pot.disabled?disabledpotIcon:pot.id==0?refIcon:pot.selected?potselIcon:potIcon, size: {width: 20, height: 20}, origin: {x: 0, y: 0}, anchor: {x: 0, y: 0}, scaledSize: {width: 20, height: 20}}} position={pot.location} title={pot.id==0?"Reference Pot":"Pot " + (pot.name?pot.name:pot.id)}/>

{/*}<Marker zIndex={10000 + (i*2) + 1} visible={showPots} onClick={() => onToggleOpenPot(pot.id, i+sites.length, pot.name, i)} key={i+sites.length}  icon={{url: disabledpotIcon, size: {width: 20, height: 20}, origin: {x: 0, y: 0}, anchor: {x: 0, y: 0}, scaledSize: {width: 20, height: 20}}} position={pot.location} title={pot.id==0?"Reference Pot":"Pot " + (pot.name?pot.name:pot.id)}/>*/}
          </>
          :<></>
        )}

{/*
        {newPots && newPots.map((pot, i) =>

          <Marker   key={i+1000}  icon={{url: pot.id==0?refIcon:potIcon, size: {width: 40, height: 40}, origin: {x: 0, y: 0}, anchor: {x: 0, y: 0}, scaledSize: {width: 10, height: 10}}} position={pot.location} title={pot.id==0?"Reference Pot":"Pot " + pot.id}/>
        )}
*/}

        {spOverlayIdx>-1 &&
          <Polyline
          path={getLineCoords(overlays[spOverlayIdx])}
          onMouseOver={() => {lineWidth[0][1] += 2;setLineWidth([...lineWidth]);}}
          onMouseOut={() => {lineWidth[0][1] -= 2;setLineWidth([...lineWidth]);}}
          geodesic={true}
          visible={showPots}
          onClick={() => onToggleOpenStaticSP()}
          options={{
            strokeColor: lineColour[0],
            strokeOpacity: opacity[spOverlayIdx],
            strokeWeight: lineWidth[0][1],
            icons: [{
              offset: "0",
              repeat: "20px"
            }]
          }}
          />
        }
        {glOverlayIdx>-1 && overlays[glOverlayIdx].overlays.map((overlay, i) =>

          <Polyline
          key={overlay.name}
          path={overlay.content.coords/*getGroupLineCoords(overlay)*/}
          geodesic={true}
          onMouseOver={() => {lineWidth[1][i+1] += 2;setLineWidth([...lineWidth]);}}
          onMouseOut={() => {lineWidth[1][i+1] -= 2;setLineWidth([...lineWidth]);}}
          visible={showPots}
          onClick={() => onToggleOpenERD(i)}
          options={{
            strokeColor: lineColour[1],
            strokeOpacity: 0,
            strokeWeight: lineWidth[1][i+1],
            icons: [{
              icon: {path: "M 0,-1 0,1", strokeOpacity: opacity[glOverlayIdx],},
              offset: "0",
              repeat: (4 * lineWidth[1][i+1]) + "px"
            }]
          }}
          />

        )}


        {infoOpen && overlayValues.length>0?
          <InfoBox
          position={new window.google.maps.LatLng(currentLocation.lat, currentLocation.lng)}
          options={{ closeBoxURL: ``, enableEventPropagation: true, pixelOffset:new window.google.maps.Size(5,25) }}
          >
          <Paper elevation={3} >
          <Box  mx={1} mt={1}
          noValidate
          component="form"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            m: 'auto',
            width: 'fit-content',}}
            >
            {overlayValues.map((item, i) => {
              return (
                <Typography noWrap variant="caption" color="secondary"><b>{item.title}:</b> {item.value}</Typography>
              )}
            )}
            </Box>
            <Divider />
            <Box  mx={1}  mb={1}>
            <Typography noWrap variant="caption" color="primary">{currentLocation.lat.toFixed(10)}, {currentLocation.lng.toFixed(10)}</Typography>
            </Box>
            </Paper>
            </InfoBox>

            : <></>}

            </>



            <DrawingManager
            onLoad={onDMLoad}
 onPolygonComplete={onPolygonComplete}

options = {{"drawingControl": false, "polygonOptions":{"editable": false}}}
drawingMode = {potSelectState=="drawselect"?"polygon":null}
    />

            </GoogleMap>
            : <></>
          }
          </Box>
          </div>
        )
      }
/*
      function renderCalenderView(mode, renderDefault) {

          return (
            <Dialog
            open={fromDateOpen}
            fullWidth={false}
            maxWidth = {'md'}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            >
            <DialogTitle id="alert-dialog-slide-title" style={{color:"#10385A"}} >
            {""}
            </DialogTitle>
            <DialogContent>
            {renderDefault()}
            </DialogContent>
            <DialogActions>
            <Button onClick={handleAlertClose} color="secondary">
            Ok
            </Button>
            </DialogActions>
            </Dialog>
          );
        }
*/

      function renderLoader() {
        return (
          <div id="splash" style={{paddingTop: 100, zIndex:999}}>
          <center id="splash">
          <img src={spinner} height="30px" width="auto"/>
          </center>
          </div>
        );
      }

      return (
        <div className="Map">
        {isPageLoading?renderLoader():renderLander()}

        <Dialog
        fullWidth={true}
        maxWidth = {'mx'}
        open={chainageChartOpen || potChartOpen || multiPotChartOpen || staticSpChartOpen || satsenseChartOpen}
        >
        <DialogTitle>{chartTitle}</DialogTitle>
        <DialogContent>
        <DialogContentText>

        </DialogContentText>

        {potChartOpen?
            <PotChart chartData={chartData}/>
          :multiPotChartOpen?
            <MultiPotChart chartData={chartData}/>
          :staticSpChartOpen?
            <StaticSPChart chartData={chartData}/>
          :satsenseChartOpen?
            <SatsenseChart chartData={chartData}/>
          :chainageChartOpen?
            <ChainageChart chartData={chartData} dateIndex={currentChartDayIndex}/>
          :<></>}

          {chainageChartOpen?
            <Box mx={30} mt={2}>
            <Paper  elevation={3}>
            <Box p={2}>
            Date: {rawDataChartDayLabelFormat()}
            <Slider
            min={0}
            step={1}
            max={chartData.data.length-1}
            marks

            disabled={false}
            onChange={handleCurrentChartDayIndexChange}
            value={currentChartDayIndex}
            />
            </Box>
            </Paper>
            </Box>
            :<></>
          }
          </DialogContent>
          <DialogActions>
          <Button onClick={handleClosePotChart}>Close</Button>
          </DialogActions>
          </Dialog>



          <Dialog
          fullWidth={false}
          maxWidth = {'sx'}
          open={figureOpen>-1}
          >
          <DialogTitle>{currentSite?currentSite.content.name:''}: <b>{figureOpen>-1?overlays[glOverlayIdx].overlays[figureOpen].content.name:""}</b></DialogTitle>
          <DialogContent>
          <DialogContentText>

          </DialogContentText>
          {glImages[figureOpen]?
          
           

            <Box
        component="img"
        maxWidth = "100%"
        alt="SpiVolt"
        src={glImages[figureOpen]}
      />
            :
            <center>Loading...</center>}

            </DialogContent>
            <DialogActions>
            <Button onClick={handleCloseFigure}>Close</Button>
            </DialogActions>
            </Dialog>



            <Dialog
            open={settingsOpen>=0}
            fullWidth={false}
            maxWidth = {'md'}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            >
            <DialogTitle id="alert-dialog-slide-title" style={{color:"#10385A"}} >
            {settingsOpen==0?"Static SP":"ERTs"}: line settings
            </DialogTitle>
            <DialogContent>
            <Box
            noValidate
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
              width: 'fit-content',
            }}
            >
            <InputLabel htmlFor="max-width">colour</InputLabel>
            <CirclePicker color={ lineColour[settingsOpen] } onChangeComplete={ handleChangeLineColourComplete }/>
            <br/>
            <InputLabel htmlFor="max-width">width</InputLabel>
            <Select
            autoFocus
            value={lineWidth[settingsOpen]?lineWidth[settingsOpen][0]:0}
            onChange={handleLineWidthChange}
            label="lineWidth"
            >
            <MenuItem value={1.1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={6}>6</MenuItem>
            </Select>
            </Box>


            </DialogContent>
            <DialogActions>
            <Button onClick={handleSettingsClose} color="secondary">
            Done
            </Button>
            </DialogActions>
            </Dialog>





            <Dialog
            open={alertOpen}
            fullWidth={false}
            maxWidth = {'md'}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            >
            <DialogTitle id="alert-dialog-slide-title" style={{color:"#10385A"}} >
            {alertVals.title}
            </DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
            {alertVals.message}
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleAlertClose} color="secondary">
            Ok
            </Button>
            </DialogActions>
            </Dialog>
            </div>
          );
        }
