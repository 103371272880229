import React, { useState, useEffect } from "react";
import "./Thinking.css";

import { useAppContext } from "../libs/contextLib";
import { useHistory } from "react-router-dom";
import image from'../images/thinking.jpg';
import iCampra from'../images/campra/Campra Accredited - Green.svg';

import TextField from '@material-ui/core/TextField';
import LoaderButtonX from "../components/LoaderButtonX";
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import { Link } from 'react-router-dom';


import { makeStyles, withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';

const FooterTypography = withStyles({
  root: {
    color: "#fff"
  }
})(Typography);



export default function Thinking() {
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const useViewport = () => {
    const [width, setWidth] = React.useState(window.innerWidth);

    React.useEffect(() => {
      const handleWindowResize = () => setWidth(window.innerWidth);
      window.addEventListener("resize", handleWindowResize);
      return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    // Return the width so we can use it in our components
    return { width };
  }

  const { width } = useViewport();
  const breakpoint = 600;












function handleClick(event) {
  event.preventDefault();
  history.push("/signup");
}

function handleContactClick(event) {
  event.preventDefault();
  history.push("/contact", { from: 'thinking' } );
}




  function renderLander() {
    return (
      <>


      <img src={image} alt="AireStop" width="100%" height="auto"/>
<div className={width>breakpoint?"lander":"mobile-lander"} >
<h2 ><span classname="titlespan" style={{color:"#10385A", fontFamily: 'Comfortaa', fontWeight: 900}}>Interested in becoming</span> <span style={{color:"#F18C7E", fontFamily: 'Comfortaa', fontWeight: 900}}>a Host?</span></h2>
<br/>


      <p><b>AireStops</b> come in all different shapes and sizes. Some are located in the rural countryside and others in the urban metropolis. Some are fully serviced with a range of luxuries, where others are little more than a flat area away from the road. What links all AireStops together is the safe sanctuary they offer Motoroamers on their travels.<br/><br/>

      As a host of your own AireStop you are completely in control. You can choose when your AireStop is open, what facilities you wish to offer and set the rules that you would like your guests to abide by. You will also set the fee you would like your guests to pay based on the facilities you have on offer and your location. To improve access for all guests we provide for a two tier fee system; one fee for staying the night (including facility use) and one fee for just using facilities. If your AireStop only offers facility use or overnight parking, this is no problem, you will simply charge for the services you offer.<br/><br/>
      We have learned the facilities that Guests look for the most often are drinking water and black water waste disposal. For this reason we recommend to Hosts to prioritise these facilities when making infrastructure investments.<br/><br/>

      <b>Pricing</b><br/>
      Our pricing system is straightforward and transparent. There are no hidden charges and all fees are taken on a per-transaction basis before the funds are paid to you. That means the money you receive is all yours.<br/><br/>

      <Box display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                width="100%"

      >

      <Box >
      <Paper variant="outlined">
      <Box p={1}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid style={{borderLeft:"1px solid grey",borderRight:"1px solid grey", borderTop:"1px solid grey"}} item xs={6}>
          <Box ml={1}><b>Payment</b></Box>
        </Grid>
        <Grid style={{borderRight:"1px solid grey", borderTop:"1px solid grey"}} item xs={6}>
          <Box ml={1}><b>Fee</b></Box>
        </Grid>
        <Grid style={{border:"1px solid grey"}} item xs={6}>
          <Box ml={1}>£10 or less</Box>
        </Grid>
        <Grid style={{borderRight:"1px solid grey",borderBottom:"1px solid grey", borderTop:"1px solid grey"}} item xs={6}>
          <Box ml={1}>£0.90</Box>
        </Grid>
        <Grid style={{borderLeft:"1px solid grey",borderRight:"1px solid grey", borderBottom:"1px solid grey"}} item xs={6}>
          <Box ml={1}>Over £10</Box>
        </Grid>
        <Grid style={{borderRight:"1px solid grey", borderBottom:"1px solid grey"}} item xs={6}>
          <Box ml={1}>9%</Box>
        </Grid>
      </Grid>
      </Box>
      </Paper>
</Box></Box>


<br/><br/>

      To ensure maximum security and convenience for our users we have chosen to partner with world-leading payment provider Stripe. This means when you register you become an AireStop host you will also automatically be set up with a Stripe account. Once your Stripe account has been fully verified, all payments will be securely transferred to your chosen bank account.<br/><br/>

      It is your responsibility to ensure you have all the necessary permissions and cover to open an AireStop before you start accepting guests. There are many ways to set up an Aire and our friends at CAMpRA have lots of helpful information.<br/><br/>

      <b>How it works</b><br/>
      Click on the button below to start the registration process. It usually takes around 10-15 minutes to complete the registration and you will be guided along the way.  You can start collecting payments as soon as you are registered.<br/><br/>

      For a step-by-step guide to setting up your AireStop Profile visit our ‘How to set up my AireStop Profile’ Guide.<br/><br/>

      Once you are registered we will also send you a unique AireStop sticker for your Aire. This should be displayed in a prominent position onsiteAcc so that guests know where to find your information and how to pay when they arrive. In your AireStop Hosts Hub you will also have the option of ordering additional signage for your stop.<br/><br/>

      It is your responsibility to ensure you have all the necessary permissions and cover to open an AireStop before you start accepting guests.</p>




<br/>

<p>View the <a href={"https://airestop.co.uk/docs/registration_guide.pdf"} target="_blank" style={{color:"#F18C7E"}}><u>Registration Guide</u></a> to help you become an AireStop Host.</p>

<br/>

<Box display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width="100%"

>

<Box >
<LoaderButtonX
type="button"
style={{background:"#F18C7E"}}
isLoading={false}
disabled={false}
onClick={handleClick}
>
Become a Host
</LoaderButtonX>
</Box></Box>
<br/><br/>
</div>






<Box
position="sticky"
bottom="0px"
left="0px"
right="0px"
display="flex"
flexDirection="column"
justifyContent="center"
alignItems="center"
width="100%"
bgcolor="#10385A">
<Divider width="100%" style={{ background: '#fff' }}/>
<Box display="flex" p={1} width="100%">
<Box p={0} flexGrow={1}>
<FooterTypography variant="caption">AIRESTOP<sup><small>TM</small></sup> LIMITED<br/> {/* ® */}
Company No. SC707418<br/>
<Link style={{color:"white"}} onClick={handleContactClick} >Contact us</Link>
</FooterTypography>
</Box>
<Box p={0} display="flex" flexDirection="column">
<a style={{color:"white", fontSize:"12px", textAlign:"right"}} href={"https://airestop.co.uk/docs/faqs.pdf"} target="_blank">FAQs</a>
<Box style={{textAlign:"right"}} py={0.5}><a style={{color:"white", fontSize:"12px", textAlign:"right"}} href={"https://airestop.co.uk/docs/privacy.pdf"} target="_blank">Privacy</a></Box>
<a style={{color:"white", fontSize:"12px", textAlign:"right"}} href={"https://airestop.co.uk/docs/tncs.pdf"} target="_blank">Terms of Use</a>
</Box>
</Box>
<Box width="100%" mt={-0.8} pl={1} style={{textAlign:"left"}}><a style={{color:"#ccc", fontSize:"10px", textAlign:"left"}} >©2021 AireStop Limited. All rights reserved.</a></Box>
</Box>









</>




    );




  }




  return (
    <div className="Thinking">
      {renderLander()}
    </div>
  );
}
