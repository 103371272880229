import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";

import "./ListUsers.css";

import { useAppContext } from "../libs/contextLib";
import { useHistory, useParams } from "react-router-dom";
import image from'../images/thinking.jpg';
import iCampra from'../images/campra/Campra Accredited - Green.svg';

import TextField from '@material-ui/core/TextField';
import LoaderButtonX from "../components/LoaderButtonX";
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';



import { Link } from 'react-router-dom';


import { makeStyles, withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';

const FooterTypography = withStyles({
  root: {
    color: "#fff"
  }
})(Typography);


const columns = createCols();

function createCols() {
  let cols = [
    { id: 'name',
      label: 'Name',
      minWidth: 180,
      format: (value) => value.toLocaleString('en-GB'),
    },
    { id: 'email',
      label: 'Email',
      minWidth: 180,
      align: 'center',
      format: (value) => value.toLocaleString('en-GB'),
    },
    { id: 'phone',
      label: 'Phone',
      minWidth: 180,
      align: 'center',
      format: (value) => value?value.toLocaleString('en-GB'):"",
    },
    { id: 'status',
      label: 'Status',
      minWidth: 100,
      align: 'center',
      format: (value) => value.toFixed(0),
    },
  ];
  return cols;
}


export default function ListUsers() {
  const [rows, setRows] = useState([]);
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [data, setData] = useState([]);
  const [type, setType] = useState("orgs");
  const [currentOrg, setCurrentOrg] = useState(null);

useEffect(() => {
  async function onLoad() {
    if (!isAuthenticated) {history.push("/"); return;}
    initForSpivoltId();
  }
  onLoad();
}, []);

function getUsers() {
    return API.get("spivolt", "/org/users");
}

async function initForSpivoltId() {
  try {
    const result = await getUsers();
    console.log(result);
    if (!result.status) {history.push("/"); return;}
    const items = result.items;
    setData(items);
    var newRows = [];
    for (var i = 0; i < items.length; ++i) {
      let sd = items[i];
      const {content, itemStatus} = sd;
      var newRow = {};
      newRow['name'] = content.name;
      newRow['phone'] = content.phone;
      newRow['email'] = content.email;
      newRow['status'] = itemStatus;
      newRows.push(newRow);
    }

    setRows(newRows);
  } catch (e) {
    alert(e);
  }
}



function handleRowClick(event, idx) {
  console.log(data[idx]);
}

function handleAddClick(event) {
    history.push("/users/create/new");
}

  function renderLander() {
    return (
      <div className={"lander"} >
        <h2 style={{color:"#0cb9c2"}}>Admin: <b>Users</b> <Fab size="small"  color="primary" aria-label="add" onClick={handleAddClick}>
        <AddIcon />
      </Fab></h2>
        <Box height="16px"/>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer style={{ maxHeight: `calc(100vh - 210px)` }}>
            <Table stickyHeader aria-label="sticky table" size={'small' }>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, background:"#f0c239", fontWeight:"bold" }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  .map((row, index) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.code} onClick={event => handleRowClick(event, index)} >
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format(value)}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            </TableContainer>
        </Paper>
      </div>
    );
  }

  return (
    <div className="ListUsers">
      {renderLander()}
    </div>
  );
}
