import React, { useState, useEffect } from "react";
import { API, Auth } from "aws-amplify";
import "./Home.css";
//import LoaderButton from "../components/LoaderButton";

import { useAppContext } from "../libs/contextLib";
import { useHistory } from "react-router-dom";
//import bgimage from'../images/large_camper.jpeg';
import campraImage from'../images/large_camper_wide.jpeg';


import { styled } from '@material-ui/core/styles';

import smallRes from'../images/reservoir-small.jpg';


import { s3Download } from "../libs/awsLib";


import spinner from'../images/logo.gif';


import TextField from '@material-ui/core/TextField';
import LoaderButtonX from "../components/LoaderButtonX";
import SiteIdInput from "../components/SiteIdInput";

import { useFormFields } from "../libs/hooksLib";
import { makeStyles, withStyles } from '@material-ui/core/styles';

import Form from "react-bootstrap/Form";
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';


import PhotoLibraryRoundedIcon from '@material-ui/icons/PhotoLibraryRounded';
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';
import FormatListBulletedRoundedIcon from '@material-ui/icons/FormatListBulletedRounded';
import AccountBalanceRoundedIcon from '@material-ui/icons/AccountBalanceRounded';
import FollowTheSignsRoundedIcon from '@material-ui/icons/StorefrontRounded';
//import StorefrontRoundedIcon from '@mui/icons-material/StorefrontRounded';
//import SettingsBackupRestoreRoundedIcon from '@mui/icons-material/SettingsBackupRestoreRounded';
import LocalParkingRoundedIcon from '@material-ui/icons/LocalParkingRounded';
import HowToRegRoundedIcon from '@material-ui/icons/HowToRegRounded';
import RestartAltRoundedIcon from '@material-ui/icons/SettingsBackupRestoreRounded';
//import AddLocationAltRoundedIcon from '@material-ui/icons/AddLocationAltRounded';
import DocIcon from '@material-ui/icons/Description';
import ImageIcon from '@material-ui/icons/Image';
import PdfIcon from '@material-ui/icons/PictureAsPdf';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


import { Link } from 'react-router-dom';



import QrReader from 'react-qr-reader'
import Fab from '@material-ui/core/Fab';

import Divider from '@material-ui/core/Divider';

import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';


const FooterTypography = withStyles({
  root: {
    color: "#fff"
  }
})(Typography);

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function Home() {
  const history = useHistory();
  const { userHasAuthenticated, isAuthenticated, isCampra, setShowSites, setUserName} = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [isLoadingContReg, setIsLoadingContReg] = useState(false);
  const [isLoadingReReg, setIsLoadingReReg] = useState(false);
  const [isLoadingPayments, setIsLoadingPayments] = useState(false);
  const [isCheckingAccount, setIsCheckingAccount] = useState(false);
  const [isScanning, setIsScanning] = useState(false);
  const [scanResult, setScanResult] = useState("");
  const [pauseScan, setPauseScan] = useState(false);
  const [accountId, setAccountId] = useState("");
  const [email, setEmail] = useState("");
  const [requiresAccountDetails, setRequiresAccountDetails] = useState(false);
  const [canResetAccount, setCanResetAccount] = useState(false);
  const [canShowSites, setCanShowSites] = useState(false);
  const [addFirstSite, setAddFirstSite] = useState(false);
  const [orderSigns, setOrderSigns] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertRestart, setAlertRestart] = useState(false);
  const [orgs, setOrgs] = useState({});
  const [fields, handleFieldChange] = useFormFields({
    siteid: "",
    reg: "",
  });
  const [alertVals, handleAlertChange] = useFormFields({
    title: "",
    message: "",
  });
  const [expanded, setExpanded] = React.useState([]);
  const handleExpandClick = (idx) => {
    const curr = expanded[idx];
    let newArr = new Array(data.length).fill(false);
    newArr[idx] = !curr;
    setExpanded(newArr);
  };






  const useViewport = () => {
    const [width, setWidth] = React.useState(window.innerWidth);
    const [height, setHeight] = React.useState(window.innerHeight);
    React.useEffect(() => {
      const handleWindowResize = () => {setWidth(window.innerWidth);setHeight(window.innerHeight);};
      window.addEventListener("resize", handleWindowResize);
      return () => window.removeEventListener("resize", handleWindowResize);
    }, []);
    return { width, height };
  }
  const { width, height } = useViewport();
  const breakpoint = 600;


function getSites() {
  return API.get("spivolt", `/org/sites`);
}

function getOrgs() {
  return API.get("spivolt", `/orgs`);
}




useEffect(() => {

  async function onLoad() {
    if (!isAuthenticated) {history.push("/Login"); return;}
    setIsLoading(true);
    setIsCheckingAccount(true);

    const cui = await Auth.currentUserInfo();
    if (!(cui && cui.attributes)) {
      userHasAuthenticated(false);
      history.push("/");
    } else {
      const resultUser = await createUser({name: cui.attributes.name, phone: cui.attributes.phone_number, email: cui.attributes.email});
      setEmail(resultUser.item.content.email);
      setUserName(resultUser.item.content.name);



      try {
        const result = await getSites();
        const result1 = await getOrgs();
        console.log(result);
        const items = result.items;
        const orgs = result1.items;

        let oo={};
        for(let i=0;i<orgs.length;i++) {
          oo[orgs[i].pk] = {name: orgs[i].content.name, img: orgs[i].base64data}
        }
        setOrgs(oo);



        const expandArray = new Array(items.length).fill(false);
        setExpanded(expandArray);
        setData(items);

        console.log(items);
      } catch (e) {
        console.log(e.message);
      }






    }

    setIsCheckingAccount(false);
setIsLoading(false);
  }

  onLoad();
}, []);

/*
function getAccount(accId) {
  return API.get("aires", `/customers/${accId}`);
}



async function handleContinueRegistrationClick() {
  setIsLoadingContReg(true);
  setIsLoading(true);
  const resultLink = await linkCustomer({id: accountId, url: window.location.protocol + "//" + window.location.host});
  let url = resultLink.accountLinks.url;
  if (url) {
    window.location.href = url;
  }
  return;
  setIsLoading(false);
  setIsLoadingContReg(false);
}



function handleResetAccountClick(e) {
  e.preventDefault();
  setAlertRestart(true);
}

function handleAddFirstSiteClick(e) {
  e.preventDefault();
  history.push("sites/create/new");
}



*/





function handleSiteGalleryClick(siteId) {
  history.push("/gallery/" + siteId);
}

function handleSiteListDataClick(site) {
  //history.push("/sites/" + siteId + "/data");


  history.push({
    pathname: "/sites/" + site.content.id + "/data",
    state: { site: site },
  });


}
function handleSiteMapClick(siteId) {
  history.push("/map/" + siteId);







}



function getSatsenseData(body) {
    return API.post("spivolt", "/satsense", {
      body: body
    });
}


async function handleSatsenseClick(coords) {

  //const satsenseResult = await getSatsenseData(
    //{"type":"Polygon","coordinates":[[[-0.044739246368408196,51.48880642406116],[-0.044739246368408196,51.48970160196874],[-0.04297971725463867,51.48970160196874],[-0.044739246368408196,51.48880642406116]]]}

  //);
  //console.log(satsenseResult);
  if (coords == null) {
    console.log('No satsense data');
  } else {
    const body = {"type":"Polygon","coordinates":[]};
    body.coordinates.push(coords);
    //const data = {type: "POINTS", body: body};
    const data = {type: "DISPLACEMENTS", body: body};
    //const data = {type: "POINT", pointId: "103A_03925_051013_12503_49420"};
    const satsenseResult = await getSatsenseData(data);
    console.log(satsenseResult);
  }




}


async function handleDocLinkClick(id, fileName) {
  const path = `${id}/docs/${fileName}`;
  const url = await s3Download(path);
  window.open(url);
}



/*

async function resetAccount() {
  setIsLoadingReReg(true);
  setIsLoading(true);
  setAlertRestart(false);
  const resultLink = await recreateCustomer({previousAccountId: accountId, email: email, url: window.location.protocol + "//" + window.location.host});
  let url = resultLink.accountLinks.url;
  if (url) {
    window.location.href = url;
  }
  return;
  setIsLoading(false);
  setIsLoadingReReg(false);
}

*/



/*

function validateForm() {
  return !(fields.siteid.length > 0);
}



function handleScanClick(e) {
  setPauseScan(false);
  //setScanResult("CLEARED");
  setIsScanning(true);
}

function handleScanError(e) {
  setIsScanning(false);
  alertVals.title = "QR Code Scanner"
  if (e.code==8) {
    alertVals.message = "Camera not found."
  } else {
    alertVals.message = e.message;
  }
  setAlertOpen(true);
}

function handleScan(data) {
  if (!pauseScan && data) {
    setPauseScan(true);
    const parts=data.split("/");
    if (parts.length==5) {
      if (parts[2].indexOf("airestop.co.uk")>-1) {
        const siteId=parts[parts.length-1];
        openSite(siteId);
        setIsScanning(false);
      }
    }
    setPauseScan(false);
  }
}

function handleSiteClick() {
  const siteId = fields.siteid.trim().toUpperCase();
  openSite(siteId);
}


async function openSite(siteId) {
  if (siteId.length>0) {
    setIsLoading(true);
    const result = await getSite(siteId);
    if (result.status==true) {
      if (isCampra) {
        history.push("/campra/" + siteId);
      } else {
        history.push("/sites/" + siteId);
      }
    } else {
      alertVals.title = "AireStop - Oops!"
      alertVals.message = "Unable to find an AireStop with that code."
      setAlertOpen(true);
    }
    setIsLoading(false);
  }
}

*/


/*


function handleSitesClick() {
  history.push("/sites");
}
function handleSignShopClick() {
  history.push("/shop");
}









async function handleEmailClick() {
  setIsLoading(true);
  let result = await testmail();
  setIsLoading(false);
}


function cancelScanClick() {
  setIsScanning(false);
}


function testmail() {
  return API.post("aires", "/mail", {
    body: {}
  });
}



function getSite(code) {
  return API.get("aires", `/sites/${code}/EXISTS`);
}

*/

// create user if not already exists then returns user
function createUser(body) {
  return API.post("spivolt", "/users", {
    body: body
  });
}

/*
function createCustomer(content) {
  return API.post("aires", "/customers", {
    body: {type: 'create', content: content}
  });
}
function recreateCustomer(content) {
  return API.post("aires", "/customers", {
    body: {type: 'recreate', content: content}
  });
}
function linkCustomer(content) {
  return API.post("aires", "/customers", {
    body: {type: 'link', content: content}
  });
}
*/
function handleAlertClose() {
  setAlertOpen(false);
  setAlertRestart(false);
};

/*
function getDashboardLink() {
  return API.get("aires", `/customers/dashboard`);
}

async function handlePaymentsClick(event) {
  event.preventDefault();
  setIsLoadingPayments(true);
  setIsLoading(true);
  const result = await getDashboardLink();
  if (result && result.status==true) {
    let url = result.link.url;
    if (url) {
      window.location.href = url;
    }
  } else {
    // user not found (unlikely)
    history.push("/");
  }
  setIsLoading(false);
  setIsLoadingPayments(false);
}

const eventhandler = data => {fields.siteid=data.siteid }

function handleContactClick(event) {
  event.preventDefault();
  history.push(isAuthenticated?"/contactus":"/contact", { from: 'home' } );
}

*/

function forceLogin() {
  history.push("/Login");
}












function renderLander2() {
  return (
    <>
    <div className={"lander2"} >


    <h2 style={{color:"#0cb9c2"}}>Sites Hub</h2>
    {/*}<h1 style={{color:"#F18C7E"}}>Host Hub.</h1>

    <Box my={2}>
    <p>From this page you can access everything you sssss need in just a few clicks.<br/><br/>If there is any important information that we need to bring to your attention or actions we need you to complete then we will display them here.</p>
    </Box>*/}


    <br/>


    <Box style={{width: "100%"}}   display="flex"
     alignItems="center"
     justifyContent="center">
    <Box    display="flex"
     alignItems="center"
     justifyContent="center">
     <Grid sx={{ flexGrow: 1 }} container spacing={2}>
     <Grid item xs={20}>
       <Grid container justifyContent="center" spacing={5}>
         {data.map((site, idx) => (
           <Grid key={idx} item>
           <Card sx={{ maxWidth: 100 }} style={{width:"300px"}}>
            <Box sx={{ position: 'relative' }}>
             <CardMedia
               component="img"
               alt="reservoir"
               height="200"
               image={site.base64data}
             />
             <Box
             py={0.2}
             px={1}
               sx={{
                 position: 'absolute',
                 bottom: 0,
                 left: 0,
                 width: '100%',
                 bgcolor: 'rgba(12, 185, 194, 0.6)',
                 color: 'white',
               }}
             >

<Grid container>
  <Grid item><Typography variant="h5" color="primary"><b>{site.content.name}</b></Typography></Grid>                          
  <Grid item xs>                                 
    <Grid container direction="row-reverse">      
      <Grid item><Typography variant="h5" color="primary">{site.content.id}</Typography></Grid>
    </Grid>
  </Grid>
</Grid>

               







             </Box>
             <Box
             py={0.2}
             px={1}
               sx={{
                 position: 'absolute',
                 top: 0,
                 left: 0,
                 width: '100%',
                 bgcolor: 'rgba(12, 185, 194, 1)',
                 color: 'white',
               }}
             >
               <Typography variant="caption" color="primary">Status:<b> {site.itemStatus==0?"offline":site.itemStatus==1?"online":site.itemStatus==2?"deactivated":site.itemStatus==3?"active (manual uploads)":"not installed"}</b></Typography>
             </Box>
             </Box>
            <CardContent>


            <Typography variant="body2" color="text.secondary">
              <b>Manager:</b> {orgs[site.pk].name}
            </Typography>

            
            <img height="40px" src={orgs[site.pk].img}/>
            <Typography variant="body2" color="text.secondary">
              <b>Contact:</b>
            </Typography>

               <Paper variant="outlined" width="100%" style={{backgroundColor: "#f0c23933"}}>
                 <Box  m={1}
                 display= 'flex'
                 flexDirection = 'column'
                 width='100%'
                   justifyContent='flex-start'
                 alignItems='left'
                 alignContent='flex-start'

           >
           <Typography variant="body2" >
             {site.content.contact.name}
           </Typography>
           <Typography variant="body2" >
             {site.content.contact.phone}
           </Typography>
           <Typography variant="body2" >
             {site.content.contact.email}
           </Typography>
</Box>
</Paper>

             </CardContent>
             <CardActions disableSpacing>
             <Tooltip title="Gallery">
             <IconButton  aria-label="gallery" onClick={() => { handleSiteGalleryClick(site.content.id) }} size="small" color="secondary">
               <PhotoLibraryRoundedIcon />
             </IconButton>
             </Tooltip>
             <Tooltip title="List Data">
             <IconButton disabled={site.content.id!="S0002" && site.content.id!="S0003" && site.content.id!="S0004" && site.content.id!="S0005" && site.content.id!="S0006" && site.content.id!="S0007"} aria-label="listData" onClick={() => { handleSiteListDataClick(site) }} size="small" color="secondary">
               <FormatListBulletedRoundedIcon />
             </IconButton>
             </Tooltip>
             <Tooltip title="Data Mapper">
             <IconButton disabled={site.content.id!="S0002" && site.content.id!="S0003" && site.content.id!="S0004" && site.content.id!="S0005" && site.content.id!="S0006" && site.content.id!="S0007"} aria-label="dataMapper"  onClick={() => { handleSiteMapClick(site.content.id) }} size="small" color="secondary">
               <LocationOnRoundedIcon />
             </IconButton>
             </Tooltip>

{/*
             <Tooltip title="TEST SATSENSE">
             <IconButton aria-label="satsenseTest"  onClick={() => { handleSatsenseClick(site.content.satsense) }} size="small" color="secondary">
               <PhotoLibraryRoundedIcon />
             </IconButton>
             </Tooltip>
*/}


             <ExpandMore
             disabled={site.content.id!="S0002" && site.content.id!="S0003"}
             color="secondary"
               expand={expanded[idx]}
               onClick={() => { handleExpandClick(idx) }}
               aria-expanded={expanded[idx]}
               aria-label="show more"
                size="small"
              id={idx}
             >
               <ExpandMoreIcon />
             </ExpandMore>


              </CardActions>


              <Collapse in={expanded[idx]} timeout="auto" unmountOnExit>
                <CardContent>
                <Typography paragraph>Documents:</Typography>
                <List dense={true}>
                  {site.content.links && site.content.links.map((link, idx) => (
                <ListItem onClick={() => { handleDocLinkClick(site.content.id, link.url) }}
                button
                divider
                >
                  <ListItemIcon m={0} p={0}>
                    {link.linkType=="doc"?<DocIcon />:link.linkType=="img"?<ImageIcon />:link.linkType=="pdf"?<PdfIcon />:<DocIcon />}
                  </ListItemIcon>
                  <ListItemText

                    primary={<Typography variant="body2" style={{ color: '#0cb9c2' }}>{link.title}</Typography>}
                    secondary={<Typography variant="caption" style={{ color: '#555' }}>{link.url}</Typography>}
                  />
                </ListItem>
              ))}
                </List>
                <br/>
                <Typography paragraph>Other Details:</Typography>
                <Typography paragraph>
                <Typography variant="body2" color="text.secondary">
                  {site.content.desc}
                </Typography>
                </Typography>
                </CardContent>
              </Collapse>
           </Card>
           </Grid>
         ))}
       </Grid>
     </Grid></Grid>

   </Box> </Box>






<br/>







      </div>



</>
    );
  }







        function renderLoader() {
          return (
            <div className={"lander2"} id="splash" style={{paddingTop: (height-100)/2, zIndex:999}}>

              <center id="splash">
                <img src={spinner} height="30px" width="auto"/>
              </center>
            </div>


          );

        }

        return (
          <div className="Home">
          {isLoading?renderLoader():isAuthenticated?renderLander2():forceLogin()}
          <Dialog
          open={alertOpen}
          fullWidth={false}
          maxWidth = {'md'}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          >
          <DialogTitle id="alert-dialog-slide-title" style={{color:"#10385A"}} >
          {alertVals.title}
          </DialogTitle>
          <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
          {alertVals.message}
          </DialogContentText>
          </DialogContent>
          <DialogActions>
          <Button onClick={handleAlertClose} color="secondary">
          Ok
          </Button>
          </DialogActions>
          </Dialog>
          </div>
        );
      }
